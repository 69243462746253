import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import leftImg from "../img/left_arrow.svg";
import plusImg from "../img/plus.svg";
import {getCompaniesAll, updateCompanyLive} from "../http/cityAPI";
import {getFilteredCompaniesFalse} from "../http/catalogAPI"
// import {updateUser} from "../http/userAPI";

const TheDeletedContractors = ({info}) => {
    const [expandedIndex, setExpandedIndex] = useState(-1);
    const [names, setNames] = useState(info.userCreate)

    const [checked, setChecked] = useState([]);
    const [selectedRow, setSelectedRow] = useState(-1);

    const [showModal, setShowModal] = useState(false)
    const [deleteEmployee, setDeleteEmployee] = useState(false)
    const [blockEmployee, setBlockEmployee] = useState(false)

    const [currentCheck, setCurrentCheck] = useState(true)
    const [currentCheckIndex, setCurrentCheckIndex] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const companiesAll = await getCompaniesAll();
                // const filteredCompanies = companiesAll.filter(company => company.live === 'false');

                // console.log('DELETE filteredCompanies', filteredCompanies)

                const result = await getFilteredCompaniesFalse()

                setNames(result);

                const newChecked = result.map(company => false); // Создаем массив с false для каждой компании

                setChecked(newChecked);

            } catch (error) {
                console.error('Ошибка при получении данных ВСЕХ с live:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        console.log('!!cheked', checked)
    }, [checked]);


    const openBio = (index) => {
        if (expandedIndex === index) {
            setExpandedIndex(-1);
        } else {
            setExpandedIndex(index);
        }
    };


    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f0f0f0',
            fontFamily: 'Arial, sans-serif',
        },
        formItem: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '20px',
            borderRadius: '5px',
            backgroundColor: 'white',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.15)',
            width: '78vw'
        },
        input: {
            width: '100%', // make the input take the full width of its container
            padding: '10px 0 15px 4px',
            marginLeft: '0',
            marginRight: '0',
            border: 'none',
            borderRadius: '5px',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.15)',
        },
        button: {
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            backgroundColor: '#00b2e2',
            color: 'white',
            cursor: 'pointer',
            transition: 'background-color 0.2s ease',
        },
        buttonHover: {
            backgroundColor: '#0056b3',
        },
        header: {
            backgroundColor: '#00b2e2',
            fontSize: "14px",
        },
        logoImg: {
            marginRight: "10px",
        }
    };

    const handleCheckboxChange = (index, value, idx) => {
        console.log('VALUE, INDEX', value, index)
        // const newChecked = [...checked];

        if (value === true) {
            console.log('idx TRUE', idx)
            setChecked(prevChecked => {
                const newChecked = [...prevChecked];
                newChecked[idx] = value;
                return newChecked;
            });
            updateContractor(index, value);
        }
        else {
            console.log('idx FALSE', idx)
            // console.log('newChecked', newChecked)
            // console.log('blockEmployee', blockEmployee)
            // console.log('index', index)
            //
            // newChecked[index] = value;
            // console.log('newChecked[index]', newChecked[index])
            // setChecked(newChecked);
            setChecked(prevChecked => {
                const newChecked = [...prevChecked];
                newChecked[idx] = value;
                return newChecked;
            });
        }
    };

    const updateContractor = (id, bool) => {
        updateCompanyLive(id, bool)
    }


    switch (info.role.role) {
        case 'admin':
            return (
                <>
                    <div
                        className="bg"
                        style={showModal ? {display: "block"} : {display: "none"}}
                    ></div>
                    <div
                        className="modal"
                        style={showModal ? {display: "block"} : {display: "none"}}
                    >
                        <div
                            style={blockEmployee ? {display: "block"} : {display: "none"}}
                        >
                            <div>Вы точно хотите восстановить работу с контрагентом?
                            </div>
                            <div className="modal__btns">
                                <div
                                    className="modal_btn yes"
                                    onClick={() => {
                                        setShowModal(false)
                                        setBlockEmployee(false)
                                        handleCheckboxChange(currentCheckIndex, true, currentCheck)
                                    }}
                                >Да
                                </div>
                                <div
                                    className="modal_btn no"
                                    onClick={() => {
                                        setShowModal(false)
                                        setBlockEmployee(false)
                                        handleCheckboxChange(currentCheckIndex, false, currentCheck)
                                    }}
                                >Нет
                                </div>
                            </div>
                        </div>
                    </div>
                    <header
                        className="header order__header"
                        // style={headerName == 'Заказ' ? {display: "flex"} : {display: "none"}}
                    >
                        <div className="header__left">
                            <div>
                                <Link to={`/`}>
                                    <img src={leftImg} className="logo_img" width="25px"
                                         height="25px"/>
                                </Link>
                            </div>
                        </div>
                        <div className="header__name"
                        >
                        </div>
                        <div className="header__right">
                            <div className="filter_"></div>
                        </div>
                    </header>
                    <div className="catalog__items bio__items">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            className="contractor_add"
                        >
                            <Link
                                // to={`/createOrder/${url}`}
                            >
                                <img src={plusImg} className="logo_img" width="25px" height="25px"/>
                            </Link>
                        </div>


                        {names && (
                            <table>
                                <thead>
                                <tr>
                                    <th>Компания</th>
                                    <th>ИНН</th>
                                    <th>Комментарий</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {names
                                    .map((item, index) => {
                                    return (
                                        <>
                                            <tr key={index} onClick={() => {
                                                openBio(index);
                                                setSelectedRow(index)
                                            }} style={{background: selectedRow === index ? '#f0f8ff' : 'transparent'}}
                                                className="row_item"
                                            >
                                                <td>
                                                    <div>{item.nameCompany}</div>
                                                </td>
                                                <td>
                                                    <div>{item.innCompany}</div>
                                                </td>
                                                <td>
                                                    <div className="ellipsis">{item.liveComment}</div><div className="more_comments">Подробнее</div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div>{item.id}</div>
                                                        <input
                                                            type="checkbox"
                                                            className="employees_checkbox"
                                                            id={`myCheckbox${index}`}
                                                            checked={checked[index]}
                                                            onChange={(e) => {
                                                                console.log('ИНДЕКС', index, checked[index])
                                                                setBlockEmployee(true);
                                                                setShowModal(true);
                                                                handleCheckboxChange(item.id, false, index);
                                                                setCurrentCheck(index);
                                                                setCurrentCheckIndex(item.id);
                                                        }}
                                                        />
                                                        <label className="employees_label"
                                                               htmlFor={`myCheckbox${index}`}></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                expandedIndex === index && (
                                                    <tr>
                                                        <td colSpan="4" >
                                                            <div className="bio"
                                                            >
                                                                <div>
                                                                    {item.liveComment}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                    );
                                    })}
                                </tbody>
                            </table>
                        )}
                    </div>
                </>
            )
        case 'buh':
            return (
                <>
                    {/*<div*/}
                    {/*    className="bg"*/}
                    {/*    style={showModal ? {display: "block"} : {display: "none"}}*/}
                    {/*></div>*/}
                    {/*<div*/}
                    {/*    className="modal"*/}
                    {/*    style={showModal ? {display: "block"} : {display: "none"}}*/}
                    {/*>*/}
                    {/*    <div*/}
                    {/*        style={deleteEmployee ? {display: "block"} : {display: "none"}}*/}
                    {/*    >*/}
                    {/*        Вы точно хотите удалить сотрудника?*/}
                    {/*        <div className="modal__btns">*/}
                    {/*            <div*/}
                    {/*                className="modal_btn yes"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setDeleteEmployee(false)*/}
                    {/*                }}*/}
                    {/*            >Да*/}
                    {/*            </div>*/}
                    {/*            <div*/}
                    {/*                className="modal_btn no"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setDeleteEmployee(false)*/}
                    {/*                }}*/}
                    {/*            >Нет*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div*/}
                    {/*        style={blockEmployee ? {display: "block"} : {display: "none"}}*/}
                    {/*    >*/}
                    {/*        <div>Вы точно хотите*/}
                    {/*            {currentCheck ? ' заблокировать ' : ' разблокировать '}*/}
                    {/*            сотрудника?*/}
                    {/*        </div>*/}
                    {/*        <div className="modal__btns">*/}
                    {/*            <div*/}
                    {/*                className="modal_btn yes"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setBlockEmployee(false)*/}
                    {/*                    handleCheckboxChange(currentCheckIndex, true)*/}
                    {/*                    if (currentCheck) {*/}
                    {/*                        selectBun(true)*/}
                    {/*                    } else {*/}
                    {/*                        selectBun(false)*/}
                    {/*                        handleCheckboxChange(currentCheckIndex, false)*/}
                    {/*                    }*/}

                    {/*                }}*/}
                    {/*            >Да*/}
                    {/*            </div>*/}
                    {/*            <div*/}
                    {/*                className="modal_btn no"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setBlockEmployee(false)*/}
                    {/*                    handleCheckboxChange(currentCheckIndex, false)*/}
                    {/*                    // if (currentCheck) {*/}
                    {/*                    //     selectBun(true)*/}
                    {/*                    // } else {*/}
                    {/*                    //     selectBun(false)*/}
                    {/*                    //     handleCheckboxChange(currentCheckIndex, true)*/}
                    {/*                    // }*/}
                    {/*                }}*/}
                    {/*            >Нет*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <header
                        className="header order__header"
                        // style={headerName == 'Заказ' ? {display: "flex"} : {display: "none"}}
                    >
                        <div className="header__left">
                            <div>
                                <Link to={`/`}>
                                    <img src={leftImg} className="logo_img" width="25px"
                                         height="25px"/>
                                </Link>
                            </div>
                        </div>
                        <div className="header__name"
                        >
                        </div>
                        <div className="header__right">
                            <div className="filter_"></div>
                        </div>
                    </header>
                    <div className="catalog__items bio__items">
                        {/*<div*/}
                        {/*    style={{*/}
                        {/*        display: "flex",*/}
                        {/*        justifyContent: "center",*/}
                        {/*        alignItems: "center"*/}
                        {/*    }}*/}
                        {/*    className="contractor_add"*/}
                        {/*    onClick={() => setRegOk(true)}*/}
                        {/*>*/}
                        {/*    <Link*/}
                        {/*        // to={`/createOrder/${url}`}*/}
                        {/*    >*/}
                        {/*        <img src={plusImg} className="logo_img" width="25px" height="25px"/>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}


                        {names && (
                            <table>
                                <thead>
                                <tr>
                                    <th>Роль/ФИО</th>
                                    <th>Email</th>
                                    {/*<th>Фото</th>*/}
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {names.map((item, index) => {
                                    // setArrayNames([...arrayNames, item[index]])
                                    return (
                                        <>
                                            <tr key={index} onClick={() => {
                                                openBio(index);
                                                setSelectedRow(index)
                                            }} style={{background: selectedRow === index ? '#f0f8ff' : 'transparent'}}>
                                                <td>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <div style={{color: "#706565"}}>{item.role}</div>
                                                        <div>{item.nameTitle}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="td_mail">{item.email}</div>
                                                </td>
                                                {/*<td>*/}
                                                {/*    <img src={item.dop1} width="30px" height="35px"/>*/}
                                                {/*</td>*/}
                                                <td>
                                                    {/*<div>*/}
                                                    {/*    <div>{item.id}</div>*/}
                                                    {/*    <input*/}
                                                    {/*        type="checkbox"*/}
                                                    {/*        id={`myCheckbox${index}`}*/}
                                                    {/*        checked={checked[index]}*/}
                                                    {/*        onChange={(e) => {*/}
                                                    {/*            setBlockEmployee(true)*/}
                                                    {/*            setShowModal(true)*/}
                                                    {/*            handleCheckboxChange(index, e.target.checked)*/}
                                                    {/*            setCurrentCheck(e.target.checked)*/}
                                                    {/*            setCurrentCheckIndex(index)*/}
                                                    {/*            setNamesBan(item.email)*/}
                                                    {/*        }}*/}
                                                    {/*    />*/}
                                                    {/*    <label className="employees_label"*/}
                                                    {/*           htmlFor={`myCheckbox${index}`}></label>*/}
                                                    {/*</div>*/}
                                                </td>
                                            </tr>
                                            {
                                                expandedIndex === index && (
                                                    <tr>
                                                        <td colSpan="4">
                                                            <div className="bio"
                                                                // style={info.userCreate[bio] ? {display: "block"} : {display: "none"}}
                                                            >
                                                                <div>
                                                                    <div >
                                                                        <div>{item.role}</div>
                                                                        {/*<button*/}
                                                                        {/*    onClick={() => {*/}
                                                                        {/*        // window.location.reload();*/}
                                                                        {/*        // deleteEmployee()*/}
                                                                        {/*        setShowModal(true)*/}
                                                                        {/*        setDeleteEmployee(true)*/}
                                                                        {/*        deleteUser(item.email)*/}
                                                                        {/*    }}*/}
                                                                        {/*    className="menu__main_button delete"*/}
                                                                        {/*>Удалить сотрудника*/}
                                                                        {/*</button>*/}
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between"
                                                                    }}>
                                                                        <div>
                                                                            <div className="bio_item__header">ФИО</div>
                                                                            <div
                                                                                className="bio_item__desc">{item.name}</div>
                                                                            <div className="bio_item__header">Номер
                                                                                телефона
                                                                            </div>
                                                                            <div
                                                                                className="bio_item__desc">{item.dop}</div>
                                                                            <div className="bio_item__header">Email
                                                                            </div>
                                                                            <div className="td_mail">{item.email}</div>
                                                                        </div>
                                                                        {/*<div>*/}
                                                                        {/*    <img src={item.dop1}*/}
                                                                        {/*         width="130px" height="145px"/>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                    );
                                })}
                                {/*<tr>*/}
                                {/*    <td colSpan="4">*/}
                                {/*        <button style={{display: showMore ? "block" : "none"}}*/}
                                {/*                onClick={() => {*/}
                                {/*                    setShowCount(prevCount => prevCount + 5);*/}
                                {/*                    console.log('click', showCount)*/}
                                {/*                }}*/}
                                {/*                className="item_card__button"*/}
                                {/*        >*/}
                                {/*            Показать больше*/}
                                {/*        </button>*/}
                                {/*        <button style={{display: !showMore ? "block" : "none"}}*/}
                                {/*                onClick={() => {*/}
                                {/*                    setShowCount(5)*/}
                                {/*                    console.log('click', showCount)*/}
                                {/*                }}*/}
                                {/*                className="item_card__button"*/}
                                {/*        >*/}
                                {/*            Свернуть*/}
                                {/*        </button>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                                </tbody>
                            </table>
                        )}


                        {/*<div style={{...styles.formItem, display: regOk ? "flex" : "none"}}>*/}
                        {/*    <h4>Создание сотрудника</h4>*/}
                        {/*    <form style={styles.form}>*/}
                        {/*        <header*/}
                        {/*            className="header order__header"*/}
                        {/*            style={styles.header}*/}
                        {/*        >*/}
                        {/*            <div className="header__left" onClick={() => setRegOk(false)}>*/}
                        {/*                /!*<div onClick={() => setRegOk(false)} style={styles.logoImg}>*!/*/}
                        {/*                /!*    <img src={leftImg} className="logo_img" width="20px"*!/*/}
                        {/*                /!*         height="20px"/>*!/*/}
                        {/*                /!*</div>*!/*/}
                        {/*                Отменить создание*/}
                        {/*            </div>*/}
                        {/*            <div className="header__name"*/}
                        {/*            >*/}
                        {/*            </div>*/}
                        {/*            <div className="header__right">*/}

                        {/*            </div>*/}
                        {/*        </header>*/}
                        {/*        <label>Для роли:*/}
                        {/*            <select*/}
                        {/*                name="filterby"*/}
                        {/*                className="filterby"*/}
                        {/*                aria-label="Поле в списке"*/}
                        {/*                onChange={handleSelect}*/}
                        {/*                defaultValue="manager"*/}
                        {/*            >*/}
                        {/*                <option value="admin">Администратор</option>*/}
                        {/*                <option value="buh">Бухгалтер</option>*/}
                        {/*                <option value="manager">Менеджер</option>*/}
                        {/*            </select>*/}
                        {/*        </label>*/}
                        {/*        <label>Email:*/}
                        {/*            <input*/}
                        {/*                type="email"*/}
                        {/*                value={email}*/}
                        {/*                style={styles.input}*/}
                        {/*                onChange={(e) => setEmail(e.target.value)}*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*        <label>Password:*/}
                        {/*            <input*/}
                        {/*                type="password"*/}
                        {/*                value={password}*/}
                        {/*                style={styles.input}*/}
                        {/*                onChange={(e) => setPassword(e.target.value)}*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*        <label>From Admin Code*/}
                        {/*            <input*/}
                        {/*                type="password"*/}
                        {/*                value={adminPassword}*/}
                        {/*                style={styles.input}*/}
                        {/*                onChange={(e) => setAdminPassword(e.target.value)}*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*        <label>ФИО:*/}
                        {/*            <input*/}
                        {/*                type="text"*/}
                        {/*                value={name}*/}
                        {/*                style={styles.input}*/}
                        {/*                onChange={(e) => setName(e.target.value)}*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*        <label>Телефон:*/}
                        {/*            <input*/}
                        {/*                type="text"*/}
                        {/*                value={phone}*/}
                        {/*                style={styles.input}*/}
                        {/*                onChange={(e) => setPhone(e.target.value)}*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*        <button type="submit" style={styles.button} onClick={(e) => {*/}
                        {/*            e.preventDefault();*/}
                        {/*            setRegOk(false);*/}


                        {/*            createUsers(*/}
                        {/*                selectRole,*/}
                        {/*                email,*/}
                        {/*                password,*/}
                        {/*                adminPassword,*/}
                        {/*                name,*/}
                        {/*                phone,*/}
                        {/*                '',*/}
                        {/*                false)*/}

                        {/*        }}>*/}
                        {/*            Создать*/}
                        {/*        </button>*/}
                        {/*    </form>*/}
                        {/*</div>*/}
                    </div>
                </>
            )
        case 'manager':
            return (
                <>
                    {/*<div*/}
                    {/*    className="bg"*/}
                    {/*    style={showModal ? {display: "block"} : {display: "none"}}*/}
                    {/*></div>*/}
                    {/*<div*/}
                    {/*    className="modal"*/}
                    {/*    style={showModal ? {display: "block"} : {display: "none"}}*/}
                    {/*>*/}
                    {/*    <div*/}
                    {/*        style={deleteEmployee ? {display: "block"} : {display: "none"}}*/}
                    {/*    >*/}
                    {/*        Вы точно хотите удалить сотрудника?*/}
                    {/*        <div className="modal__btns">*/}
                    {/*            <div*/}
                    {/*                className="modal_btn yes"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setDeleteEmployee(false)*/}
                    {/*                }}*/}
                    {/*            >Да*/}
                    {/*            </div>*/}
                    {/*            <div*/}
                    {/*                className="modal_btn no"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setDeleteEmployee(false)*/}
                    {/*                }}*/}
                    {/*            >Нет*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div*/}
                    {/*        style={blockEmployee ? {display: "block"} : {display: "none"}}*/}
                    {/*    >*/}
                    {/*        <div>Вы точно хотите*/}
                    {/*            {currentCheck ? ' заблокировать ' : ' разблокировать '}*/}
                    {/*            сотрудника?*/}
                    {/*        </div>*/}
                    {/*        <div className="modal__btns">*/}
                    {/*            <div*/}
                    {/*                className="modal_btn yes"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setBlockEmployee(false)*/}
                    {/*                    handleCheckboxChange(currentCheckIndex, true)*/}
                    {/*                    if (currentCheck) {*/}
                    {/*                        selectBun(true)*/}
                    {/*                    } else {*/}
                    {/*                        selectBun(false)*/}
                    {/*                        handleCheckboxChange(currentCheckIndex, false)*/}
                    {/*                    }*/}

                    {/*                }}*/}
                    {/*            >Да*/}
                    {/*            </div>*/}
                    {/*            <div*/}
                    {/*                className="modal_btn no"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setBlockEmployee(false)*/}
                    {/*                    handleCheckboxChange(currentCheckIndex, false)*/}
                    {/*                    // if (currentCheck) {*/}
                    {/*                    //     selectBun(true)*/}
                    {/*                    // } else {*/}
                    {/*                    //     selectBun(false)*/}
                    {/*                    //     handleCheckboxChange(currentCheckIndex, true)*/}
                    {/*                    // }*/}
                    {/*                }}*/}
                    {/*            >Нет*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <header
                        className="header order__header"
                        // style={headerName == 'Заказ' ? {display: "flex"} : {display: "none"}}
                    >
                        <div className="header__left">
                            <div>
                                <Link to={`/`}>
                                    <img src={leftImg} className="logo_img" width="25px"
                                         height="25px"/>
                                </Link>
                            </div>
                        </div>
                        <div className="header__name"
                        >
                        </div>
                        <div className="header__right">
                            <div className="filter_"></div>
                        </div>
                    </header>
                    <div className="catalog__items bio__items">
                        {/*<div*/}
                        {/*    style={{*/}
                        {/*        display: "flex",*/}
                        {/*        justifyContent: "center",*/}
                        {/*        alignItems: "center"*/}
                        {/*    }}*/}
                        {/*    className="contractor_add"*/}
                        {/*    onClick={() => setRegOk(true)}*/}
                        {/*>*/}
                        {/*    <Link*/}
                        {/*        // to={`/createOrder/${url}`}*/}
                        {/*    >*/}
                        {/*        <img src={plusImg} className="logo_img" width="25px" height="25px"/>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}


                        {names && (
                            <table>
                                <thead>
                                <tr>
                                    <th>Роль/ФИО</th>
                                    <th>Email</th>
                                    {/*<th>Фото</th>*/}
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {names.map((item, index) => {
                                    // setArrayNames([...arrayNames, item[index]])
                                    return (
                                        <>
                                            <tr key={index} onClick={() => {
                                                openBio(index);
                                                setSelectedRow(index)
                                            }} style={{background: selectedRow === index ? '#f0f8ff' : 'transparent'}}>
                                                <td>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <div style={{color: "#706565"}}>{item.role}</div>
                                                        <div>{item.nameTitle}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="td_mail">{item.email}</div>
                                                </td>
                                                {/*<td>*/}
                                                {/*    <img src={item.dop1} width="30px" height="35px"/>*/}
                                                {/*</td>*/}
                                                <td>
                                                    {/*<div>*/}
                                                    {/*    <div>{item.id}</div>*/}
                                                    {/*    <input*/}
                                                    {/*        type="checkbox"*/}
                                                    {/*        id={`myCheckbox${index}`}*/}
                                                    {/*        checked={checked[index]}*/}
                                                    {/*        onChange={(e) => {*/}
                                                    {/*            setBlockEmployee(true)*/}
                                                    {/*            setShowModal(true)*/}
                                                    {/*            handleCheckboxChange(index, e.target.checked)*/}
                                                    {/*            setCurrentCheck(e.target.checked)*/}
                                                    {/*            setCurrentCheckIndex(index)*/}
                                                    {/*            setNamesBan(item.email)*/}
                                                    {/*        }}*/}
                                                    {/*    />*/}
                                                    {/*    <label className="employees_label"*/}
                                                    {/*           htmlFor={`myCheckbox${index}`}></label>*/}
                                                    {/*</div>*/}
                                                </td>
                                            </tr>
                                            {
                                                expandedIndex === index && (
                                                    <tr>
                                                        <td colSpan="4">
                                                            <div className="bio"
                                                                // style={info.userCreate[bio] ? {display: "block"} : {display: "none"}}
                                                            >
                                                                <div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between"
                                                                    }}>
                                                                        <div>{item.role}</div>
                                                                        {/*<button*/}
                                                                        {/*    onClick={() => {*/}
                                                                        {/*        // window.location.reload();*/}
                                                                        {/*        // deleteEmployee()*/}
                                                                        {/*        setShowModal(true)*/}
                                                                        {/*        setDeleteEmployee(true)*/}
                                                                        {/*        deleteUser(item.email)*/}
                                                                        {/*    }}*/}
                                                                        {/*    className="menu__main_button delete"*/}
                                                                        {/*>Удалить сотрудника*/}
                                                                        {/*</button>*/}
                                                                    </div>
                                                                    <div >
                                                                        <div>
                                                                            <div className="bio_item__header">ФИО</div>
                                                                            <div
                                                                                className="bio_item__desc">{item.name}</div>
                                                                            <div className="bio_item__header">Номер
                                                                                телефона
                                                                            </div>
                                                                            <div
                                                                                className="bio_item__desc">{item.dop}</div>
                                                                            <div className="bio_item__header">Email
                                                                            </div>
                                                                            <div className="td_mail">{item.email}</div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                    );
                                })}
                                </tbody>
                            </table>
                        )}
                    </div>
                </>
            )
    }
}

export {TheDeletedContractors}
import React, {useEffect, useState} from 'react';
import {createCompanyBD, createOrderBD} from "../http/catalogAPI";
import {Link} from "react-router-dom";

const TheOrderCreate = ({info}) => {
    const [formData, setFormData] = useState({
        orderNumber: '',
        orderPrice: '',
        shipmentData: '',
        orderComments: '',
        orderResponsible: '',
    });

    const [showCreateOrg, setShowCreateOrg] = useState(false);
    const [card, setCard] = useState([]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const [url, setUrl] = useState('');

    useEffect(() => {
        let url = window.location.href;
        let parts = url.split('/');
        let lastWord = parts[parts.length - 1];
        let INN = parseInt(lastWord);
        let result = info.contractor[INN];
        setCard(result);
        setUrl(lastWord)
        console.log('resultOrderCreate', info.contractor);
        console.log('url', url);
    }, [info]);

    const createOrder = () => {
        console.log('созданный заказ', formData)
        createOrderBD(formData)
        // createCompanyBD(formData).then(async data => {
        //     alert(data);
        //     setFormData({
        //         name: '',
        //         INN: '',
        //         OGRN: '',
        //         region: '',
        //         city: '',
        //         leaderPhone: '',
        //         messenger1: '',
        //         messenger2: '',
        //         contract: '',
        //         BIK: '',
        //         QRrs: '',
        //         QRstatic: '',
        //         workPhone1: '',
        //         workPhone2: ''
        //     })
        // })
        //     .catch(error => {
        //         alert(error.message);
        //     });
    }

    // const handleImageChangePhoto = async (e, name) => {
    //     //console.log("name", name);
    //     let file = e.target.files[0];
    //     //console.log("file", file);
    //     const parts = file.name.split('.');
    //     const fileExtension = parts[parts.length - 1];
    //     if (fileExtension !== "jpg"){
    //         alert("фото только JPG")
    //     } else {
    //         let newFile = new File([file], name + ".jpg", {type: file.type});
    //         setImage(newFile);
    //         // Создаем URL превью фото, пока оно загружается на сервер
    //         let reader = new FileReader();
    //         reader.onloadend = () => {
    //             setPreviewImage(reader.result);
    //         };
    //         reader.readAsDataURL(newFile);
    //
    //
    //         if (file) {
    //             const formData = new FormData();
    //             formData.append('image', newFile);
    //             try {
    //                 const response = await fetch('/api/upload', {
    //                     method: 'POST',
    //                     body: formData,
    //                 });
    //                 if (response.ok) {
    //                     // Обработка успешного ответа от сервера
    //                     //console.log('Изображение успешно загружено', newFile);
    //                 } else {
    //                     alert("Фото уже существует выберете новое")
    //                     console.error('Ошибка загрузки изображения');
    //                 }
    //             } catch (error) {
    //                 // Обработка ошибки сети
    //                 console.error('Ошибка сети', error);
    //             }
    //         }
    //     }
    // }

    return (
        <>
            <header
                className="header save__header"
            >
                <div className="header__left">
                    <div>
                        <Link to={`/orders/${url}`}>X</Link>
                    </div>
                </div>
                <div className="header__right">
                    <div>
                        <Link to={`/orders/${url}`} onClick={createOrder}>СОХРАНИТЬ</Link>
                    </div>
                </div>
            </header>
            <div className="create_organizations"
            >
                <div className="create_fields">
                    <input className="item_" name="orderNumber" value={formData.orderNumber}
                           onChange={handleInputChange}
                           placeholder="Введите № заказа"/>
                    <input className="item_" name="orderPrice" value={formData.orderPrice} onChange={handleInputChange}
                           placeholder="Введите стоимость"/>
                    <input className="item_" name="shipmentData" value={formData.shipmentData}
                           onChange={handleInputChange}
                           placeholder="Введите данные доставки"/>
                    <input className="item_" name="orderComments" value={formData.orderComments}
                           onChange={handleInputChange}
                           placeholder="Введите комментарий"/>
                    <input className="item_" name="orderResponsible" value={info.role.nameTitle}
                           onChange={handleInputChange}
                           placeholder="Введите ответсвенного"/>
                    {/*<input type="file" onChange={handleImageChangePhoto}/>*/}
                    <div className="input_item">
                        <div>Дата создания</div>
                        <div className="item_"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export {TheOrderCreate};
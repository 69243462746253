import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import leftImg from "../img/left_arrow.svg";
import {DateTime} from "luxon";
import {getCompanyOrdersByDatePeriod, getCompanyOrdersByDatePeriodForCity} from "../http/ordersAPI";
import preloadImg from "../img/tube-spinner.svg";

const TheCityStatistics = ({show}) => {
    const [preloader, setPreloader] = useState(false)
    const [sortOption, setSortOption] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dealsStatistics, setDealsStatistics] = useState({})
    const [advValues, setAdvValues] = useState([]);
    const [profitValues, setProfitValues] = useState([0]);
    const [incomeValues, setIncomeValues] = useState([]);
    const [profitAll, setProfitAll] = useState(0)
    const [advAll, setAdvAll] = useState(0)
    const [incomeAll, setIncomeAll] = useState(0)
    const [allTdSum, setAllTdSum] = useState(0)
    const [failedTdSum, setFailedTdSum] = useState(0)
    const [winTdSum, setWinTdSum] = useState(0)

    const handleInputChange = (e, index) => {
        const adv = [...advValues];
        adv[index] = e.target.value;
        setAdvValues(adv);

        const newProfitValues = incomeValues.map((income, i) => {
            if (i === index) {
                return income - Number(adv[index]);
            }
            return profitValues[i]; // Keep other values unchanged
        });

        setProfitValues(newProfitValues);

        const timeoutId = setTimeout(() => {
            let allProfitTd = document.querySelectorAll('.profitTd');
            let allAdvTd = document.querySelectorAll('.advTd');

            let allProfitTdSum = 0;
            let allAdvTdSum = 0;

            allProfitTd.forEach(td => {

                if (!isNaN(parseFloat(td.textContent.trim()))) {
                    allProfitTdSum += parseFloat(td.textContent.trim());
                }
            });

            allAdvTd.forEach(td => {

                let value = parseFloat(td.querySelector('input').value.trim());
                // Проверяем, что значение не является NaN
                if (!isNaN(value)) {
                    allAdvTdSum += value;
                }
            });

            setProfitAll(allProfitTdSum);
            setAdvAll(allAdvTdSum);
        }, 50); // Задержка в миллисекундах (например, 1000 мс = 1 секунда)

        return () => clearTimeout(timeoutId);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleChangeSort = (orderBy) => {
        setSortOption(orderBy)
        // sortShops(orderBy)
        setProfitAll(0)
        setAdvAll(0)
        setIncomeAll(0)
    }

    const getDataToTable = async () => {
        setPreloader(true)
        setProfitAll(0)
        setAdvAll(0)


        if (startDate && endDate && sortOption) {
            const ordersByDate = await getCompanyOrdersByDatePeriodForCity(startDate, endDate, sortOption)
            console.log('ordersByDate1', ordersByDate)

            let arrOrdersByDate = Object.values(ordersByDate);

            if (arrOrdersByDate.length > 0) {
                setPreloader(false)
            }

            const managersArray = Object.entries(ordersByDate).map(([key, value]) => ({ key, ...value }));
            // console.log('managersArray', managersArray)

            managersArray.sort((a, b) => b.orders.filter(item => item.status === null).length - a.orders.filter(item => item.status === null).length);

            const managersObject = managersArray.reduce((acc, curr) => {
                acc[curr.key] = { ...curr };
                delete acc[curr.key].key; // Remove the 'key' property from each nested object
                return acc;
            }, {});

            setDealsStatistics(managersObject)

            const incomeValues = Object.entries(managersObject).map((item, index) => (
                // item[1].orders.reduce((total, item) => total + Number(item.sentSum), 0)
                item[1].orders.reduce((total, item) => total + Math.round(Number(item.sum) - Math.round(Number(item.sentSum))), 0)
            ));

            setIncomeValues(incomeValues);

            setProfitValues([])
            setAdvValues([])

            const timeoutId = setTimeout(() => {
                // Ваш код для вычисления суммы и установки общего дохода
                let allIncomeTd = document.querySelectorAll('.incomeTd');
                let allIncomeTdSum = 0;
                allIncomeTd.forEach(td => {
                    allIncomeTdSum += parseFloat(td.textContent.trim());
                });
                setIncomeAll(allIncomeTdSum);

                let allTd = document.querySelectorAll('.all_item');
                let allSumTd = 0;
                allTd.forEach(td => {
                    allSumTd += parseFloat(td.textContent.trim());
                });
                setAllTdSum(allSumTd);

                let winTd = document.querySelectorAll('.win_item');
                let winSumTd = 0;
                winTd.forEach(td => {
                    winSumTd += parseFloat(td.textContent.trim());
                });
                setWinTdSum(winSumTd);

                let failedTd = document.querySelectorAll('.failed_item');
                let failedSumTd = 0;
                failedTd.forEach(td => {
                    failedSumTd += parseFloat(td.textContent.trim());
                });
                setFailedTdSum(failedSumTd);

            }, 100); // Задержка в миллисекундах (например, 1000 мс = 1 секунда)


            return () => {
                clearTimeout(timeoutId);
            };
        }

    }

    const showManagers = async () => {
        await getDataToTable()
    }

    useEffect(() => {
        // console.log('ДЕРНУЛСЯ res getNotificationOrders')
        const fetch = async () => {

            if (sortOption === '') {
                setSortOption("https://miamor.bitrix24.ru/")
            }

            await getDataToTable()
        }
        fetch()
    }, [sortOption]);

    useEffect(() => {
        const fetch = async () => {
            let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let now = DateTime.now().setZone(timeZone);
            // console.log('DateTime.now()', now)
            let firstMonthDate = now.startOf('month').toFormat('yyyy-MM-dd');
            let currentDate = now.toISODate();
            setStartDate(firstMonthDate);
            setEndDate(currentDate);
        }
        fetch();
    }, []);

    const getOrdersByDatePeriod = async (sortPeriod) => {
        setPreloader(true)
        setProfitAll(0)
        setAdvAll(0)
        let startDate, endDate, formattedStartDate, nowDate, lastYear, isLastYearLeap;
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Получение даты начала и конца периода в зависимости от выбранного варианта
        switch (sortPeriod) {
            case '7 дней':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 7 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            case 'месяц':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 30 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            case '90 дней':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 90 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            case '180 дней':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 180 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            case '1 год':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ years: 1 });
                lastYear = startDate.year;
                // console.log('lastYear', lastYear)
                isLastYearLeap = DateTime.local(lastYear).isInLeapYear;
                // console.log('isLastYearLeap', isLastYearLeap)
                startDate = nowDate.minus({ days: isLastYearLeap ? 366 : 365 });
                // console.log('startDate', startDate)
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            default:
                let now = DateTime.now().setZone(timeZone);
                let firstMonthDate = now.startOf('month').toFormat('yyyy-MM-dd');
                let currentDate = now.toISODate();
                setStartDate(firstMonthDate);
                setEndDate(currentDate);
        }

        const ordersByDate = await getCompanyOrdersByDatePeriodForCity(startDate, endDate, sortOption)


        const managersArray = Object.entries(ordersByDate).map(([key, value]) => ({ key, ...value }));

        managersArray.sort((a, b) => b.orders.filter(item => item.status === null).length - a.orders.filter(item => item.status === null).length);

        const managersObject = managersArray.reduce((acc, curr) => {
            acc[curr.key] = { ...curr };
            delete acc[curr.key].key; // Remove the 'key' property from each nested object
            return acc;
        }, {});


        setDealsStatistics(managersObject)

        const incomeValues = Object.entries(managersObject).map((item, index) => (
            // item[1].orders.reduce((total, item) => total + Number(item.sentSum), 0)
            item[1].orders.reduce((total, item) => total + Math.round(Number(item.sum) - Math.round(Number(item.sentSum))), 0)
        ));

        setIncomeValues(incomeValues);

        setProfitValues([])
        setAdvValues([])

        setPreloader(false)
    };

    return (
        <>
            <div
                className="left_links"
                onClick={() => {
                    localStorage.removeItem('scroll_order');
                }}
            >
                <Link to={`/statistics`} style={show ? {display: "flex"} : {display: "none"}}>
                    <img src={leftImg} className="logo_img" width="25px" height="25px"/>
                </Link>
                <Link to={`/statistics`} style={!show ? {display: "flex"} : {display: "none"}}>
                    <img src={leftImg} className="logo_img" width="25px" height="25px"/>
                </Link>
                {/*<div>{groupedManagers}</div>*/}
            </div>
            <div className="deals">
                <div
                    className="preloader"
                    style={preloader ? {display: "block"} : {display: "none"}}
                >
                    <img src={preloadImg} className="logo_img" width="125px"
                         height="125px"/>
                </div>
                <div>
                    <div className="select__wrapper" style={{marginBottom: "20px"}}>
                        Выбрать магазин
                        <select name="orderby" className="orderby" aria-label="Магазин" onChange={(e) => {
                            handleChangeSort(e.target.value)
                        }} value={sortOption}>
                            <option value="">Выберите магазин</option>
                            <option value="https://tsvetochnyi.bitrix24.ru/">Цветочный</option>
                            <option value="https://miamor.bitrix24.ru/">Ми-амор</option>
                            <option value="https://sladkoeshka.bitrix24.ru/">Сладкоешка</option>
                            <option value="https://kami-flow.bitrix24.ru/">Камелия</option>
                        </select>
                    </div>
                </div>
                <div style={{marginBottom: "20px"}}>
                    <label>Дата начала:</label>
                    <input type="date" value={startDate} max={new Date().toISOString().split('T')[0]}
                           onChange={(e) => handleStartDateChange(e.target.value)}/>

                    <label style={{marginLeft: "10px"}}>Дата окончания:</label>
                    <input type="date" value={endDate} max={new Date().toISOString().split('T')[0]}
                           onChange={(e) => handleEndDateChange(e.target.value)}/>

                    <button style={{marginLeft: "10px"}} onClick={showManagers}>Применить</button>
                </div>
                <div style={{marginBottom: "20px"}}>
                    <select id="sortPeriod" onChange={(e) => {
                        getOrdersByDatePeriod(e.target.value).then()
                    }}>
                        <option value="текущий">Выберите период</option>
                        <option value="7 дней">За неделю</option>
                        <option value="месяц">За месяц</option>
                        <option value="90 дней">За 90 дней</option>
                        <option value="180 дней">За 180 дней</option>
                        <option value="1 год">За 1 год</option>
                    </select>
                </div>
                <div style={{marginBottom: "20px"}}>
                    <button onClick={() => {
                        // sentGetBitrix()
                    }}>Кнопка
                    </button>
                    <button>Отчет за день</button>
                    <button style={{marginLeft: "10px"}}>Отчет за неделю</button>
                    <button style={{marginLeft: "10px"}}>Отчет за месяц</button>
                </div>
                <div>
                    <div>
                        <div className="wide-table-wrapper city-wide-table-wrapper" style={{display: 'flex'}}>
                            <table className="wide-table">
                                <thead>
                                <tr>
                                    <th rowSpan="2">Город</th>
                                    <th colSpan="3">Общие показатели</th>
                                    <th rowSpan="2">Конверсия</th>
                                    <th>{incomeAll}</th>
                                    <th>{advAll}</th>
                                    <th style={{backgroundColor: profitAll < 0 ? 'rgba(250,0,0,0.42)' : 'transparent'}}>{profitAll}</th>
                                </tr>
                                <tr>
                                    <th>Всего</th>
                                    <th>Успех</th>
                                    <th>Провал</th>
                                    <th>Доход</th>
                                    <th>Реклама</th>
                                    <th>Прибыль</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    Object.entries(dealsStatistics)
                                        .map(((item, index) => {
                                            return (
                                                // item.text &&
                                                <tr>
                                                    <td className="city_item">{item[0]}</td>
                                                    <td className="all_item">{item[1].orders.length}</td>
                                                    <td style={{backgroundColor: 'rgba(152,234,119,0.42)'}}
                                                        className="win_item">
                                                        {
                                                            item[1].orders.filter(item => item.status === null).length
                                                        }
                                                    </td>
                                                    <td style={{backgroundColor: 'rgba(248,101,101,0.42)'}}
                                                        className="failed_item">
                                                        {
                                                            item[1].orders.filter(item => item.status === 'failed').length
                                                        }
                                                    </td>
                                                    <td style={{backgroundColor: 'rgba(250,243,155,0.42)'}}>
                                                        {`${(item[1].orders.filter(item => item.status === null).length * 100 / item[1].orders.length).toFixed(2)}%`}
                                                    </td>
                                                    <td className="incomeTd">
                                                        {

                                                            // item[1].orders.reduce((total, item) => total + Number(item.sentSum), 0)
                                                            item[1].orders.filter(item => item.status === null).reduce((total, item) => total + Math.round(Number(item.sum) - Math.round(Number(item.sentSum))), 0)
                                                        }
                                                    </td>
                                                    <td className="advTd" id={`${item[0]}`}>
                                                        <input
                                                            style={{maxWidth: "48px"}}
                                                            value={advValues[index] || ''}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            // onClick={clickInput}
                                                        />
                                                    </td>
                                                    <td className="profitTd"
                                                        style={{backgroundColor: profitValues[index] < 0 ? 'rgba(250,0,0,0.42)' : 'transparent'}}
                                                    >
                                                        {profitValues[index]}
                                                    </td>
                                                </tr>
                                            )
                                        }))
                                }
                                <tr>
                                    <th>Итого</th>
                                    <th>{allTdSum}</th>
                                    <th>{winTdSum}</th>
                                    <th>{failedTdSum}</th>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {TheCityStatistics};

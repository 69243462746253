import React, {createRef, useEffect, useRef, useState} from 'react';
import phoneImg from "../img/phone.svg";
import waImg from "../img/whatsapp.svg";
import tgImg from "../img/telegram.svg";
import vbImg from "../img/viber.svg";
import {Link} from "react-router-dom";
import leftImg from "../img/left_arrow.svg";
import reloadImg from "../img/reload.svg";
import {deleteNotificationBD, getNotificationOrders} from "../http/notificationAPI";

import {normalizeDate} from "./functions/normalizeDate";
import copyImg from "../img/copy.svg";
import {getUser} from "../http/userAPI";
import {normalizePhoneNumber} from "./functions/normalizePhoneNumber";
import {getNumberBD} from "../http/cityAPI";
import {postUpdateOrder} from "../http/ordersAPI";

const TheNotifications = ({info, headerName, changeMenu}) => {
    const [url, setUrl] = useState('');
    const [notification,setNotification] = useState('')
    const [notifManagerName, setNotifManagerName] = useState('')
    const [users, setUsers] = useState('');

    useEffect(() => {
        let url = window.location.href;
        let parts = url.split('/');
        let lastWord = parts[parts.length - 1];
        let INN = parseInt(lastWord);
        let result = info.contractor[INN];
        // setCard(result);
        setUrl(lastWord)
        // ('result', result);
        // console.log('url', url);
    }, [info]);

    useEffect(() => {
        // console.log('ДЕРНУЛСЯ res getNotificationOrders')
        const fetch = async () => {
            const res = await getNotificationOrders()

            let filteredRes = res.sort((a, b) =>
                b.id - a.id
            )

            // console.log('res getNotificationOrders', filteredRes)

            setNotification(filteredRes)

            let users = await getUser();
            setUsers(users)



        }

        fetch()
    }, []);


    const deleteNotification = (id, order) => {
        // console.log('deleteNotification id', id)

        postUpdateOrder({
                idBitrix: order.idBitrix || '',
                sum: order.sum || '',
                sentSum: order.sentSum || '',
                companyId: '',
                shop_number: order.shop_number || '',
                link: order.link || '',
                who: order.who || '',
                dop: order.dop || '',
                dopMain: order.dopMain || '',
                sentStatus: 'trashDelete'
            })
            .then(data => {
                // console.log('Message DELETE', data)
                if (data === 'ok') {
                    deleteNotificationBD(id)
                }
            })

            // .then(async () => {
            //     if ()
            // await deleteNotificationBD(id)
            // })
        // deleteNotificationBD(id)

        // console.log('deleteNotification ORDERS', order)

        let notificationNow = notification.filter(item => item.id !== id)

        // console.log('notificationNow', notificationNow)

        setNotification(notificationNow)
    }

    // const normalizeName = (name) => {
    //     const stringWithOnlyAlphabetic = name && name.toLowerCase();
    //     return stringWithOnlyAlphabetic
    // }

    // useEffect(() => {
    //     const fetch = async () => {
    //         let users = await getUser();
    //         let user = users && users.filter(item => item.email === manager);
    //
    //         setNotifManagerName(user[0].nameTitle)
    //     }
    //
    //     fetch()
    // }, []);

    const sentCheckedMessage = async (manager, link, idBitrix, shop_number) => {
        let trimmedUrl = link && link.substring(link.indexOf('//') + 2);
        // console.log('sentCheckedMessage ДЕРНУЛСЯ', manager);
        let users = await getUser();
        let user = users && users.filter(item => item.email === manager);

        // console.log('user sentCheckedMessage', user)

        if (!user || user.length === 0) {
            alert(`Нет информации о менеджере ${user}`);
            return;
        }

        if (!user[0].dop) {
            alert(`Нет телефона у менеджера  ${user}`);
            return;
        }

        let userPhone = user[0].dop;

        let userPhoneNormal = "7" + userPhone.slice(-10);

        // const whatsappLink = `https://api.whatsapp.com/send/?phone=${userPhoneNormal}&text=%D0%9F%D1%80%D0%B8%D0%B2%D0%B5%D1%82%21+%D0%AD%D1%82%D0%BE%D0%B3%D0%BE+%D0%BA%D0%BE%D0%BD%D1%82%D1%80%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D0%B0+%D0%BD%D0%B5%D1%82+%D0%B2+%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B8.+%D0%A0%D0%B0%D1%81%D1%81%D0%BA%D0%B0%D0%B6%D0%B8+%D0%BF%D1%80%D0%BE+%D0%BD%D0%B8%D1%85%2C+%D0%B2%D0%BE%D0%B7%D0%BC%D0%BE%D0%B6%D0%BD%D0%BE+%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%B8%D0%BC&text=https://tsvetochnyi.bitrix24.ru/crm/deal/details/123597/type=phone_number&app_absent=0`;
        // const whatsappLink = `https://api.whatsapp.com/send/?phone=79257891708&text=%D0%AD%D1%82%D0%BE%D0%B3%D0%BE%20%D0%BA%D0%BE%D0%BD%D1%82%D1%80%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D0%B0%20%7Bhttps%3A%2F%2F${trimmedUrl}crm%2Fdeal%2Fdetails%2F${idBitrix}%2F%7D%20%D0%BD%D0%B5%D1%82%20%D0%B2%20%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B8%20%D0%B8%20%D0%B2%20%D0%B1%D0%BE%D1%82%D0%B5.%20%D0%A0%D0%B0%D1%81%D1%81%D0%BA%D0%B0%D0%B6%D0%B8%20%D0%BF%D0%BE%D0%B4%D1%80%D0%BE%D0%B1%D0%BD%D0%B5%D0%B5%2C%20%D0%B2%D0%BE%D0%B7%D0%BC%D0%BE%D0%B6%D0%BD%D0%BE%20%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%B8%D0%BC`
        const whatsappLink = `https://api.whatsapp.com/send/?phone=${userPhoneNormal}&text=%D0%AD%D1%82%D0%BE%D0%B3%D0%BE%20%D0%BA%D0%BE%D0%BD%D1%82%D1%80%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D0%B0%20https%3A%2F%2F${trimmedUrl}crm%2Fdeal%2Fdetails%2F${idBitrix}%2F%20(%20%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%20%D0%BA%D0%BE%D0%BD%D1%82%D1%80%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D0%B0%20%2B${shop_number}%20)%20%D0%BD%D0%B5%D1%82%20%D0%B2%20%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B8%20%D0%B8%20%D0%B2%20%D0%B1%D0%BE%D1%82%D0%B5.%20%D0%A0%D0%B0%D1%81%D1%81%D0%BA%D0%B0%D0%B6%D0%B8%20%D0%BF%D0%BE%D0%B4%D1%80%D0%BE%D0%B1%D0%BD%D0%B5%D0%B5%2C%20%D0%B2%D0%BE%D0%B7%D0%BC%D0%BE%D0%B6%D0%BD%D0%BE%20%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%B8%D0%BC&type=phone_number&app_absent=0`

        // Функция для копирования текста в буфер обмена
        function copyToClipboard(text) {
            const input = document.createElement('input');
            input.style.position = 'fixed';
            input.style.opacity = 0;
            input.value = text;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
        }

        // Обработчик двойного клика для копирования ссылки в буфер обмена и удаления ее из буфера
        function doubleClickHandler() {
            // console.log('двойной')
            copyToClipboard(whatsappLink);
            setTimeout(() => {
                const input = document.createElement('input');
                input.style.position = 'fixed';
                input.style.opacity = 0;
                document.body.appendChild(input);
                input.select();
                document.execCommand('copy');
                document.body.removeChild(input);
            }, 100);
        }

        // Привязка обработчика к событию двойного клика
        document.addEventListener('dblclick', doubleClickHandler);

        // Открытие ссылки в новой вкладке
        window.open(whatsappLink, '_blank');
    }


    useEffect(() => {
        // Проверяем, что additionalPhone не пустой
        if (notification.length > 0) {
            // Заполняем массив рефов
            inputWorkPhonesRefs.current = notification.map(() => createRef());

            // console.log("!!!!notificationRefs", inputWorkPhonesRefs)
        }
    }, [notification]);

    const inputWorkPhonesRefs = useRef([]);

    const copyToClipboardOnClickPhones = (index) => {
        // console.log('index PHONE', index)
        const inputValue = document.querySelector(`.workPhone${index}`);
        // console.log('inputValue', inputValue)

        if (inputValue) {
            const valueText = inputValue.textContent;
            // console.log('valueText', valueText);

            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = valueText;
            document.body.appendChild(tempTextArea);

            // Выделяем текст внутри textarea
            tempTextArea.select();
            tempTextArea.setSelectionRange(0, 99999); // Для поддержки выбора текста в различных браузерах

            // Копируем текст в буфер обмена
            document.execCommand('copy');
            alert('Текст скопирован в буфер обмена');

            // Удаляем временный элемент
            document.body.removeChild(tempTextArea);
        }
    };

    const copyToClipboardOnClickLink = (index) => {
        // console.log('index PHONE', index)
        const inputValue = document.querySelector(`.workLink${index}`);
        // console.log('inputValue', inputValue)

        if (inputValue) {
            const valueText = inputValue.getAttribute('href');
            // console.log('valueText', valueText);

            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = valueText;
            document.body.appendChild(tempTextArea);

            // Выделяем текст внутри textarea
            tempTextArea.select();
            tempTextArea.setSelectionRange(0, 99999); // Для поддержки выбора текста в различных браузерах

            // Копируем текст в буфер обмена
            document.execCommand('copy');
            alert('Текст скопирован в буфер обмена');

            // Удаляем временный элемент
            document.body.removeChild(tempTextArea);
        }
    };

    return (
        <div>
            <header
                className="header order__header"
                // style={headerName == 'Заказ' ? {display: "flex"} : {display: "none"}}
            >
                <div className="header__left">
                    <div>
                        <Link to={`/`}>
                            <img src={leftImg} className="logo_img" width="25px"
                                 height="25px"/>
                        </Link>
                    </div>
                </div>
                <div className="header__name"
                >

                </div>
                <div className="header__right">
                    <div style={{ cursor: "pointer" }} onClick={() => window.location.reload()}>
                        <img src={reloadImg} className="logo_img" width="20px"
                             height="20px"/>
                    </div>
                </div>
            </header>
            <div className="orders pay">
            {
                    notification && notification.map(((item, index) => {
                        return (
                            item.text &&
                            <div className="center">
                                <div
                                    className="orders__wrapper all"
                                    style={{padding: "2px",
                                        border: "1px solid #eeeeee",
                                        marginBottom: "10px"}}
                                >
                                    <div className="orders__date" style={{display: "flex",
                                        justifyContent: "space-between", backgroundColor: item.text ? "#fcf0f4" : "initial", alignItems: "center"}}>
                                        {
                                            item.text &&
                                            <div style={{fontSize: "12px"}}>{normalizeDate(item.createdAt)}</div>
                                        }
                                        <div style={{display: "flex"}}>
                                            {
                                                item.text
                                                &&
                                                <button
                                                    style={{cursor: "pointer"}}
                                                    className="deleteNotif"
                                                    style={{ marginRight: "10px" }}
                                                    onClick={() => {
                                                        deleteNotification(item.id, item)
                                                    }}>Удалить
                                                </button>
                                            }
                                            {
                                                item.text &&
                                                    <button
                                                        style={{ cursor: "pointer" }}
                                                        className="checkNotif"
                                                        onClick={() => {
                                                            // deleteNotification(item.id)
                                                            sentCheckedMessage(item.dop, item.link, item.idBitrix, item.shop_number).then()
                                                        }}>Проверить
                                                    </button>
                                                    // :
                                                    // <a
                                                    //     target="_blank"
                                                    //     href={`${item.link}crm/deal/details/${item.idBitrix}/`}
                                                    //     style={{textDecoration: "underline", fontSize: "14px"}}
                                                    // >
                                                    //     Ссылка на Bitrix
                                                    // </a>
                                            }
                                            {
                                                item.text
                                                &&
                                                <Link to={`/create/${item.shop_number}/${item.id}`}
                                                      className="updateNotif">
                                                    {/*<div onClick={() => {*/}
                                                    {/*    toCreateLink(item.shop_number)*/}
                                                    {/*}}>Создать*/}
                                                    {/*</div>*/}
                                                    Создать
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                    {
                                        item.text
                                            &&
                                            <div className="orders__item"
                                                 style={{display: "flex", flexDirection: "column"}}>
                                                <div style={{
                                                    display: "flex",
                                                    marginBottom: "6px",
                                                    alignItems: "baseline",
                                                    fontSize: "12px",
                                                    justifyContent: "space-between"
                                                }}>
                                                    <div>
                                                        <div className="to_adress">Ответственный:</div>
                                                        <div className="item adress up">
                                                            {users && users
                                                                .filter(user => user.email === item.dop)
                                                                .map((filteredUser) => (
                                                                    <div
                                                                        key={filteredUser.id}>{filteredUser.nameTitle}</div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="to_adress"
                                                             style={{margin: "0", textAlign: "right"}}>Город:
                                                        </div>
                                                        <div className="item adress up">{item.dopMain}</div>
                                                    </div>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                                    <div>
                                                        <div style={{position: "relative"}}>
                                                            <div
                                                                style={{marginTop: "10px"}}
                                                            >
                                                                <a
                                                                    className={`workLink${index}`}
                                                                    ref={(el) => (inputWorkPhonesRefs.current[index] = el)}
                                                                    target="_blank"
                                                                    href={`${item.link}crm/deal/details/${item.idBitrix}/`}
                                                                    style={{
                                                                        textDecoration: "underline",
                                                                        fontSize: "14px"
                                                                    }}
                                                                >
                                                                    Ссылка на Bitrix
                                                                </a>
                                                            </div>
                                                            <div className="copy icon copyWorkPhone"
                                                                 onClick={() => copyToClipboardOnClickLink(index)}
                                                                 style={{right: "0"}}
                                                            >
                                                                <img src={copyImg} className="logo_img" width="20px"
                                                                     height="20px"/>
                                                            </div>
                                                        </div>
                                                        <div className="item group">
                                                            <div className="item" style={{
                                                                fontSize: "14px",
                                                                marginTop: "10px",
                                                                color: "#f02d76"
                                                            }}>{item.text}</div>
                                                        </div>
                                                        <div style={{position: "relative"}}>
                                                            <div
                                                                style={{marginTop: "10px"}}
                                                                className={`workPhone${index}`}
                                                                ref={(el) => (inputWorkPhonesRefs.current[index] = el)}
                                                            >{item.shop_number}</div>
                                                            <div className="copy icon copyWorkPhone"
                                                                 onClick={() => copyToClipboardOnClickPhones(index)}
                                                                 style={{right: "0"}}
                                                            >
                                                                <img src={copyImg} className="logo_img" width="20px"
                                                                     height="20px"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="item_left">
                                                        <div className="item price up">
                                                            <div style={{fontWeight: "400", marginBottom: "2px"}}>Сумма
                                                                заказа
                                                            </div>
                                                            <div>{item.sum} руб</div>
                                                        </div>
                                                        <div className="item price up">
                                                            <div
                                                                style={{fontWeight: "400", marginBottom: "2px"}}>Выплата
                                                                магазину
                                                            </div>
                                                            <div>{item.sentSum} руб</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{marginTop: "10px", fontSize: "12px"}}>
                                                    Пришло из {item.link}
                                                </div>
                                            </div>
                                            // :
                                            // <div className="orders__item"
                                            //      style={{display: "flex", flexDirection: "column"}}>
                                            //     <div style={{display: "flex", marginBottom: "6px", alignItems: "baseline", fontSize: "12px"}}>
                                            //         <div className="to_adress">Ответственный: </div>
                                            //         <div className="item adress up">{item.dop}</div>
                                            //     </div>
                                            //     <div style={{display: "flex", justifyContent: "space-between"}}>
                                            //         <div>
                                            //             <div className="item number" style={{
                                            //                 fontSize: "14px",
                                            //                 border: "1px solid #000000",
                                            //                 padding: "3px",
                                            //                 borderRadius: "6px",
                                            //                 maxWidth: "63px"
                                            //             }}>
                                            //                 <div style={{fontSize: "10px"}}>Id компании</div>
                                            //                 {item.companyId}
                                            //             </div>
                                            //             <div style={{position: "relative"}}>
                                            //                 <div
                                            //                     style={{marginTop: "10px"}}
                                            //                     className={`workPhone${index}`}
                                            //                     ref={(el) => (inputWorkPhonesRefs.current[index] = el)}
                                            //                 >{item.shop_number}</div>
                                            //                 <div className="copy icon copyWorkPhone"
                                            //                      onClick={() => copyToClipboardOnClickPhones(index)}
                                            //                      style={{ right: "-24px" }}
                                            //                 >
                                            //                     <img src={copyImg} className="logo_img" width="20px"
                                            //                          height="20px"/>
                                            //                 </div>
                                            //             </div>
                                            //             <div className="item_rigth">
                                            //                 <div
                                            //                     className="item status inProcess"
                                            //                     style={{marginTop: "16px"}}
                                            //                 >{item.type}</div>
                                            //             </div>
                                            //         </div>
                                            //         <div className="item_left">
                                            //             <div className="item price up">
                                            //                 <div className="item price up">
                                            //                     <div style={{
                                            //                         fontWeight: "400",
                                            //                         marginBottom: "2px"
                                            //                     }}>Сумма
                                            //                         заказа
                                            //                     </div>
                                            //                     <div>{item.sum} руб</div>
                                            //                 </div>
                                            //                 <div
                                            //                     style={{
                                            //                         fontWeight: "400",
                                            //                         marginBottom: "2px"
                                            //                     }}>Выплата
                                            //                     магазину
                                            //                 </div>
                                            //                 <div>{item.sentSum} руб</div>
                                            //             </div>
                                            //         </div>
                                            //     </div>
                                            //     <div style={{marginTop: "10px", fontSize: "12px"}}>
                                            //         Пришло из {item.link}
                                            //     </div>
                                            // </div>
                                    }
                                </div>
                            </div>
                        )
                    }))
                }
            </div>
        </div>
    );
};

export {TheNotifications};
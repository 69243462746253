import React, {useEffect, useState} from 'react';
import {getAppReg} from "../http/appRegAPI";

const TheAppReg = () => {
    const [appReg, setAppReg] = useState([]);

    useEffect(() => {
        getAppReg().then(res => {
            setAppReg(res)
        })
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            getAppReg().then(res => {
                setAppReg(res)
            })
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    return (

        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Имя</th>
                        <th>Телефон</th>
                    </tr>
                </thead>
                <tbody> {
                    appReg.length !== 0 ?
                        appReg.map((app, index) => (
                            <tr key={index}>
                                <td>{app.name}</td>
                                <td>{app.phone}</td>
                            </tr> )) : (
                                <tr>
                                    <td colSpan="2">No data</td>
                                </tr> )}
                </tbody>
            </table>
        </div>
    );
};

export {TheAppReg};

const normalizePhoneNumber = (phoneNumber) => {
    if (phoneNumber !== '') {
        const digits = phoneNumber.replace(/\D/g, ''); // Удаление всех нечисловых символов
        if (digits.length > 10) {
            return "+7" + digits.slice(-10); // Если больше 10 цифр, обрезаем до 10 и добавляем +7 в начале
        } else {
            return "+7" + digits; // Если меньше или равно 10 цифр, добавляем +7 в начале
        }
    }
}

export {normalizePhoneNumber};
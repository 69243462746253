import React from 'react';
import './ImageUploader.css';

const ImageUploader = ({ name, onClose }) => {
    console.log("idBitrix name", name);

    const handleFileChange = async (event) => {
        const formData = new FormData();
        const file = event.target.files[0];

        if (!file) {
            console.error('No file selected');
            return;
        }

        // Получаем расширение файла
        const extension = file.name.split('.').pop();
        const newFileName = `${name}.${extension}`; // Используем значение name для нового имени файла

        // Добавляем файл и idBitrix в FormData
        formData.append('photo', file, name);
        formData.append('idBitrix', name); // Убедитесь, что здесь передается нужное значение
        formData.append('newFileName', newFileName);

        for (let [key, value] of formData.entries()) {
            console.log("formData", `${key}: ${value}`);
        }

        try {
            const response = await fetch('https://localhost:5000/api/upload', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error('Error uploading photo:', error);
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Загрузите фото</h2>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ marginBottom: '10px' }} // Добавлен отступ
                />
                <button onClick={onClose}>Закрыть</button>
            </div>
        </div>
    );
};

export default ImageUploader;



import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {createCompanyBD} from "../http/catalogAPI";
import {Link} from "react-router-dom";
import InputMask from 'react-input-mask';

import shareBaloon from "../img/baloon.svg";
import shareCake from '../img/cake.svg';
import shareFlowers from "../img/flowers.svg";
import shareStrawberry from '../img/strawberry.svg';
import {newCreateBDCompanies} from "../http/catalogAPI";
import {deleteNotificationBD} from "../http/notificationAPI";
import {postUpdateOrder} from "../http/ordersAPI";

const TheCreate = ({info, setRender}) => {
    const [checked, setChecked] = useState(
        {
            flowers: false,
            strawberry: false,
            balloon: false,
            cakes: false,
        }
    );

    const [disAddCity, setDisAddCity] = useState(true)
    const [disAddPhone, setDisAddPhone] = useState(true)

    const [additionalCity, setAdditionalCity] = useState([{address_custom: ''}])
    const [additionalPhone, setAdditionalPhone] = useState([{workPhone: ''}])
    const [openSearchResult, setOpenSearchResult] = useState(false)
    const [openSearchResultAddress, setOpenSearchResultAddress] = useState(false)
    const [openSearchResultLettersAddress, setOpenSearchResultLettersAddress] = useState(false)
    const [openSearchResultBanks, setOpenSearchResultBanks] = useState(false)
    // const [colorPhone, setColorPhone] = useState(additionalPhone.map(() => false));

    const [innHard, setInnHard] = useState(true)
    const [disableINN, setDisableINN] = useState(false)

    const [formData, setFormData] = useState({
        name: '',
        INN: '',
        OGRN: '',
        rs: '',
        region: '',
        city: '',
        citybd: '',
        leaderPhone: '',
        messenger1: '',
        messenger2: '',
        contract: '',
        BIK: '',
        QRrs: '',
        QRstatic: '',
        workPhone1: '',
        workPhone2: '',
        soc1: '',
        soc2: '',
        amount: '',
        storePayment: '',
        siteName: '',
        bankName: '',
        lettersAddress: '',
        cityReg: '',
        districtReg: '',
        postcode: '',
    });

    // const regAddress = `${},`

    const [searchedResult, setSearchedResult] = useState(false)

    const [modalResults, setModalResults] = useState([]);
    const [modalResultsLettersAddress, setModalResultsLettersAddress] = useState([]);
    const [modalResultsINN, setModalResultsINN] = useState([]);
    const [modalResultsBanks, setModalResultsBanks] = useState([]);
    const [idNotif, setIdNotif] = useState(null)

    useEffect(() => {
        setFormData(prevData => ({
            ...prevData,
            siteName: checked,
        }));

    }, [checked]);

    useEffect(() => {
        let url = window.location.href;
        let parts = url.split('/');
        let lastWord = parts[parts.length - 2];
        let phone = lastWord;
        let notifId = parts[parts.length - 1];
        setIdNotif(notifId)

        if (!url.includes('/create/new/contractor')) {
            setIdNotif(null)
            setAdditionalPhone([{workPhone: phone}])
            setDisAddPhone(false);
        }

        // additionalPhone.push({workPhone: phone})
        // let result = info.contractor[INN];
        // setCard(result);
        // setUrl(lastWord)
        // console.log('result', result);
        // console.log('url', url);
    }, []);


    const sentBDCreate = () => {
        if (formData.INN && formData.INN !== '' && formData.INN !== ' ' && formData.INN !== '  ' &&
            (additionalCity && additionalCity.length !== 0 && additionalCity[0].address_custom !== '') &&
            (additionalPhone && additionalPhone.length !== 0 && additionalPhone[0].workPhone !== '')) {
            console.log("additionalPhone", additionalPhone)
            const resultPhone = [];
            for (let i = 0; i < additionalPhone.length; i++) {
                const normalizedPhone = additionalPhone[i].workPhone.replace(/[\s()-]/g, '');
                const normalizedPhoneObj = { workPhone: normalizedPhone,
                    // dop: colorPhone[i] ? 'check' : ''
                };
                resultPhone.push(normalizedPhoneObj);
            }

            const create = {
                cities: additionalCity || [],
                workPhones: resultPhone || [],
                innCompany: formData.INN || '',
                bankName: formData.bankName || '',
                rs: formData.rs || '',
                bik: formData.BIK || '',
                ogRn: formData.OGRN || '',
                qrRs: formData.QRrs || '',
                qrStatic: formData.QRstatic || '',
                agreement: formData.contract || '',
                nameCompanyTitle: formData.name || '',
                phoneCompanyMain: normalizePhoneNumber(formData.leaderPhone) || '',
                commentariesMain: formData.messenger1 || '',
                commentariesFirst: formData.messenger2 || '',
                typeOfActivity: formData.siteName || '',
                live: true,
                siteCompanyMain: formData.soc1 || '',
                siteCompanyDop: formData.soc2 || '',
                addressCompany: formData.cityReg || '',
            }
            console.log('CREATE !!!', create)
            newCreateBDCompanies(create)
                .then(data => {
                    console.log('data mess', data)
                    alert(data)
                    if (data === 'Компания успешно создана!') {
                        setFormData(prev => ({
                            ...prev,
                            rs: '',
                            region: '',
                            city: '',
                            citybd: '',
                            leaderPhone: '',
                            messenger1: '',
                            messenger2: '',
                            contract: '',
                            BIK: '',
                            QRrs: '',
                            QRstatic: '',
                            workPhone1: '',
                            workPhone2: '',
                            soc1: '',
                            soc2: '',
                            amount: '',
                            storePayment: '',
                            siteName: '',
                            bankName: '',
                            lettersAddress: '',
                            districtReg: '',
                            postcode: ''
                        }));

                        setAdditionalCity([{address_custom: ''}])
                        setAdditionalPhone([{workPhone: ''}])
                        setDisableINN(false)
                        setOpenSearchResult(false)
                        setChecked(prev => ({
                            ...prev,
                            flowers: false,
                            strawberry: false,
                            balloon: false,
                            cakes: false,
                        }))
                        setFormData(prevData => ({
                            ...prevData,
                            name: '',
                            OGRN: '',
                            cityReg: '',
                            INN:''
                        }));
                        console.log('После очистки', formData)
                        setModalResultsINN([])
                        setInnHard(false)
                        console.log('notifId', idNotif)
                        if (/^\d+$/.test(idNotif)) {
                            deleteNotificationBD(idNotif)
                                .then(() => {})
                                .catch(error => {
                                    console.error("Error:", error);
                                    // здесь в случае ошибки вывести сообщение из бэка
                                    alert(error.message);
                                });
                        }
                        postUpdateOrder({
                            shop_number: additionalPhone[0].workPhone,
                        })
                    }
                })
                .catch(error => {
                    console.error('Ошибка при создании компании:', error);
                    alert('Произошла ошибка при создании компании: Компания с таким ИНН уже существует.');
                })
        } else {
            alert('Заполните обязательные поля ИНН, город и рабочий телефон')
        }
    }

    // useEffect(() => {
    //     console.log('formData.city', formData)
    // }, [formData.city]);

    const handleCheckboxChange = (key) => {
        setChecked(prevChecked => {
            const updatedChecked = {
                ...prevChecked,
                [key]: !prevChecked[key]
            };

            // Удаление лишнего ключа "true"
            delete updatedChecked.true;

            console.log("THE CREATE", updatedChecked);

            return updatedChecked;
        });
    };

    // useEffect(() => {
    //     console.log('on create', info.contractor)
    // }, [info]);

    // useEffect(() => {
    //     console.log('companyInfo', modalResultsINN)
    // }, [modalResultsINN]);

    const handleInputChange = (e, field) => {
        setInnHard(true)
        const {name, value} = e.target;

        if (field === "inn") {
            if (value.length === 0 || value === "8"|| value.length === 11) {
                setOpenSearchResult(false)
            } else {
                setOpenSearchResult(true)
            }
        }

        if (field === "bik") {
            if (value.length === 0) {
                setOpenSearchResultBanks(false)
            } else {
                setOpenSearchResultBanks(true)
            }
        }

        // if (name === "city") {
        //     setFormData(prevData => ({
        //         ...prevData,
        //         [name]: value,
        //     }));
        // } else {
        //     setFormData(prevData => ({...prevData, [name]: value}));
        // }

        setFormData(prevData => ({...prevData, [name]: value}));

        console.log('formDataСОЗДАНИЕ', formData)
    };


    const normalizePhoneNumber = (phoneNumber) => {
        if (phoneNumber !== '') {
            const digits = phoneNumber.replace(/\D/g, ''); // Удаление всех нечисловых символов
            if (digits.length > 10) {
                return "+7" + digits.slice(-10); // Если больше 10 цифр, обрезаем до 10 и добавляем +7 в начале
            } else {
                return "+7" + digits; // Если меньше или равно 10 цифр, добавляем +7 в начале
            }
        }
    }

    const normalizeINN = (inn) => {
        if (inn !== '') {
            return inn.replace(/\D/g, ''); // Remove non-numeric characters
        }
    }

    const createCompany = (e) => {

        let inns = info.contractor.filter(inn => inn.INN === formData.INN)
        console.log('inns', inns)
        if (inns.length > 0) {
            alert('Введенный ИНН уже существует!');
            e.preventDefault();
            return;
        }

        let nums = info.contractor.filter(num => num.leaderPhone === formData.leaderPhone)
        console.log('nums', nums)
        if (nums.length > 0) {
            alert('Введенный телефон руководителя уже существует!');
            e.preventDefault();
            return;
        }

        if (!formData.INN || !formData.leaderPhone) {
            alert('Пожалуйста введите ИНН и телефон руководителя');
            e.preventDefault();
            return; // Прерываем дальнейшее выполнение функции
        } else {
            formData.leaderPhone = normalizePhoneNumber(formData.leaderPhone);
            formData.workPhone1 = normalizePhoneNumber(formData.workPhone1); // Применяем normalizePhoneNumber к workPhone1 перед отправкой
            // formData.workPhone2 = normalizePhoneNumber(formData.workPhone2); // Применяем normalizePhoneNumber к workPhone2 перед отправкой

            // createCompanyBD(formData).then(async data => {
            //     alert(data);
            //     setFormData({
            //         name: '',
            //         INN: '',
            //         OGRN: '',
            //         rs: '',
            //         region: '',
            //         city: null,
            //         citybd: '',
            //         leaderPhone: '',
            //         contract: '',
            //         BIK: '',
            //         QRrs: '',
            //         QRstatic: '',
            //         workPhone1: '',
            //         workPhone2: '',
            //         siteName: '',
            //         bankName: '',
            //         messenger1: '',
            //         messenger2: '',
            //         soc1: '',
            //         soc2: '',
            //         amount: '0',
            //         storePayment: '0',
            //     })
            //     console.log('DATA create', data)
            // })
            //     .catch(error => {
            //         alert(error.message);
            //     });

            console.log('formDATA create', formData)

            setRender();
        }

    }

    // const tokenSent = (e, aim) => {
    //     if (e.target.value.length > 3) {
    //         let url = "http://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
    //         let token = "80a7758e1c4d6412649c75b3f4e070057bef8234";
    //         let query = e.target.value;
    //
    //         let options = {
    //             method: "POST",
    //             mode: "cors",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Accept": "application/json",
    //                 "Authorization": "Token " + token
    //             },
    //             body: JSON.stringify({query: query})
    //         }
    //
    //         fetch(url, options)
    //             .then(response => response.json())
    //             .then(result => {
    //
    //                 // const suggestions = result.suggestions.map((suggestion, index) => ({
    //                 //     // index: suggestion.data.postal_code || "",
    //                 //     // city: suggestion.data.city || "",
    //                 //     // country: suggestion.data.country || "",
    //                 //     // region: suggestion.data.region_with_type || "",
    //                 //     // // district: suggestion.data.city_district || ""
    //                 // }));
    //
    //                 if (aim === 'address_letters') {
    //                     console.log("result", result.suggestions)
    //                     setModalResultsLettersAddress(result.suggestions);
    //                     setOpenSearchResultLettersAddress(true)
    //                 } else {
    //                     console.log("result", result.suggestions)
    //                     setModalResults(result.suggestions);
    //                     setOpenSearchResultAddress(true)
    //                 }
    //             })
    //             .catch(error => console.log("error", error));
    //     }
    // }

    const tokenSentCity = (e, aim) => {
        if (e.target.value.length) {
            if (e.target.value.toLowerCase() === "петровск") {
                console.log('NOT city',e.target.value)
                const suggestions = {
                    postcode: "412540",
                    nameCityTitle: "Петровск",
                    countryTitle: "Россия",
                    regionTitle: "Саратовская обл",
                    area_with_typeTitle: "Петровский р-н",
                    geo_lat: "52.309431",
                    geo_lon: "45.385179",
                    settlement: ""
                };
                setModalResults([suggestions]);

            } else {
                let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
                let token = "80a7758e1c4d6412649c75b3f4e070057bef8234";
                let query = e.target.value;

                let options = {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Token " + token
                    },
                    body: JSON.stringify({query: query})
                }

                fetch(url, options)
                    .then(response => response.json())
                    .then(result => {
                        console.log("result ЧИСТЫЙ", result)

                        const filteredSuggestions = result.suggestions.filter(suggestion =>
                            (suggestion.data.city && suggestion.data.city.toLowerCase().includes(query?.toLowerCase())) ||
                            (suggestion.data.settlement && suggestion.data.settlement.toLowerCase().includes(query?.toLowerCase())) ||
                            (suggestion.data.region_with_type && suggestion.data.region_with_type.toLowerCase().includes(query?.toLowerCase())) ||
                            (suggestion.data.settlement_with_type && suggestion.data.settlement_with_type.toLowerCase().includes(query?.toLowerCase())) ||
                            (suggestion.data.settlement_with_typeTitle && suggestion.data.settlement_with_typeTitle.toLowerCase().includes(query?.toLowerCase()))
                        );

                        console.log("filteredSuggestions THE CREATE", filteredSuggestions)

                        const suggestions = filteredSuggestions.map((suggestion, index) => ({
                            postcode: suggestion.data.postal_code || "",
                            nameCityTitle: suggestion.data.city || "",
                            countryTitle: suggestion.data.country || "",
                            regionTitle: suggestion.data.region_with_type || "",
                            area_with_typeTitle: suggestion.data.area_with_type || suggestion.data.settlement_with_type || "",
                            geo_lat: suggestion.data.geo_lat || "",
                            geo_lon: suggestion.data.geo_lon || "",
                            settlement: suggestion.data.settlement || suggestion.data.settlement_with_typeTitle || "",
                        }));

                        setModalResults(suggestions);

                    })
                    .catch(error => console.log("error", error));
            }
        }
    }

    const tokenSentForINN = (e) => {
        if (e.target.value.length > 3) {
            let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
            let token = "80a7758e1c4d6412649c75b3f4e070057bef8234";
            let query = e.target.value;

            let options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({query: query})
            }

            fetch(url, options)
                .then(response => response.json())
                .then(result => {
                    let arr = result.suggestions;
                    for (let i = 0; i < arr.length; i++) {
                        let objINN = arr[i].data.state
                        console.log('objINN', objINN)
                        if (objINN.status === 'ACTIVE') {
                            console.log("result INN", arr[i])
                            setModalResultsINN([arr[i]]);
                            setOpenSearchResult(true)
                        }
                    }
                    // console.log("result INN", result.suggestions)
                    // setModalResultsINN(result.suggestions);
                    // setOpenSearchResult(true)
                })
                .catch(error => console.log("error", error));
        }
    }

    const tokenSentForBanks = (e) => {
        if (e.target.value.length > 3) {
            let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank"; // Изменяем URL для получения данных по банкам
            let token = "80a7758e1c4d6412649c75b3f4e070057bef8234"; // Ваш токен авторизации
            let query = e.target.value;

            let options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({query: query})
            }

            fetch(url, options)
                .then(response => response.json())
                .then(result => {
                    console.log("result BANKS", result.suggestions)
                    setModalResultsBanks(result.suggestions);
                    setOpenSearchResultBanks(true)
                })
                .catch(error => console.log("error", error));
        }
    }

    const arrangementCompanyData = () => {
        let data = modalResultsINN[0]
        setFormData(prevData => ({
            ...prevData,
            name: data.value,
            OGRN: data.data.ogrn,
            cityReg: data.data.address.unrestricted_value,
            region: data.data.address.data.region_with_type,
            districtReg: data.data.address.data.area_with_type,
            postcode: data.data.address.data.postal_code,
        }));
    }

    const arrangementBankBIK = (result) => {
        console.log('result BankBIK', result)
        let data = result

        setFormData(prevData => ({
            ...prevData,
            BIK: data.data.bic,
            bankName: data.value,
        }));
    }

    const arrangementLettersAddress = () => {
        let data = modalResultsLettersAddress[0]

        setFormData(prevData => ({
            ...prevData,
            lettersAddress: data.unrestricted_value,
        }));
    }

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,2})(\d{0,2})$/);

        if (match) {
            return `+7(${match[1]}${match[2] ? `)${match[2]}` : ''}${match[3] ? `-${match[3]}` : ''}${match[4] ? `-${match[4]}` : ''}`;
        }

        return phoneNumber;
    };

    const [countPhone, setCountPhone] = useState(2)


    const handleAddCity = () => {
        setAdditionalCity([...additionalCity, {address_custom: ''}]);
    };

    const handleAddPhone = () => {
        setAdditionalPhone([...additionalPhone, {workPhone: ''}]);
    };

    const handleCityChange = (index, e) => {
        const {value} = e.target;

        if (value.length === 0) {
            setSearchedResult(false)
        } else {
            setSearchedResult(true);
        }

        const updatedCities = [...additionalCity]; // Создаем копию массива additionalCity
        updatedCities[index] = {...updatedCities[index], address_custom: value, disabled: false}; // Обновляем объект города по индексу
        setAdditionalCity(updatedCities); // Устанавливаем обновленный массив в состояние
    };


    const showChange = (event) => {
        event.stopPropagation()
        // Remove the "active" class from all NavLink elements
        const navLinks = document.querySelectorAll('.search_results .search_results__wrapper');
        navLinks.forEach(link => link.classList.remove('active'));

        // Add the "active" class to the clicked NavLink
        event.target.classList.add('active');
    }


    useEffect(() => {
        console.log('formData ADDED CITY', formData)
        console.log('formData ADDED DOP CITY', additionalCity)
        console.log('formData ADDED DOP PHONE', additionalPhone)
    }, [formData, additionalCity, additionalPhone]);

    const arrangementAddress = (address, result) => {
        setAdditionalCity(prevState => {
            const createCity = {
                ...prevState,
                postcode: result.postcode || "",
                nameCityTitle: result.nameCityTitle || "",
                countryTitle: result.countryTitle || "",
                regionTitle: result.regionTitle || "",
                area_with_typeTitle: result.area_with_typeTitle || "",
                settlement_with_typeTitle: result.settlement_with_type || "",
                geo_lat: result.geo_lat || "",
                geo_lon: result.geo_lon || "",
                settlement: result.settlement || '',
                address_custom: `${result.settlement} ${result.nameCityTitle} ${result.regionTitle} ${result.area_with_typeTitle} ${result.postcode}`
            };
            return [...prevState.slice(0, prevState.length - 1), createCity];
        });
        console.log('result suggestions', result);
    }

    const handleDeleteCity = (index) => {
        setAdditionalCity(prev => {
            const newData = [...prev];
            newData.splice(index, 1);
            return newData;
        });
        setDisAddCity(false);
    };

    const handleDeletePhone = (index) => {
        setAdditionalPhone(prev => {
            const newData = [...prev];
            newData.splice(index, 1);
            return newData;
        });
        setDisAddPhone(false);
    };

    const [openInput, setOpenInput] = useState([])




    return (
        <>
            <header
                className="header save__header"
            >
                <div className="header__left">
                    <div>
                        <Link to={`/`}>X</Link>
                    </div>
                </div>
                <div className="header__right">
                    <div className="filter_"></div>
                    <div>
                        {/*<Link to={`/`} onClick={createCompany}>СОХРАНИТЬ</Link>*/}
                        <a onClick={() => {
                            sentBDCreate()
                        }} style={{cursor: "pointer"}}>СОХРАНИТЬ</a>
                    </div>
                </div>
            </header>
            <div className="create_organizations"
            >
                <div className="create_products">
                    <div>
                        <label htmlFor="flowers" className="create_products__label">
                            <input
                                type="checkbox"
                                id="flowers"
                                className="create_checkbox"
                                onChange={() => handleCheckboxChange("flowers")}
                                checked={checked.flowers}
                            />
                            <img src={shareFlowers} className="share_img products" width="25px" height="25px"/>
                        </label>
                    </div>
                    <div>
                        <label htmlFor="strawberry" className="create_products__label">
                            <input type="checkbox" id="strawberry" className="create_checkbox"
                                   onChange={() => handleCheckboxChange("strawberry")} checked={checked.strawberry}/>
                            <img src={shareStrawberry} className="share_img products" width="25px" height="25px"/>
                        </label>
                    </div>
                    <div>
                        <label htmlFor="balloon" className="create_products__label">
                            <input type="checkbox" id="balloon" className="create_checkbox"
                                   onChange={() => handleCheckboxChange("balloon")} checked={checked.balloon}/>
                            <img src={shareBaloon} className="share_img products" width="25px" height="25px"/>
                        </label>
                    </div>
                    <div>
                        <label htmlFor="cake" className="create_products__label">
                            <input type="checkbox" id="cake" className="create_checkbox"
                                   onChange={() => handleCheckboxChange("cakes")} checked={checked.cakes}/>
                            <img src={shareCake} className="share_img products" width="25px" height="25px"/>
                        </label>
                    </div>
                </div>
                <div className="create_fields">
                    <div>Название организации:</div>
                    <input className="item_" name="name" value={formData.name} onChange={handleInputChange}
                           placeholder="Введите название"/>
                    <div>
                        <div>ИНН:</div>
                        <input className={formData.INN ? "item_" : "item_-empty"}
                               style={{zIndex:"300"}}
                               name="INN"
                               value={innHard ? normalizeINN(formData.INN) : ''}
                               disabled={disableINN}
                               onChange={
                                   (e) => {
                                       tokenSentForINN(e);
                                       handleInputChange(e, 'inn');
                                   }

                               }
                               onFocus={()=>{
                                   setModalResultsINN([])
                               }}
                               placeholder="Введите ИНН"
                               required
                        />
                        <div className="deleteINN" onClick={()=>{
                            setDisableINN(false)
                            setOpenSearchResult(false)
                        setFormData(prevData => ({
                            ...prevData,
                            name: '',
                            OGRN: '',
                            cityReg: '',
                            INN:' '
                        }));
                        }}>х</div>
                        <div
                            className="search_results"
                            style={{display: openSearchResult ? "block" : "none"}}
                        >
                            {modalResultsINN && modalResultsINN.map((result) => {
                                console.log("INN datas", result)
                                return (
                                    <div className="search_results__wrapper" key={result.id}>
                                        <div>Выберете организацию: </div>
                                        <div
                                            className="search_results__item"
                                            onClick={() => {
                                                arrangementCompanyData()
                                                setOpenSearchResult(false)
                                                setDisableINN(true)
                                            }}
                                        >
                                            {result.unrestricted_value}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {openSearchResult && <div className="overlay"></div>}
                    </div>

                    <div>Расчетный счет:</div>
                    <input className="item_" name="rs" value={formData.rs} onChange={handleInputChange}
                           placeholder="Введите Р/С"/>
                    <div>ОГРН:</div>
                    <input className="item_" name="OGRN" value={formData.OGRN} onChange={handleInputChange}
                           placeholder="Введите ОГРН"/>
                    <div>
                        <div>БИК:</div>
                        <input className="item_" name="BIK" value={formData.BIK}
                               onChange={
                                   (e) => {
                                       tokenSentForBanks(e);
                                       handleInputChange(e, 'bik');
                                   }

                               } placeholder="Введите БИК"/>
                        <div
                            className="search_results"
                            style={{display: openSearchResultBanks ? "block" : "none"}}
                        >
                            {modalResultsBanks && modalResultsBanks.map((result) => {
                                console.log("Banks datas", result)
                                return (
                                    <div className="search_results__wrapper" key={result.id}>
                                        <div
                                            className="search_results__item"
                                            onClick={() => {
                                                arrangementBankBIK(result)
                                                setOpenSearchResultBanks(false)
                                            }}
                                        >
                                            {result.unrestricted_value}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div>Название банка:</div>
                    <input className="item_" name="bankName" value={formData.bankName}
                           onChange={handleInputChange} placeholder="Введите название банка"/>
                    <div>QR на RS:</div>
                    <input className="item_" name="QRrs" value={formData.QRrs} onChange={handleInputChange}
                           placeholder="Введите QR на р/с"/>
                    <div>QR статичный:</div>
                    <input className="item_" name="QRstatic" value={formData.QRstatic} onChange={handleInputChange}
                           placeholder="Введите QR на статичный"/>
                    <div>Договор:</div>
                    <input className="item_" name="contract" value={formData.contract} onChange={handleInputChange}
                           placeholder="Введите договор"/>
                    <div>Адрес регистрации ЮЛ:</div>
                    <input className="item_" name="cityReg" value={formData.cityReg} onChange={handleInputChange}
                           placeholder="Адрес регистрации ЮЛ"/>
                    <div className="create_cities">
                        {/*<input className="item_" name="city" value={additionalCity[0]?.address_custom} onChange={*/}
                        {/*    (e) => {*/}
                        {/*        tokenSentCity(e);*/}
                        {/*        handleCityChange(0, e);*/}
                        {/*        setSearchedResult(true)*/}
                        {/*    }*/}
                        {/*}*/}
                        {/*       placeholder="Введите город"*/}
                        {/*/>*/}
                        <div className="click_plus" style={{color:"red"}}>
                            Нажмите на <b>+</b> чтобы добавить город
                        </div>
                        <button disabled={disAddCity ? true : false} className="add_city" onClick={() => {
                            handleAddCity();
                            setDisAddCity(true)
                            setOpenInput(prevState =>
                                [...prevState, false]
                            )
                        }}>+
                        </button>
                        {/* Дополнительные города */}
                        {additionalCity && additionalCity.map((city, index) => (
                            <div key={index} className="input_item">
                                <div>Город {index + 1} </div>
                                <input
                                    className={city.address_custom ? "item_" : "item_-empty"}
                                    disabled={openInput[index]}
                                    name={`city${index + 1}`}
                                    value={city.address_custom}
                                    onChange={(e) => {
                                        handleCityChange(index, e);
                                        tokenSentCity(e);
                                    }}
                                    placeholder={`Введите город ${index + 1}`}
                                />
                                <button className="delete_city" onClick={() => {
                                    if (additionalCity.length > 1) {
                                        handleDeleteCity(index);
                                    }
                                    else if (additionalCity.length === 1) {
                                        const updatedCity = { ...city, address_custom: '' };
                                        setAdditionalCity([updatedCity]);
                                        setOpenInput([])
                                        setDisAddCity(true)
                                    }
                                }}>x
                                </button>
                            </div>
                        ))}
                        <div
                            className="search_results"
                            style={searchedResult ? {display: "block"} : {display: "none"}}
                        >
                            <div>Выберете город из списка:</div>
                            <br/>
                            {modalResults && modalResults.map((result) => {
                                // console.log("FRONT", result)
                                if (result.postal_code !== null) {
                                    // setSearchedResult(true)
                                    return (
                                        <div className="search_results__wrapper" key={result.id} onClick={showChange}>
                                            <div
                                                className="search_results__item"
                                                onClick={() => {
                                                    setSearchedResult(false)
                                                    const res = result.settlement + ', ' + result.nameCityTitle + ', ' + result.regionTitle + ', ' +
                                                        result.area_with_typeTitle + ', ' + result.postcode + ', ' + result.settlement_with_typeTitle;
                                                    arrangementAddress(res, result)
                                                    setDisAddCity(false)
                                                    setOpenInput(prevState => {
                                                        // Создаем новый массив, в котором все элементы копируются из предыдущего состояния
                                                        // Затем устанавливаем значение true для последнего элемента
                                                        return prevState.map((item, index) => (index === prevState.length - 1 ? true : item));
                                                    });
                                                }}
                                            >
                                                <div onClick={() => setSearchedResult(false)}>
                                                    {`${result.settlement} ${result.nameCityTitle} ${result.regionTitle} 
                                                    ${result.area_with_typeTitle} ${result.postcode}`}
                                                </div>

                                            </div>
                                        </div>
                                    );
                                } else {
                                    return null; // Или другое действие, если city пустой
                                }
                            })}
                        </div>
                        {searchedResult && <div className="overlay"></div>}
                    </div>
                    {/*<input className="item_" name="region" value={formData.region} onChange={handleInputChange}*/}
                    {/*       placeholder="Введите регион"/>*/}
                    {/*<div>*/}
                    {/*    <input className="item_" name="lettersAddress" value={formData.lettersAddress} onChange={*/}
                    {/*        (e) => {*/}
                    {/*            tokenSentCity(e, 'address_letters');*/}
                    {/*            handleInputChange(e);*/}
                    {/*        }*/}
                    {/*    }*/}
                    {/*           placeholder="Введите адрес для писем"/>*/}
                    {/*    <div*/}
                    {/*        className="search_results"*/}
                    {/*        style={{display: openSearchResultLettersAddress ? "block" : "none"}}*/}
                    {/*    >*/}
                    {/*        {modalResultsLettersAddress && modalResultsLettersAddress.map((result) => {*/}
                    {/*            console.log("FRONT", result)*/}
                    {/*            if (result.data.postal_code !== null) {*/}
                    {/*                return (*/}
                    {/*                    <div className="search_results__wrapper" key={result.id}>*/}
                    {/*                        <div*/}
                    {/*                            className="search_results__item"*/}
                    {/*                            onClick={() => {*/}
                    {/*                                setOpenSearchResultLettersAddress(false)*/}
                    {/*                                arrangementLettersAddress()*/}
                    {/*                            }}*/}
                    {/*                        >*/}
                    {/*                            {result.unrestricted_value}*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                );*/}
                    {/*            } else {*/}
                    {/*                return null; // Или другое действие, если city пустой*/}
                    {/*            }*/}
                    {/*        })}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<input name="leaderPhone" value={formData.leaderPhone}*/}
                    {/*       className={formData.leaderPhone ? "item_" : "item_-empty"}*/}
                    {/*       onChange={handleInputChange} placeholder="Введите телефон руководителя" required/>*/}
                    <div className="create_phones">
                        <div className="create_phone">
                            <div className="click_plus" style={{color:"red"}} >
                                Нажмите на <b>+</b> чтобы добавить рабочий телефон
                            </div>
                            <button disabled={disAddPhone ? true : false} className="add_phone" onClick={() => {
                                handleAddPhone();
                                setDisAddPhone(true)
                            }}>+
                            </button>
                            {additionalPhone && additionalPhone.map((phone, index) => (
                                <div key={index} className="input_item">
                                    <div>Рабочий телефон {index + 1}</div>
                                    <InputMask
                                        className="item_"
                                        mask="+7(999)999-99-99"
                                        maskChar="_"
                                        value={phone.workPhone}
                                        name={`workPhone${index + 1}`}
                                        // style={{
                                        //     // color: String(phone.dopMain) === String(idCity) && phone.dop === "check" || colorPhone[index] ? "red" : "black",
                                        //     color: colorPhone[index] ? "red" : "black",
                                        //     cursor: "pointer"
                                        // }}
                                        // title="Нажмите чтобы сделать главным"
                                        // onClick={() => {
                                        //     const updatedColors = additionalPhone.map((_, idx) => idx === index ? true : false);
                                        //     setColorPhone(updatedColors);
                                        // }}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setAdditionalPhone(prevData => {
                                                const updatedPhones = [...prevData];
                                                updatedPhones[index] = {
                                                    ...updatedPhones[index],
                                                    workPhone: value,
                                                    dop: 'check',
                                                };
                                                return updatedPhones;
                                            });
                                            // setTimeout(() => {
                                                const lastChar = value[value.length - 1];
                                                if (lastChar !== '_' ) {
                                                    setDisAddPhone(false);
                                                } else {
                                                    setDisAddPhone(true);
                                                }
                                            // }, 0); // Нулевая задержка для моментальной проверки
                                        }}
                                        placeholder={`Введите рабочий телефон ${index + 1}`}
                                    />
                                    <button className="delete_phone" onClick={() => handleDeletePhone(index)}>x</button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>Телефон руководителя: </div>
                    <InputMask mask="+7(999)999-99-99" maskChar="_" name="leaderPhone"
                               value={formData.leaderPhone} onChange={handleInputChange}
                               placeholder="Введите телефон руководителя"
                               className="item_"/>
                    <div>Социальная сеть: </div>
                    <input className="item_" name="soc1" value={formData.soc1}
                           onChange={handleInputChange} placeholder="Введите соцсеть 1"/>
                    <div>Социальная сеть: </div>
                    <input className="item_" name="soc2" value={formData.soc2}
                           onChange={handleInputChange} placeholder="Введите соцсеть 2"/>
                    {/*<input className="item_" name="siteName" value={formData.siteName}*/}
                    {/*       onChange={handleInputChange} placeholder="Введите название сайта"/>*/}
                    <div>Комментарий: </div>
                    <textarea className="item_" name="messenger1" value={formData.messenger1}
                              onChange={handleInputChange} placeholder="Комментарий 1"/>
                    <div>Комментарий: </div>
                    <textarea className="item_" name="messenger2" value={formData.messenger2}
                              onChange={handleInputChange} placeholder="Комментарий 2"/>
                </div>
            </div>
        </>
    );
};

export {TheCreate};

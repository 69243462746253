import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {check, getCheckedBun, login, registration} from "./http/userAPI";
//import {Link} from "react-router-dom";
import leftImg from "./img/left_arrow.svg";


const MyComponent = () => {
    const [loginOk, setLoginOk] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [adminPassword, setAdminPassword] = useState('');
    const [token, setToken] = useState('');
    const [tokenView, setTokenView] = useState(localStorage.getItem('tokenView') || '');
    const [regOk, setRegOk] = useState(false)
    const [modalCreate, setModalCreate] = useState(false)

    const [selectRole, setSelectRole] = useState('admin')
    const [dis, setDis] = useState(true)

    useEffect(() => {
        const tokenViewString = JSON.parse(localStorage.getItem('tokenView'));
        if (tokenViewString) {
            // const tokenViewObject = JSON.parse(tokenViewString);
            // Now you can use tokenViewObject as needed
            const result = {
                role: tokenViewString.role
            }
            setToken(result)
            console.log('сработало')
            setLoginOk(true);
        }
    }, [tokenView]);


    // useEffect(() => {
    //     const ws = new WebSocket('wss://app.my-bestflowers.ru/ws');
    //     ws.onopen = () => {
    //         console.log('WebSocket connected');
    //         // Отправка сообщения после успешного установления соединения
    //         ws.send('Hello, server!');
    //     };
    //     ws.onmessage = (event) => {
    //         console.log('Received message:', event.data);
    //     };
    //     return () => {
    //         ws.close();
    //     };
    // }, []);

    // useEffect(() => {
    //     const interval = setInterval(async () => {
    //         const tokenViewString = JSON.parse(localStorage.getItem('tokenView'));
    //         if (tokenViewString) {
    //             console.log('!!!!!useEffect!!!!!tokenViewString', tokenViewString);
    //
    //             const bun = {
    //                 name: tokenViewString.email
    //             };
    //             try {
    //                 const emailUser = await getCheckedBun(bun);
    //
    //                 console.log('!!!!!!emailUser', emailUser)
    //                 if (emailUser.name === 'true') {
    //                     localStorage.removeItem('tokenView');
    //                     setLoginOk(false);
    //                     window.location.reload();
    //                 }
    //             } catch (error) {
    //                 console.error('Error fetching user data:', error);
    //             }
    //         }
    //     }, 10000); // 10 секунд в миллисекундах
    //
    //     return () => clearInterval(interval); // Чтобы очистить интервал при размонтировании компонента
    // }, []);

    const handleUserAction = async () => {
        const tokenViewString = JSON.parse(localStorage.getItem('tokenView'));
        if (tokenViewString) {
            // console.log('!!!!!useEffect!!!!!tokenViewString', tokenViewString);

            const bun = {
                name: tokenViewString.email
            };
            try {
                const emailUser = await getCheckedBun(bun);

                // console.log('!!!!!!emailUser', emailUser);
                if (emailUser.name === 'true') {
                    localStorage.removeItem('tokenView');
                    setLoginOk(false);
                    window.location.reload();
                } else {
                    return
                }
            } catch (error) {
                // console.error('Error fetching user data:', error);
                return
            }
        }
    };

// Добавляем слушатель события клика на всем документе
    document.addEventListener('click', handleUserAction);

    const getUser = async (email, password) => {
            try {
                console.log("ЗАПУСТИЛСЯ", email, password);
                const result = await login(email, password)
                console.log('Значение переменной result:', result);
                if (result) {
                    setLoginOk(true);
                    setToken(result)
                    localStorage.setItem('tokenView', JSON.stringify(result));
                    const storedTokenView = JSON.parse(localStorage.getItem('tokenView'));
                    console.log('result', result)
                    console.log('storedTokenView', storedTokenView);
                } else {
                    setLoginOk(false);
                }
                console.log("User:", email, password);
            } catch (error) {
                if (error.response && error.response.status === 500) {
                    alert("Вы ввели неправильные данные");
                    setEmail("");
                    setPassword("");
                } else {
                    console.log("Произошла ошибка с другим статус-кодом");
                }
            }

    };


    const handleSubmit = (e) => {
        e.preventDefault(); // Предотвращение перезагрузки страницы
        getUser(email, password);
    };

    const handleSelect = (e) => {
        e.preventDefault();
        setSelectRole(e.target.value)
    }


    const createUsers = (selectRole, email, password, adminPassword, inn, update) => {

        const users = {
            role: selectRole,
            email: email,
            password: password,
            adminPassword: adminPassword,
            INN:inn || '',
            update: update || false
        };
        registration(users)
            .then(res => {
                if (res.status === 'ok') {
                    alert("Пользователь успешно создан");
                } else if (res.status === 'yes') {
                    setModalCreate(true)
                    setRegOk(true)
                } else if (res.status === 'update'){
                    alert("Пароль пользователь успешно обновлен");
                }
            })
            .catch(error => {
                alert("Ошибка при регистрации пользователя")
            });
    }

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f0f0f0',
            fontFamily: 'Arial, sans-serif',
        },
        formItem: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '20px',
            borderRadius: '5px',
            backgroundColor: 'white',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.15)',
            width: '78vw'
        },
        input: {
            width: '100%', // make the input take the full width of its container
            padding: '10px 0 15px 4px',
            marginLeft: '0',
            marginRight: '0',
            border: 'none',
            borderRadius: '5px',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.15)',
        },
        button: {
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            backgroundColor: '#00b2e2',
            color: 'white',
            cursor: 'pointer',
            transition: 'background-color 0.2s ease',
        },
        buttonHover: {
            backgroundColor: '#0056b3',
        },
        header: {
            backgroundColor: '#00b2e2',
            fontSize: "14px",
        },
        logoImg: {
            marginRight: "10px",
        }
    };


    // useEffect(() => {
    //     if (loginOk === true) {
    //         setLoginOk(true);
    //     } else setLoginOk(false)
    // }, []);

    return (
        <>
            <div style={{ display: modalCreate ? "block" : "none" }}>
                Пользователь существует, введите новый пароль и нажмите "Да" ?
                <button onClick={()=> {
                    createUsers(selectRole, email, password, adminPassword, '', true)
                    setModalCreate(false)
                    setRegOk(false)
                }}>да</button> <button onClick={()=>setModalCreate(false)}>нет</button>
            </div>
            <React.StrictMode>
                {loginOk ?
                    <App role={token}/> :
                    <div style={styles.container}>
                        <div style={{...styles.formItem, display: regOk ? "none" : "flex"}}>
                            <div style={{width: "400px"}}>
                                {/*<SmartCaptcha*/}
                                {/*    sitekey="ysc1_NUjnhORxgGoO5AxL2osc3hzHHcjTkfjyL2RBcSXL1b19d9bb"*/}
                                {/*    onSuccess={setToken}*/}
                                {/*/>*/}
                            </div>
                            <h2>Вход в систему</h2>
                            <form style={styles.form} onSubmit={handleSubmit}>
                                <label>Email:
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        style={styles.input}
                                    />
                                </label>
                                <label>Password:
                                    <input
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        style={styles.input}
                                    />
                                </label>
                                <button type="submit" style={styles.button}>
                                    Войти
                                </button>
                                {/*<div*/}
                                {/*    onClick={() => {*/}
                                {/*        setRegOk(true);*/}
                                {/*        // setLoginOk(false)*/}
                                {/*    }}*/}
                                {/*    style={{...styles.button, cursor: "pointer", textAlign: "center", fontSize: "13px"}}*/}
                                {/*>Открыть форму регистрации*/}
                                {/*</div>*/}
                            </form>
                        </div>
                        <div style={{...styles.formItem, display: regOk ? "flex" : "none"}}>
                            <h2>Регистрация</h2>
                            <form style={styles.form}>
                                <header
                                    className="header order__header"
                                    style={styles.header}
                                >
                                    <div className="header__left">
                                        <div onClick={() => setRegOk(false)} style={styles.logoImg}>
                                            <img src={leftImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                        Назад
                                    </div>
                                    <div className="header__name"
                                    >
                                    </div>
                                    <div className="header__right">

                                    </div>
                                </header>
                                <label>Email:
                                    <input
                                        type="email"
                                        value={email}
                                        style={styles.input}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </label>
                                <label>Password:
                                    <input
                                        type="password"
                                        value={password}
                                        style={styles.input}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </label>
                                <label>From Admin Code
                                    <input
                                        type="password"
                                        value={adminPassword}
                                        style={styles.input}
                                        onChange={(e) => setAdminPassword(e.target.value)}
                                    />
                                </label>
                                <label>Для роли:
                                    <select
                                        name="filterby"
                                        className="filterby"
                                        aria-label="Поле в списке"
                                        onChange={handleSelect}
                                    >
                                        <option value="admin">Администратор</option>
                                        <option value="buh">Бухгалтер</option>
                                        <option value="manager">Менеджер</option>
                                        {/*<option value="shop">Магазин</option>*/}
                                    </select>
                                </label>
                                <button type="submit" style={styles.button} onClick={(e) => {
                                    e.preventDefault();
                                    setRegOk(false);


                                    createUsers(selectRole, email, password, adminPassword, '', false)

                                }}>
                                    Регистрация
                                </button>
                            </form>
                        </div>
                    </div>
                }
            </React.StrictMode>
        </>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<MyComponent/>);
reportWebVitals();

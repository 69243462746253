async function sortByPriority(result) {
    console.log('sortByPriority result',result)
    let agreement_qrStatic_rs_qrRs_Arr = [];
    let agreement_qrStatic_Arr = [];
    let agreement_rs_qrRs_Arr = [];
    let qrStatic_rs_qrRs_Arr = [];

    let agreement_Arr = [];
    let qrStatic_Arr = [];
    let rs_qrRs_Arr = [];

    let empty = []

    if (result && result.length > 0 && result[0].companyAlls) {
        for (let i = 0; i < result[0].companyAlls.length; i++) {
            let card = result[0].companyAlls[i];
            // console.log('card THE BODY', card);

            if (card.agreement !== '' && card.qrStatic !== '' && (card.rs !== '' || card.qrRs !== '')) {
                agreement_qrStatic_rs_qrRs_Arr.push(card);
            }
            else if (card.agreement !== '' && card.qrStatic !== '' && (card.rs === '' || card.qrRs === '')) {
                agreement_qrStatic_Arr.push(card);
            }
            else if (card.agreement !== '' && card.qrStatic === '' && (card.rs !== '' || card.qrRs !== '')) {
                agreement_rs_qrRs_Arr.push(card);
            }
            else if (card.agreement === '' && card.qrStatic !== '' && (card.rs !== '' || card.qrRs !== '')) {
                qrStatic_rs_qrRs_Arr.push(card);
            }
            else if (card.agreement !== '' && card.qrStatic === '' && (card.rs === '' || card.qrRs === '')) {
                agreement_Arr.push(card);
            }
            else if (card.agreement === '' && card.qrStatic !== '' && (card.rs === '' || card.qrRs === '')) {
                qrStatic_Arr.push(card);
            }
            else if (card.agreement === '' && card.qrStatic === '' && (card.rs !== '' || card.qrRs !== '')) {
                rs_qrRs_Arr.push(card);
            }
            else if (card.agreement === '' && card.qrStatic === '' && card.rs === '' && card.qrRs === '') {
                empty.push(card);
            }
        }
    }

    const sortBySumOfOrders = (arr) => {
        if (arr.length > 0) {
            let mapArr = arr.map((item) => {
                let sumAll = item.orders.reduce((total, order) => total + Number(order.sentSum.replace(/\D/g, '')), 0);
                return { item, sumAll };
            });

            mapArr && mapArr.sort((a, b) => b.sumAll - a.sumAll);

            return mapArr.map((obj) => obj.item);
        } else {
            return []
        }
    };

    // console.log('agreement_qrStatic_rs_qrRs_Arr', agreement_qrStatic_rs_qrRs_Arr);
    const sorted_agreement_qrStatic_rs_qrRs_Arr = sortBySumOfOrders(agreement_qrStatic_rs_qrRs_Arr);
    // console.log('sorted_agreement_qrStatic_rs_qrRs_Arr', sorted_agreement_qrStatic_rs_qrRs_Arr);

    // console.log('agreement_rs_qrRs_Arr', agreement_rs_qrRs_Arr);
    const sorted_agreement_rs_qrRs_Arr = sortBySumOfOrders(agreement_rs_qrRs_Arr);
    // console.log('sorted_agreement_rs_qrRs_Arr', sorted_agreement_rs_qrRs_Arr);

    // console.log('agreement_qrStatic_Arr', agreement_qrStatic_Arr);
    const sorted_agreement_qrStatic_Arr = sortBySumOfOrders(agreement_qrStatic_Arr);
    // console.log('sorted_agreement_qrStatic_Arr', sorted_agreement_qrStatic_Arr);

    // console.log('qrStatic_rs_qrRs_Arr', qrStatic_rs_qrRs_Arr);
    const sorted_qrStatic_rs_qrRs_Arr = sortBySumOfOrders(qrStatic_rs_qrRs_Arr);
    // console.log('sorted_qrStatic_rs_qrRs_Arr', sorted_qrStatic_rs_qrRs_Arr);

    // console.log('agreement_Arr', agreement_Arr);
    const sorted_agreement_Arr = sortBySumOfOrders(agreement_Arr);
    // console.log('sorted_agreement_Arr', sorted_agreement_Arr);

    // console.log('qrStatic_Arr', qrStatic_Arr);
    const sorted_qrStatic_Arr = sortBySumOfOrders(qrStatic_Arr);
    // console.log('sorted_qrStatic_Arr', sorted_qrStatic_Arr);

    // console.log('rs_qrRs_Arr', rs_qrRs_Arr);
    const sorted_rs_qrRs_Arr = sortBySumOfOrders(rs_qrRs_Arr);
    // console.log('sorted_rs_qrRs_Arr', sorted_rs_qrRs_Arr);

    // console.log('empty', empty);
    const sorted_empty = sortBySumOfOrders(empty);
    // console.log('sorted_empty', sorted_empty);

    let sortedArr = [
        ...sorted_agreement_qrStatic_rs_qrRs_Arr,
        ...sorted_agreement_rs_qrRs_Arr,
        ...sorted_agreement_qrStatic_Arr,
        ...sorted_qrStatic_rs_qrRs_Arr,
        ...sorted_agreement_Arr,
        ...sorted_rs_qrRs_Arr,
        ...sorted_qrStatic_Arr,
        ...sorted_empty
    ]

    // console.log('sortedArr', sortedArr)

    // setNames(sortedArr)
    // if (result && result.length > 0 && result[0].companyAlls) {
    //     result[0].companyAlls.sort((a, b) => {
    //         console.log('sort useEffect');
    //         const completenessA = (a.agreement !== '') * 2 + (a.qrStatic !== '') + (+!!(a.rs || a.qrRs));
    //         const completenessB = (b.agreement !== '') * 2 + (b.qrStatic !== '') + (+!!(b.rs || b.qrRs));
    //
    //         if (completenessB !== completenessA) {
    //             return completenessB - completenessA;
    //         } else {
    //             return b.sumAll - a.sumAll;
    //         }
    //     });
    // } else {
    //     console.error("Result or companyAlls is undefined or empty.");
    // }

    return sortedArr;
}

export {sortByPriority}
import {$host} from "./index";

export const createCity = async (company) => {
    console.log("city запрос:", company)
    const {data} = await $host.post(`api/city/createCity`, {company: company})
    return data
}

export const getCompaniesByCityName = async (company) => {
    console.log("city запрос:", company)
    const {data} = await $host.post(`api/city/getCompaniesByCityName`, company)
    return data
}

export const getCompaniesAll = async () => {
    const {data} = await $host.post(`api/city/getCompaniesAll`)
    return data
}

export const updateCompanyLive = async (updateCompany, live, liveComment) => {
    console.log('companyLive', updateCompany, live, liveComment)
    const {data} = await $host.post('api/city/updateCompanyLive' , {id: updateCompany, live: live, liveComment: liveComment})
    console.log('companyLive DATA', data)
    return data
}


export const upDateCities = async (company) => {
    console.log("city запрос:", company)
    const {data} = await $host.post(`api/city/upDateCities`, {company: company})
    return data
}

export const groupCityI = async () => {
    // console.log("city запрос:", company)
    const {data} = await $host.post(`api/city/groupCityI`, )
    return data
}

export const getNumberBD = async (dataFront) => {
    // console.log("city запрос:", company)
    const {data} = await $host.post(`api/city/getNumberBD`, dataFront)
    return data
}

export const getTitleNameBD = async (dataFront) => {
    console.log("getTitleNameBD запрос:", dataFront)
    const {data} = await $host.post(`api/city/getTitleNameBD`,{city: dataFront})
    return data
}

export const getCardsWithCoom2 = async () => {
    const {data} = await $host.post(`api/city/getCardsWithCoom2`)
    return data
}

export const getCardsGeo = async (city) => {
    console.log("getCardsGeo запрос:", city)
    const {data} = await $host.post(`api/city/getCardsGeo`, {city: city})
    return data
}

export const getOneCity = async (city) => {
    console.log("getOneCity запрос:", city)
    const {data} = await $host.post(`api/city/getOneCity`, {city: city})
    return data
}

export const cityCoordinatesUpdateBD = async (coordinates) => {
    console.log("cityCoordinates UPDATE запрос:", coordinates)
    const {data} = await $host.post(`api/city/cityCoordinatesUpdateBD`, coordinates)
    return data
}

export const postBd = async () => {
    const {data} = await $host.post(`api/city/postBd`)
    return data
}

export const clearBd = async () => {
    const {data} = await $host.post(`api/city/clearBd`)
    return data
}

export const createComponyWordPress = async () => {
    const {data} = await $host.post(`api/city/createComponyWordPress`)
    return data
}



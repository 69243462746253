import {$host} from "./index";
// $host.get('api/upload/update.js')
//import jwt_decode from "jwt-decode";

export const registration = async (formDataReg) => {
    console.log('formDataReg', formDataReg)
    const {data} = await $host.post('api/user/registration', {formDataReg})
    //localStorage.setItem('token', data.token)
    return data
}

export const login = async (email, password) => {
    console.log('login', email, password)
    const {data} = await $host.post('api/user/login', {email, password})
    return data
}

export const check = async () => {
    const {data} = await $host.get('api/user/auth' )
    //localStorage.setItem('token', data.token)
    return data.token
}

export const getUser = async () => {
    const {data} = await $host.get('api/user/getUser' )
    return data
}

export const updateUser = async (formData) => {
    console.log('formData', formData)
    const {data} = await $host.post('api/user/updateUser' , formData)
    return data
}

export const bunUser = async (bun) => {
    console.log('bun', bun)
    const {data} = await $host.post('api/user/bunUser' , bun)
    return data
}

export const getCheckedBun = async (bun) => {
    console.log('bun', bun)
    const {data} = await $host.post('api/user/getCheckedBun' , bun)
    return data
}


export const deleteUserBD = async (del) => {
    console.log('del', del)
    const {data} = await $host.post('api/user/deleteUserBD' , del)
    return data
}

// export const makeDerg = async () => {
//     const {data} = await $host.get('api/upload/makeDerg')
//     return data
// }

// $host.get('api/upload/update.js')



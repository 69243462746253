// const axios= require("axios");
import axios from 'axios'

//const baseURL = process.env.NODE_ENV !== 'production' ? 'https://localhost:5000' : 'https://localhost:5000';

const $host = axios.create({
    //baseURL:'http://51.250.51.88:5000'
    //baseURL: 'http://localhost:5001'
    //baseURL: 'http://10.129.0.14:5001'
    //baseURL: 'http://localhost:5000'
    //baseURL: 'http://158.160.29.34:5000'
    //baseURL: 'https://torto-eshka.ru/'
    //baseURL: baseURL
    //baseURL: '/'
    baseURL: 'https://app.my-bestflowers.ru/'
})

export {
    $host
}

// const $host = axios.create({
//     baseURL: 'http://localhost:5000'
// });
//
// module.exports = { $host };

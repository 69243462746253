import React, { useEffect, useState } from 'react';
import {getCitySearch, getOrderShopBD, getOrderShopMainBD, getPars} from "../http/orderShopAPI";
import { MoonLoader } from 'react-spinners';
import ImageUploader from './functions/ImageUploader';
import './TheOrderShop.css';
import { Button, Image } from "react-bootstrap";
import ReactDadataBox from 'react-dadata-box';
import axios from 'axios';

const TheOrderShop = ({ info }) => {
    const [orderShop, setOrderShop] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isUploaderVisible, setUploaderVisible] = useState(false);
    const [idBitrix, setIdBitrix] = useState();
    const [cityEnter, setCityEnter] = useState({});
    const [citySearch, setCitySearch] = useState("")
    const [citySearches, setCitySearches] = useState({});
    const [parsedData, setParsedData] = useState({});
    const [imageSrc, setImageSrc] = useState(`'https://via.placeholder.com/250'; g`);
    const [imageSources, setImageSources] = useState({});

    const handleGetPars = async (url, orderId) => {
        try {
            const response = await getPars(url);
            const parsedResponse = typeof response === 'string' ? response : JSON.stringify(response);
            setParsedData(prevState => ({
                ...prevState,
                [orderId]: parsedResponse,
            }));

            if (response.imageUrl) {
                setImageSources(prevState => ({
                    ...prevState,
                    [orderId]: response.imageUrl, // Обновляем только для конкретного orderId
                }));
            }
        } catch (error) {
            console.error("Ошибка получения данных:", error);
        }
    };

    useEffect(() => {
        const fetchData = () => {
            getOrderShopMainBD()
                .then(response => {
                    setOrderShop(response);
                })
                .catch(error => {
                    console.error("Error fetching order shop data:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        };
        fetchData();
        // const intervalId = setInterval(fetchData, 5000);
        // return () => clearInterval(intervalId);
    }, []);

    const start = () => {
        getOrderShopBD().then();
    };

    const handleUploadClick = (idBitrix) => {
        console.log("idBitrix", idBitrix)
        setUploaderVisible(true);
        setIdBitrix(idBitrix);
    };

    const toggleCityEnter = (orderId, city) => {
        setCitySearch(city)
        setCityEnter(prevState => ({
            ...prevState,
            [orderId]: !prevState[orderId]
        }));
    };

    const handleResult = (data, orderId) => {
        setCitySearches(prevState => ({
            ...prevState,
            [orderId]: data ? data : ""
        }));
    };


    // const parsCiteGo = (url) => {
    //
    //     console.log("url", url)
    // };




    return (
        <div className="containerOrderShop">
            <br />
            <Button style={{ width: "250px", marginLeft: "10px" }} onClick={start}>запуск синхронизации</Button>
            <br />
            <div className="loader-containerOrderShop">
                {loading ? (
                    <MoonLoader size={60} color={"#3498db"} loading={loading} />
                ) : (
                    <div className="orderShopOrderShop">
                        {Object.keys(orderShop).length > 0 ? (
                            Object.entries(orderShop).map(([link, managers]) => (
                                <div key={link} className="">
                                    <h4>{link}</h4>
                                    {Object.entries(managers).map(([dop, orders]) => (
                                        <div key={dop} className="managerBlockOrderShop">
                                            <h4>
                                                {dop !== "Робот менеджер" && orders.filter(ord => ord.comments !== "").length > 0 && (
                                                    <>
                                                        {dop} {orders.filter(ord => ord.comments !== "").length} шт.
                                                    </>
                                                )}
                                            </h4>
                                            <div className="orderBlock">
                                                {orders.map((order, index) => {
                                                    if (!order.comments || !order.comments.trim().length || dop === "Робот менеджер") {
                                                        return null;
                                                    }
                                                    const imageUrl = `./img/photoordershop/${order.idBitrix}.jpg`;
                                                    return (
                                                        <div key={index} className="orderShopBlockOrderShop">
                                                            <div className="imageContainer">
                                                                <Image
                                                                    src={imageSources[order.idBitrix] || 'https://via.placeholder.com/250'}
                                                                    alt={`Order ${order.idBitrix}`}
                                                                    style={{
                                                                        objectFit: 'cover',
                                                                        width: '250px',
                                                                        height: '250px'
                                                                    }}
                                                                    onError={(e) => {
                                                                        e.target.onerror = null;
                                                                        e.target.src = parsedData[order.idBitrix]?.imageUrl
                                                                            ? parsedData[order.idBitrix].imageUrl
                                                                            : 'https://via.placeholder.com/250';
                                                                    }}
                                                                    onClick={() => handleUploadClick(order.idBitrix)}
                                                                />
                                                                <div
                                                                    className="hoverText"
                                                                    onClick={() => handleUploadClick(order.idBitrix)}
                                                                >
                                                                    Нажми, чтобы загрузить или изменить фото
                                                                </div>
                                                            </div>
                                                            <br/>
                                                            <a
                                                                className="linkStyleOrderShop"
                                                                href={`${link}crm/deal/details/${order.idBitrix}/`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                Ссылка на сделку Битрикс24
                                                            </a>
                                                            {order.comments.split("\n").map((comment, i) => {
                                                                const urlRegex = /\[url=(.*?)\](.*?)\[\/url\]/g;
                                                                const urls = [];
                                                                let match;

                                                                while ((match = urlRegex.exec(comment)) !== null) {
                                                                    urls.push(match[1]); // Сохраняем ссылку
                                                                }

                                                                return (
                                                                    urls.length > 0 && (
                                                                        <div key={i}>
                                                                            {urls.map((url, idx) => (
                                                                                <div key={idx}>
                                                                                    <div>
                                                                                        <a
                                                                                            className="linkStyleOrderShop"
                                                                                            href={url} target="_blank" rel="noopener noreferrer">
                                                                                            Ссылка на букет
                                                                                        </a>
                                                                                    </div>
                                                                                    <div style={{ whiteSpace: 'pre-line' }}>
                                                                                       <div>
                                                                                           Состав:
                                                                                       </div>
                                                                                        {typeof parsedData[order.idBitrix] === 'string'
                                                                                        ? parsedData[order.idBitrix]
                                                                                                .replace(/["[\]{}]/g, '') // Убираем кавычки, квадратные и фигурные скобки
                                                                                                .replace(/&nbsp;/g, ' ') // Заменяем неразрывные пробелы
                                                                                                .replace(/imageUrl:.*?(,|$)/, '') // Убираем imageUrl и его значение
                                                                                                .replace(/ingredients:/g, '') // Убираем слово "ingredients:"
                                                                                                .replace(/<\/?span>/g, '') // Убираем теги <span> и </span>
                                                                                                .replace(/([А-Я])/g, '\n$1') // Добавляем новую строку перед заглавными буквами
                                                                                                .trim() // Обрезаем лишние пробелы
                                                                                        : 'Данные отсутствуют'}
                                                                                    </div>
                                                                                    <Button onClick={() => handleGetPars(url, order.idBitrix)}>Получить состав и фото</Button>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )
                                                                );
                                                            })}

                                                            <div>
                                                                <br/>
                                                                Город доставки: {
                                                                cityEnter[order.idBitrix]
                                                                    ? <Button className="btn btn-danger" onClick={() => toggleCityEnter(order.idBitrix)}>Удалить</Button>
                                                                    : order.dopMain?<Button onClick={() => toggleCityEnter(order.idBitrix, order.dopMain)}>Найти ({order.dopMain})</Button>
                                                                        : <Button onClick={() => toggleCityEnter(order.idBitrix)}>Заполнить</Button>
                                                            }
                                                                <br/>
                                                                {cityEnter[order.idBitrix]?
                                                                <ReactDadataBox
                                                                    style={{margin:"10px"}}
                                                                    token="80a7758e1c4d6412649c75b3f4e070057bef8234"
                                                                    query={citySearches[order.idBitrix]?.value || citySearch || order.dopMain || ""}
                                                                    placeholder="Начните вводить"
                                                                    onChange={(data) => handleResult(data, order.idBitrix)}
                                                                    silentQuery
                                                                />
                                                                : null
                                                                }
                                                            </div>
                                                            <div>
                                                                {order.comments.split("\n").map((comment, i) => {
                                                                    const urlRegex = /\[url=(.*?)\](.*?)\[\/url\]/g;
                                                                    const formattedComment = comment.replace(urlRegex, (match, url, text) => {
                                                                        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`;
                                                                    });
                                                                    return (
                                                                        <div key={i} dangerouslySetInnerHTML={{ __html: formattedComment }} />
                                                                    );
                                                                })}
                                                            </div>
                                                            <div>
                                                                {/*<Button style={{ marginRight: "10px" }} onClick={() => handleUploadClick(order.idBitrix)}>*/}
                                                                {/*    Загрузить фото*/}
                                                                {/*</Button>*/}
                                                                <Button onClick={() => {
                                                                    getCitySearch(citySearch).then()
                                                                    console.log("citySearch", citySearch)
                                                                }}>
                                                                    Поиск исполнителя
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))
                        ) : (
                            <p>Нет данных для отображения</p>
                        )}
                    </div>
                )}
                {isUploaderVisible && (
                    <ImageUploader name={idBitrix} onClose={() => setUploaderVisible(false)} />
                )}
            </div>
        </div>
    );
};

export default TheOrderShop;

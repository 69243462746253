import React, {useState} from 'react';

const TheFooter = () => {


    return (
        <>
            <footer className="footer">

            </footer>
        </>
    );
};

export {TheFooter};
import {$host} from "./index";

export const getCompanyOrdersByINN = async (inn) => {
    console.log('ordersByINN', inn)
    const {data} = await $host.post('api/order/getCompanyOrdersByINN' , {inn: inn})
    return data
}

export const getCompanyOrdersByDate = async (date) => {
    console.log('ordersByDate', date)
    const {data} = await $host.post('api/order/getCompanyOrdersByDate' , {date: date})
    return data
}

export const getCompanyOrdersByDatePeriod = async (date1, date2, sortOption, formData) => {
    console.log('ordersByDatePeriod', date1, date2)
    const {data} = await $host.post('api/order/getCompanyOrdersByDatePeriod' , {date: {date1, date2, sortOption, formData}})
    return data
}

export const getCompanyOrdersByDatePeriodForCity = async (date1, date2, sortOption) => {
    console.log('ordersByDatePeriodForCity', date1, date2)
    const {data} = await $host.post('api/order/getCompanyOrdersByDatePeriodForCity' , {date: {date1, date2, sortOption}})
    return data
}

export const getCompanyOrdersByDatePeriodForCityTest = async (date1, date2, sortOption) => {
    console.log('ordersByDatePeriodForCityTest', date1, date2)
    const {data} = await $host.post('api/order/getCompanyOrdersByDatePeriodForCityTest' , {date: {date1, date2, sortOption}})
    return data
}

export const postUpdateOrder = async (query) => {
    console.log('postUpdateOrder', query)
    const {data} = await $host.post(`api/order/postUpdateOrder`, {query: query})
    return data
}
export const normalizationNotifAll = async () => {
    const {data} = await $host.post('api/order/normalizationNotifAll')
    return data
}

export const sendRating = async (rating) => {
    const {data} = await $host.post('api/order/sendRating', rating)
    return data
}

export const getRating = async (link) => {
    const {data} = await $host.post('api/order/getRating' , {link})
    return data
}

export const getBitrix = async () => {
    const {data} = await $host.post('api/order/getBitrix')
    return data
}

export const callGetDayReport = async (sent) => {
    const {data} = await $host.post('api/order/callGetDayReport', sent)
    return data
}
export const callGetSum = async (sent) => {
    const {data} = await $host.post('api/order/callGetSum', sent)
    return data
}

export const getOrderNull = async () => {
    const {data} = await $host.post('api/order/getOrderNull')
    return data
}
export const getOrderFailed = async () => {
    const {data} = await $host.post('api/order/getOrderFailed')
    return data
}





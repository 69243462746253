import React from 'react';
import leftImg from "../img/left_arrow.svg";
import {Link} from "react-router-dom";

const TheStatistics = ({info}) => {

    switch (info.role.role) {
        case 'admin':
            return (
                <div style={{ display: "flex", flexDirection: "column", padding: "12px" }}>
                    {/*<Link className="stat_link" to={`/deals`}>*/}
                    {/*    Сделки*/}
                    {/*</Link>*/}
                    <Link className="stat_link" to={`/cityStatisticsTest`}>
                        Сверка
                    </Link>
                    <Link className="stat_link" to={`/cityStatistics`}>
                        Статистика по городам
                    </Link>
                    <Link className="stat_link" to={`/dealsStatistics`}>
                        Статистика по сделкам
                    </Link>
                    {/*<Link className="stat_link" to={`/dealsStatisticsBootstrap`}>*/}
                    {/*    Статистика по сделкам (Бутстрап)*/}
                    {/*</Link>*/}
                </div>
            );
        case 'buh':
            return (
                <div style={{ display: "flex", flexDirection: "column", padding: "12px" }}>
                    {/*<Link className="stat_link" to={`/deals`}>*/}
                    {/*    Сделки*/}
                    {/*</Link>*/}
                    {/*<Link className="stat_link" to={`/cityStatistics`}>*/}
                    {/*    Статистика по городам*/}
                    {/*</Link>*/}
                    <Link className="stat_link" to={`/cityStatisticsTest`}>
                        Сверка
                    </Link>
                    <Link className="stat_link" to={`/dealsStatistics`}>
                        Статистика по сделкам
                    </Link>
                </div>
            );
        case 'manager':
            return (
                <div style={{ display: "flex", flexDirection: "column", padding: "12px" }}>
                    {/*<Link className="stat_link" to={`/deals`}>*/}
                    {/*    Сделки*/}
                    {/*</Link>*/}
                    {/*<Link className="stat_link" to={`/cityStatistics`}>*/}
                    {/*    Статистика по городам*/}
                    {/*</Link>*/}
                    <Link className="stat_link" to={`/cityStatisticsTest`}>
                        Сверка
                    </Link>
                    {/*<Link className="stat_link" to={`/dealsStatistics`}>*/}
                    {/*    Статистика по сделкам*/}
                    {/*</Link>*/}
                </div>
            );
    }
};

export {TheStatistics}

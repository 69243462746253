import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import leftImg from "../img/left_arrow.svg";
import {countAdvBDNotification, dataFromNotifications, getNotificationOrders} from "../http/notificationAPI";
import {getUser} from "../http/userAPI";

import {getBitrix, getCompanyOrdersByDate, getCompanyOrdersByDatePeriod} from "../http/ordersAPI";
import {click} from "@testing-library/user-event/dist/click";
import preloadImg from "../img/tube-spinner.svg";
import {newUpdateBDCompanies} from "../http/catalogAPI";
import {clearBd, postBd} from "../http/cityAPI";
const { DateTime } = require('luxon');



const TheDeals = ({info, show}) => {
    const [preloader, setPreloader] = useState(false)
    const [sortOption, setSortOption] = useState("")
    // const [sortOptionName, setSortOptionName] = useState('')
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showTable,setShowTable] = useState(false)
    const [notification, setNotification] = useState([])
    const [dealsStatistics, setDealsStatistics] = useState({})

    const [advValues, setAdvValues] = useState([]);
    const [profitValues, setProfitValues] = useState([0]);
    const [incomeValues, setIncomeValues] = useState([]);

    const [users, setUsers] = useState([])
    const [periodManagers, setPeriodManagers] = useState([])

    const [profitAll, setProfitAll] = useState(0)
    const [advAll, setAdvAll] = useState(0)
    const [incomeAll, setIncomeAll] = useState(0)
    const [allTdSum, setAllTdSum] = useState(0)
    const [failedTdSum, setFailedTdSum] = useState(0)
    const [winTdSum, setWinTdSum] = useState(0)
    const [workTdSum, setWorkTdSum] = useState(0)
    const [dateBtx, setDateBtx] = useState('')
    const [linkBtx, setLinkBtx] = useState('')



    const handleInputChange = (e, index, dateSent) => {
        // console.log('handleInputChange', e.target.value)
        // setInputValue(e.target.value);

        const adv = [...advValues];
        adv[index] = e.target.value;
        setAdvValues(adv);

        const newProfitValues = incomeValues.map((income, i) => {
            if (i === index) {
                return income - Number(adv[index]);
            }
            return profitValues[i]; // Сохраняем другие значения без изменений
        });
        setProfitValues(newProfitValues);

        // Очищаем предыдущий таймер перед установкой нового
        // clearTimeout(handleInputChange.timeout);
        //
        // // Устанавливаем новый таймер после 1000мс
        // handleInputChange.timeout = setTimeout(() => {
        //     // Собираем данные, которые нужно передать в countAdvBDNotification
        //     countAdvBDNotification(dataObj)
        //         .then(data => {
        //             console.log('countAdv mess', data);
        //             // if (data === "Данные успешно обновлены в базе данных." || data === "Данные успешно добавлены в базе данных.") {
        //             //     getDataReverse().then(() => {}).catch(err => {
        //             //         console.error('Ошибка при получении обратных данных:', err);
        //             //     });
        //             // }
        //         })
        //         .catch(error => {
        //             console.error('Ошибка при редактировании расходов по рекламе:', error);
        //         });
        //
        //     // Вызываем countAdvBDNotification после задержки
        // }, 3000);

        const data = {
            currentAdv: e.target.value,
            status: 'countAdv',
            dateBitrix: dateSent,
            link: linkBtx
        };

        countAdvBDNotification(data)
            .then(data => {
                // console.log('countAdv mess', data);
                // if (data === "Данные успешно обновлены в базе данных." || data === "Данные успешно добавлены в базе данных.") {
                //     getDataReverse().then(() => {}).catch(err => {
                //         console.error('Ошибка при получении обратных данных:', err);
                //     });
                // }
                getDataReverse().then()
            })
            .catch(error => {
                console.error('Ошибка при редактировании расходов по рекламе:', error);
            });


        // if (e.target.value) {
        //     data.status = 'countAdv';
        //     data.dateBitrix = dateSent; // Подставьте значение dateBtx
        //     data.link = linkBtx; // Подставьте значение linkBtx
        // }

        // setDataObj(data)


        const timeoutId = setTimeout(() => {
            let allProfitTd = document.querySelectorAll('.profitTd');
            let allAdvTd = document.querySelectorAll('.advTd');

            let allProfitTdSum = 0;
            let allAdvTdSum = 0;

            allProfitTd.forEach(td => {
                if (!isNaN(parseFloat(td.textContent.trim()))) {
                    allProfitTdSum += parseFloat(td.textContent.trim());
                }
            });

            allAdvTd.forEach(td => {
                let value = parseFloat(td.querySelector('input').value.trim());
                // Проверяем, что значение не является NaN
                if (!isNaN(value)) {
                    allAdvTdSum += value;
                }
            });

            setProfitAll(allProfitTdSum);
            setAdvAll(allAdvTdSum);
        }, 50); // Задержка в миллисекундах (например, 50 мс)

        return () => clearTimeout(timeoutId);
    };


    const [noTableData, setNoTableData] = useState(false)
    const [valuesAdv, setValuesAdv] = useState([])

    useEffect(() => {
        // console.log('advValues', advValues)
        // console.log('valuesAdv', valuesAdv)
        // console.log('advValues dealsStatistics', dealsStatistics)
        // const deals = Object.values(dealsStatistics)
        // console.log('profitValues deals', profitValues)
        // console.log('incomeValues', incomeValues)

        const fetch = async () => {
            await getDataReverse()
        }

        fetch()

    }, [
        profitValues,
        incomeValues,
        valuesAdv]);




    const getDataReverse = async () => {


        const ordersByDate = await getCompanyOrdersByDatePeriod(startDate, endDate, sortOption)
        // console.log('ordersByDate1', Object.entries(ordersByDate))
        let ordersByDateArr =  Object.entries(ordersByDate)


        if (ordersByDateArr.length === 0) {
            setPreloader(false)
            setNoTableData(true)
        }


        if (ordersByDateArr.length > 0) {

        }
    }

    useEffect(() => {
        // console.log('advValues', advValues)
        const fetch = async () => {
            await getDataReverse()
        }

        // let newProfitValues

        const advsTd = document.querySelectorAll('.advTd');

        let arr = []

        advsTd.forEach((item, i) => {
            let adv = Number(item.querySelector('input').value.trim());

            incomeValues.map((income, j) => {
                if (i === j) {
                    let newValue = income - adv;
                    arr.push(newValue)
                    // console.log('income - adv', income, adv, newValue);
                    return arr
                }
            })

        });

        console.log('arr advsTd', arr);

        setProfitValues(arr)
        // for (let i = 0; i < ; i++) {
        //
        // }


        console.log('v', incomeValues)

        fetch()
    }, [advValues]);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };


    const handleChangeSort = async (orderBy) => {
        setSortOption(orderBy)
        // sortShops(orderBy)
        setProfitAll(0)
        setAdvAll(0)
        setIncomeAll(0)
    }


    const showManagers = async () => {
        await getDataToTable()
    }


    const showManagersTables = async (item) => {
        // const ordersByDate = await getCompanyOrdersByDatePeriod(startDate, endDate, sortOption)
        // console.log('item Tables', item[1].orders[0].dateBitrix)
        setDateBtx(item[1].orders[0].dateBitrix)
        setLinkBtx(item[1].orders[0].link)

        if (!showTable) {
            setShowTable(true)
        } else {
            setShowTable(false)
        }
    }

    const reverseDate = (date) => {
        if (date !== null) {
            return date.toString().split('-').reverse().join('.')
        }
    }


    useEffect(() => {
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let now = DateTime.now().setZone(timeZone);
        // console.log('DateTime.now()', now)
        let firstMonthDate = now.startOf('month').toFormat('yyyy-MM-dd');
        let currentDate = now.toISODate();

        if (firstMonthDate && currentDate) {
            setStartDate(firstMonthDate);
            setEndDate(currentDate);
        }
    }, []);

    const [managersActivity,setManagersActivity] = useState({})

    const getDataToTable = async () => {

        setPreloader(true)

        if (startDate && endDate && sortOption) {
            const ordersByDate = await getCompanyOrdersByDatePeriod(startDate, endDate, sortOption)
            // console.log('ordersByDate1', ordersByDate)

            let arrOrdersByDate = Object.values(ordersByDate);
            // setTextObj(arrManagersActivity)
            // console.log('arrOrdersByDate', arrOrdersByDate);

            if (arrOrdersByDate.length > 0) {
                setPreloader(false)
                setNoTableData(false)
            }



            // setNotification(ordersByDate)
            setDealsStatistics(ordersByDate)

            const incomeValues = Object.entries(ordersByDate).sort((a, b) => new Date(b[0]) - new Date(a[0])).map((item, index) => (
                // item[1].orders.reduce((total, item) => total + Number(item.sentSum), 0)
                item[1].orders.filter(item => item.status === null).reduce((total, item) => total + Math.round(Number(item.sum) - Math.round(Number(item.sentSum))), 0)
            ));

            setIncomeValues(incomeValues);

            setProfitValues([])
            setAdvValues([])

            let managersInPeriod = []
            let objectArr = Object.entries(ordersByDate)
            // console.log('objectArr', objectArr)

            for (let i = 0; i < objectArr.length; i++) {
                let managersArr = Object.entries(objectArr[i][1].managers)
                // console.log('managersArr', managersArr)
                for (let j = 0; j < managersArr.length; j++) {
                    // console.log('managersArr[j]', managersArr[j])
                    managersInPeriod.push(managersArr[j][0])
                }
            }

            managersInPeriod = [...new Set(managersInPeriod)];
            // console.log('managersInPeriod', managersInPeriod)
            setPeriodManagers(managersInPeriod)

            // Ваш код для вычисления суммы и установки общего дохода
            let allIncomeTd = document.querySelectorAll('.incomeTd');
            let allIncomeTdSum = 0;
            allIncomeTd.forEach(td => {
                allIncomeTdSum += parseFloat(td.textContent.trim());
            });
            setIncomeAll(allIncomeTdSum);

            let allTd = document.querySelectorAll('.all_item');
            let allSumTd = 0;
            allTd.forEach(td => {
                allSumTd += parseFloat(td.textContent.trim());
            });
            setAllTdSum(allSumTd);

            let winTd = document.querySelectorAll('.win_item');
            let winSumTd = 0;
            winTd.forEach(td => {
                winSumTd += parseFloat(td.textContent.trim());
            });
            setWinTdSum(winSumTd);

            let workTd = document.querySelectorAll('.work_item');
            let workSumTd = 0;
            workTd.forEach(td => {
                workSumTd += parseFloat(td.textContent.trim());
            });
            setWorkTdSum(workSumTd);

            let failedTd = document.querySelectorAll('.failed_item');
            let failedSumTd = 0;
            failedTd.forEach(td => {
                failedSumTd += parseFloat(td.textContent.trim());
            });
            setFailedTdSum(failedSumTd);

            for (let i = 0; i < managersInPeriod.length; i++) {
                let manager_all_items = document.querySelectorAll(`.manager_${i}_all__item`);
                let manager_win_items = document.querySelectorAll(`.manager_${i}_win__item`);
                let manager_failed_items = document.querySelectorAll(`.manager_${i}_failed__item`);
                let manager_work_items = document.querySelectorAll(`.manager_${i}_work__item`);
                let manager_incom_items = document.querySelectorAll(`.manager_${i}_incom__item`);

                let all_sum = 0;
                manager_all_items.forEach(el => {
                    all_sum += parseFloat(el.textContent.trim());
                    // el.style.minWidth = '28px';
                    console.log(`.manager_${i}_all__item`, el.textContent)
                });

                let win_sum = 0;
                manager_win_items.forEach(el => {
                    win_sum += parseFloat(el.textContent.trim());
                    // el.style.minWidth = '28px';
                });

                let failed_sum = 0;
                manager_failed_items.forEach(el => {
                    failed_sum += parseFloat(el.textContent.trim());
                    // el.style.minWidth = '28px';
                });

                let work_sum = 0;
                manager_work_items.forEach(el => {
                    work_sum += parseFloat(el.textContent.trim());
                    // el.style.minWidth = '28px';
                });


                let incom_sum = 0;
                manager_incom_items.forEach(el => {
                    incom_sum += parseFloat(el.textContent.trim());
                    // el.style.minWidth = '40px';
                });

                setManagersActivity(prev => {
                    return {
                        ...prev,
                        [`manager_${i}`]: {
                            all: all_sum,
                            win: win_sum,
                            failed: failed_sum,
                            work: work_sum,
                            incom: incom_sum
                        }
                    };
                });
            }
        }
    }

    useEffect(() => {
        // console.log('ordersByDate sortOption')
        const fetch = async () => {

            if (sortOption === '') {
                setSortOption("https://miamor.bitrix24.ru/")
            }

            let users = await getUser();
            setUsers(users)

            await getDataToTable()
        }
        fetch()
    }, [sortOption]);

    const [textObj, setTextObj] = useState([])

    // useEffect(() => {
    //     setPreloader(true)
    //     if (textObj.length > 0) {
    //         setPreloader(false)
    //     }
    // }, [textObj])


    useEffect(() => {
        // let arrManagersActivity = Object.values(managersActivity);
        // setTextObj(arrManagersActivity)
        // console.log('arrManagersActivity', arrManagersActivity);

        const managersArray = Object.entries(managersActivity).map(([key, value]) => ({ key, ...value }));

        managersArray.sort((a, b) => b.all - a.all);

        const sortedManagersActivity = {};
        managersArray.forEach(item => {
            sortedManagersActivity[item.key] = item;
        });

        const arrNew = []

        for (let i = 0; i < managersArray.length; i++) {
            const key = managersArray[i].key;
            const number = Number(key.match(/\d+/)[0]); // Extracts the first number from the string
            // console.log('numberKey', number);
            arrNew[i] = periodManagers[number]
        }

        setTextObj(sortedManagersActivity)
        // console.log('arrNew', arrNew);
        setPeriodManagers(arrNew)
        // console.log('sortedManagersActivity', managersArray);
        //
        // console.log('managersActivity', managersActivity)
        const timeoutId = setTimeout(() => {
            for (let i = 0; i < arrNew.length; i++) {
                let manager_all = document.querySelector(`.manager_${i}_all`)
                if (manager_all) {
                    manager_all.style.minWidth = '28px';
                    // manager_all.textContent = sortedManagersActivity[`manager_${i}`].all
                    manager_all.textContent = managersArray[i]?.all
                    console.log(`.manager_${i}_all`, managersArray[i]?.all)
                }

                let manager_win = document.querySelector(`.manager_${i}_win`)
                if (manager_win) {
                    manager_win.style.minWidth = '28px'
                    // manager_win.textContent = sortedManagersActivity[`manager_${i}`].win
                    manager_win.textContent = managersArray[i]?.win
                }

                let manager_failed = document.querySelector(`.manager_${i}_failed`)
                if (manager_failed) {
                    manager_failed.style.minWidth = '28px'
                    // manager_failed.textContent = sortedManagersActivity[`manager_${i}`].failed
                    manager_failed.textContent = managersArray[i]?.failed
                }

                let manager_work = document.querySelector(`.manager_${i}_work`)
                if (manager_work) {
                    manager_work.style.minWidth = '28px'
                    // manager_work.textContent = sortedManagersActivity[`manager_${i}`].work
                    manager_work.textContent = managersArray[i]?.work
                }

                let manager_conversion = document.querySelector(`.manager_${i}_conversion`)
                if (manager_conversion) {
                    manager_conversion.style.minWidth = '40px'
                    // manager_conversion.textContent = (sortedManagersActivity[`manager_${i}`].win * 100 / sortedManagersActivity[`manager_${i}`].all).toFixed(2) + '%'
                    manager_conversion.textContent = (managersArray[i]?.win * 100 / managersArray[i]?.all).toFixed(2) + '%'
                }

                let manager_conversion_th = document.querySelector(`.manager_${i}_conversion_th`)
                if (manager_conversion_th) {
                    manager_conversion_th.style.minWidth = '40px'
                    // manager_conversion_th.textContent = (sortedManagersActivity[`manager_${i}`].win * 100 / sortedManagersActivity[`manager_${i}`].all).toFixed(2) + '%'
                    manager_conversion_th.textContent = (managersArray[i]?.win * 100 / managersArray[i]?.all).toFixed(2) + '%'
                }

                let manager_incom = document.querySelector(`.manager_${i}_incom`)
                if (manager_incom) {
                    manager_incom.style.minWidth = '40px'
                    manager_incom.textContent = managersArray[i]?.incom
                }

                let manager_incom_middle = document.querySelector(`.manager_${i}_incom_middle`)
                if (manager_incom_middle) {
                    manager_incom_middle.style.minWidth = '60px'

                    let value = managersArray[i]?.incom / managersArray[i]?.win;

                    // Check if the value is NaN, replace with 0 if true
                    if (!Number.isFinite(value)) {
                        value = 0;
                    }

                    // manager_incom_middle.textContent = 'р.' + Math.round((sortedManagersActivity[`manager_${i}`].incom / sortedManagersActivity[`manager_${i}`].win).toFixed(2))
                    manager_incom_middle.textContent = 'р.' + Math.round((value).toFixed(2))
                }

            }
        }, 100); // Задержка в миллисекундах (например, 1000 мс = 1 секунда)

        return () => {
            clearTimeout(timeoutId);
        };
    }, [managersActivity]);


    const getOrdersByDatePeriod = async (sortPeriod) => {
        setPreloader(true)
        let startDate, endDate, formattedStartDate, nowDate;
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Получение даты начала и конца периода в зависимости от выбранного варианта
        switch (sortPeriod) {
            case 'неделя':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 7 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            case '10 дней':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 10 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            case 'месяц':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 30 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            case 'вчера':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 1 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            default:
                let now = DateTime.now().setZone(timeZone);
                let firstMonthDate = now.startOf('month').toFormat('yyyy-MM-dd');
                let currentDate = now.toISODate();
                setStartDate(firstMonthDate);
                setEndDate(currentDate);
        }

        if (startDate && endDate && sortOption) {
            const ordersByDate = await getCompanyOrdersByDatePeriod(startDate, endDate, sortOption)
            // console.log('ordersByDate2', ordersByDate)

            // setNotification(ordersByDate)
            setDealsStatistics(ordersByDate)

            const incomeValues = Object.entries(ordersByDate).sort((a, b) => new Date(b[0]) - new Date(a[0])).map((item, index) => (
                // item[1].orders.reduce((total, item) => total + Number(item.sentSum), 0)
                item[1].orders.filter(item => item.status === null).reduce((total, item) => total + Math.round(Number(item.sum) - Math.round(Number(item.sentSum))), 0)
            ));

            setIncomeValues(incomeValues);

            setProfitValues([])
            setAdvValues([])

            let managersInPeriod = []
            let objectArr = Object.entries(ordersByDate)

            for (let i = 0; i < objectArr.length; i++) {
                let managersArr = Object.entries(objectArr[i][1].managers)
                // console.log('managersArr', managersArr)
                for (let j = 0; j < managersArr.length; j++) {
                    // console.log('managersArr[j]', managersArr[j])
                    managersInPeriod.push(managersArr[j][0])
                }
            }

            managersInPeriod = [...new Set(managersInPeriod)];
            setPeriodManagers(managersInPeriod)


            const timeoutId = setTimeout(() => {
                // Ваш код для вычисления суммы и установки общего дохода
                let allIncomeTd = document.querySelectorAll('.incomeTd');
                let allIncomeTdSum = 0;

                allIncomeTd.forEach(td => {
                    allIncomeTdSum += parseFloat(td.textContent.trim());
                });
                setIncomeAll(allIncomeTdSum);

                let allTd = document.querySelectorAll('.all_item');
                let allSumTd = 0;
                allTd.forEach(td => {
                    allSumTd += parseFloat(td.textContent.trim());
                });
                setAllTdSum(allSumTd);

                let winTd = document.querySelectorAll('.win_item');
                let winSumTd = 0;
                winTd.forEach(td => {
                    winSumTd += parseFloat(td.textContent.trim());
                });
                setWinTdSum(winSumTd);

                let workTd = document.querySelectorAll('.work_item');
                let workSumTd = 0;
                workTd.forEach(td => {
                    workSumTd += parseFloat(td.textContent.trim());
                });
                setWorkTdSum(workSumTd);

                let failedTd = document.querySelectorAll('.failed_item');
                let failedSumTd = 0;
                failedTd.forEach(td => {
                    failedSumTd += parseFloat(td.textContent.trim());
                });
                setFailedTdSum(failedSumTd);


                for (let i = 0; i < managersInPeriod.length; i++) {
                    let manager_all_items = document.querySelectorAll(`.manager_${i}_all__item`);
                    let manager_win_items = document.querySelectorAll(`.manager_${i}_win__item`);
                    let manager_failed_items = document.querySelectorAll(`.manager_${i}_failed__item`);
                    let manager_work_items = document.querySelectorAll(`.manager_${i}_work__item`);
                    let manager_incom_items = document.querySelectorAll(`.manager_${i}_incom__item`);

                    let all_sum = 0;
                    manager_all_items.forEach(el => {
                        all_sum += parseFloat(el.textContent.trim());
                        // el.style.minWidth = '28px';
                    });

                    let win_sum = 0;
                    manager_win_items.forEach(el => {
                        win_sum += parseFloat(el.textContent.trim());
                        // el.style.minWidth = '28px';
                    });

                    let failed_sum = 0;
                    manager_failed_items.forEach(el => {
                        failed_sum += parseFloat(el.textContent.trim());
                        // el.style.minWidth = '28px';
                    });

                    let work_sum = 0;
                    manager_work_items.forEach(el => {
                        work_sum += parseFloat(el.textContent.trim());
                        // el.style.minWidth = '28px';
                    });


                    let incom_sum = 0;
                    manager_incom_items.forEach(el => {
                        incom_sum += parseFloat(el.textContent.trim());
                        // el.style.minWidth = '40px';
                    });

                    setManagersActivity(prev => {
                        return {
                            ...prev,
                            [`manager_${i}`]: {
                                all: all_sum,
                                win: win_sum,
                                failed: failed_sum,
                                work: work_sum,
                                incom: incom_sum
                            }
                        };
                    });
                }

                // console.log('managersActivity', managersActivity)

            }, 200); // Задержка в миллисекундах (например, 1000 мс = 1 секунда)

            setPreloader(false)
            setNoTableData(false)

            return () => {
                clearTimeout(timeoutId);
            };
        }
    };

    const sentGetBitrix = async () => {
        const password = prompt('Введите пароль для запуска синхронизации:');
        if (password === '0111') {
            // Password is correct, start synchronization
            await getBitrix();
            alert('Синхронизация запущена!');
        } else {
            // Password is incorrect or canceled
            alert('Неверный пароль или отмена.');
        }
    };

    const orgBd = async () => {
        await postBd()
    }

    const  clickClearBd = async () => {
        await clearBd()
    }


    return (
        <>
            <div
                className="left_links"
                onClick={() => {
                    localStorage.removeItem('scroll_order');
                }}
            >
                <Link to={`/statistics`} style={show ? {display: "flex"} : {display: "none"}}>
                    <img src={leftImg} className="logo_img" width="25px" height="25px"/>
                </Link>
                <Link to={`/statistics`} style={!show ? {display: "flex"} : {display: "none"}}>
                    <img src={leftImg} className="logo_img" width="25px" height="25px"/>
                </Link>
                {/*<div>{groupedManagers}</div>*/}
            </div>
            <div className="deals">
                <div
                    className="preloader"
                    style={preloader ? {display: "block"} : {display: "none"}}
                >
                    <img src={preloadImg} className="logo_img" width="125px"
                         height="125px"/>
                </div>
                <div>
                    <div className="select__wrapper" style={{marginBottom: "20px"}}>
                        Выбрать магазин
                        <select name="orderby" className="orderby" aria-label="Магазин" onChange={(e) => {
                            handleChangeSort(e.target.value)
                        }} value={sortOption}>
                            <option value="">Выберите магазин</option>
                            <option value="https://tsvetochnyi.bitrix24.ru/">Цветочный</option>
                            <option value="https://miamor.bitrix24.ru/">Ми-амор</option>
                            <option value="https://sladkoeshka.bitrix24.ru/">Сладкоешка</option>
                            <option value="https://kami-flow.bitrix24.ru/">Камелия</option>
                        </select>
                    </div>
                </div>
                <div style={{marginBottom: "20px"}}>
                    <label>Дата начала:</label>
                    <input type="date" value={startDate} max={new Date().toISOString().split('T')[0]}
                           onChange={(e) => handleStartDateChange(e.target.value)}/>

                    <label style={{ marginLeft: "10px" }}>Дата окончания:</label>
                    <input type="date" value={endDate} max={new Date().toISOString().split('T')[0]}
                           onChange={(e) => handleEndDateChange(e.target.value)}/>

                    <button style={{ marginLeft: "10px" }} onClick={showManagers}>Применить</button>
                </div>
                <div style={{marginBottom: "20px"}}>
                    <select id="sortPeriod" onChange={(e) => {
                        getOrdersByDatePeriod(e.target.value).then()
                    }}>
                        <option value="текущий">Выберите период</option>
                        <option value="неделя">За неделю</option>
                        <option value="10 дней">За 10 дней</option>
                        <option value="месяц">За месяц</option>
                        <option value="вчера">За вчера</option>
                    </select>
                </div>
                <div style={{marginBottom: "20px"}}>
                    <button onClick={()=>{
                        sentGetBitrix()
                    }}>Синхронизация</button>
                    {/*<button onClick={()=>{*/}
                    {/*    orgBd()*/}
                    {/*}}>заполнить БД</button>*/}
                    {/*<button onClick={()=>{*/}
                    {/*    clickClearBd()*/}
                    {/*}}>очистка старой БД</button>*/}

                    <button>Отчет за день</button>
                    <button style={{marginLeft: "10px"}} >Отчет за неделю</button>
                    <button style={{marginLeft: "10px"}}>Отчет за месяц</button>
                </div>
                <div>
                    <div className="wide-table-wrapper" style={{display: 'flex'}}>
                        <table className="wide-table">
                            <thead>
                            <tr>
                                <th rowSpan="2">{`${reverseDate(startDate)}`} <br/> {`${reverseDate(endDate)}`}</th>
                                <th colSpan="4">Общие показатели</th>
                                <th rowSpan="2">Конверсия</th>
                                <th>{incomeAll}</th>
                                <th>{advAll}</th>
                                <th style={{backgroundColor: profitAll < 0 ? 'rgba(250,0,0,0.42)' : 'transparent'}}>{profitAll}</th>
                                {
                                    periodManagers.map((manager, i) => {
                                        return (
                                        <th key={i}>
                                            <table className="wide-table-min">
                                                <tbody>
                                                <tr>
                                                    <th className="td_div td_div_name">
                                                        {users &&
                                                            users.map(user => {
                                                                if (user.email === manager) {
                                                                    return user.nameTitle; // Display user's name if email matches manager
                                                                }
                                                                return null
                                                            })}
                                                    </th>
                                                    <th className="td_div">Conv</th>
                                                    <th style={{backgroundColor: 'rgba(250,169,114,0.42)'}}
                                                        className={`manager_${i}_incom td_div`}>100
                                                    </th>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </th>
                                    )
                                    })
                                }
                            </tr>
                            <tr>
                                <th>Всего</th>
                                <th>Успех</th>
                                <th>Провал</th>
                                <th>В работе</th>
                                <th>Доход</th>
                                <th>Реклама</th>
                                <th>Прибыль</th>
                                {
                                    periodManagers.map((manager, i) => {
                                        return (
                                            <th key={i}>
                                                <table className="wide-table-min">
                                                    <tbody>
                                                    <tr>
                                                        <th className={`manager_${i}_all`}>0</th>
                                                        <th className={`manager_${i}_win`} style={{backgroundColor: 'rgba(152,234,119,0.42)'}}>0</th>
                                                        <th className={`manager_${i}_failed`} style={{ backgroundColor: 'rgba(169,175,248,0.42)'}}>0</th>
                                                        <th className={`manager_${i}_work`} style={{backgroundColor: 'rgba(248,101,101,0.42)'}}>0</th>
                                                        <th className={`manager_${i}_conversion`} style={{ backgroundColor: 'rgba(250,243,155,0.42)'}}>0</th>
                                                        <th className={`manager_${i}_incom_middle`} style={{ backgroundColor: 'rgba(250,169,114,0.42)'}}>0</th>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Object.entries(dealsStatistics)
                                    .sort((a, b) => new Date(b[0]) - new Date(a[0]))
                                    .map(((item, index) => (
                                            <tr key={index} onClick={() => {
                                                showManagersTables(item).then()
                                            }}>
                                                <td key={`td1_${index}`} >{reverseDate(item[0])}</td>
                                                <td key={`td2_${index}`} className="all_item">{item[1].orders.length}</td>
                                                <td key={`td3_${index}`}  style={{backgroundColor: 'rgba(152,234,119,0.42)'}} className="win_item">
                                                    {
                                                        item[1].orders.filter(item => item.status === null).length
                                                    }
                                                </td>
                                                <td key={`td4_${index}`}  style={{backgroundColor: 'rgba(248,101,101,0.42)'}} className="failed_item">
                                                    {
                                                        item[1].orders.filter(item => item.status === 'failed').length
                                                    }
                                                </td>
                                                <td key={`td5_${index}`}  style={{backgroundColor: 'rgba(169,175,248,0.42)'}} className="work_item">
                                                    {
                                                        item[1].orders.filter(item => item.status === 'work').length
                                                    }
                                                </td>
                                                <td key={`td6_${index}`}  style={{backgroundColor: 'rgba(250,243,155,0.42)'}}>
                                                    {`${(item[1].orders.filter(item => item.status === null).length * 100 / item[1].orders.length).toFixed(2)}%`}
                                                </td>
                                                <td key={`td7_${index}`}  className="incomeTd">
                                                    {

                                                        // item[1].orders.reduce((total, item) => total + Number(item.sentSum), 0)
                                                        item[1].orders.filter(item => item.status === null).reduce((total, item) => total + Math.round(Number(item.sum) - Math.round(Number(item.sentSum))), 0)
                                                    }
                                                </td>
                                                <td key={`td8_${index}`}  className="advTd" id={`${item[0]}`}>
                                                    <input
                                                        style={{maxWidth: "48px"}}
                                                        value={advValues[index] || (Object.values(item[1].advertisingValue).length === 0 ? "" : item[1].advertisingValue)}
                                                        // value={advValues[index] || ''}
                                                        onChange={(e) => handleInputChange(e, index, reverseDate(item[0]))}
                                                        // onClick={clickInput}
                                                    />
                                                    {/*<div className={`${item[0]}`}></div>*/}
                                                    {/*<button onClick={sentToNotif}>+</button>*/}
                                                </td>
                                                <td key={`td9_${index}`}  className="profitTd"
                                                    style={{backgroundColor: profitValues[index] < 0 ? 'rgba(250,0,0,0.42)' : 'transparent'}}>
                                                    {profitValues[index]}
                                                </td>
                                                {
                                                    periodManagers.map((manager, j) => (
                                                        <td key={`manager_${j}`}>
                                                            {
                                                                Object.entries(item[1].managers).map((item, idxNum) => {
                                                                    if (manager === item[0]) {
                                                                        let colors = [
                                                                            { color: 'rgba(152,234,119,0.42)', classItem: `manager_${j}_win__item manager_item_td` },
                                                                            { color: 'rgba(169,175,248,0.42)', classItem: `manager_${j}_failed__item manager_item_td` },
                                                                            { color: 'rgba(248,101,101,0.42)', classItem: `manager_${j}_work__item manager_item_td` },
                                                                            { color: 'rgba(250,243,155,0.42)', classItem: `manager_${j}_conversion__item td_width manager_item_td_conv` },
                                                                            { color: 'rgba(250,169,114,0.42)', classItem: `manager_${j}_incom__item td_width manager_item_td_incom` }
                                                                        ];
                                                                        return (
                                                                            <div key={`manager_${j}_${idxNum}`}>
                                                                                <table className="wide-table-min">
                                                                                    <tbody>
                                                                                    <tr>
                                                                                        <td className={`manager_${j}_all__item manager_item_td`} key={`manager_${j}_all`}>
                                                                                            {item[1].orderCount}
                                                                                        </td>
                                                                                        {
                                                                                            Object.entries(item[1].status).map((el, idx) => (
                                                                                                <td className={colors[idx].classItem} style={{ backgroundColor: colors[idx].color }} key={`manager_${j}_status_${idx}`}>
                                                                                                    {el[1]}
                                                                                                </td>
                                                                                            ))
                                                                                        }
                                                                                    </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        );
                                                                    }
                                                                })
                                                            }
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        )
                                    ))
                            }
                            <tr>
                                <th>Итого</th>
                                <th>{allTdSum}</th>
                                <th>{winTdSum}</th>
                                <th>{failedTdSum}</th>
                                <th>{workTdSum}</th>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ display: noTableData ? 'block' : 'none', marginTop: '20px', fontWeight: "bold" }}>Нет данных</div>
            </div>
        </>
    );
};

export {TheDeals};

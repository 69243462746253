import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import leftImg from "../img/left_arrow.svg";
import plusImg from "../img/plus.svg";
import {getCompanyOrdersByINN} from "../http/ordersAPI";
import {normalizeDate} from "./functions/normalizeDate";


const TheOrders = ({info, show}) => {
    console.log('TheOrders', info, show)

    const [card, setCard] = useState([]);

    const [all, setAll] = useState(true);
    const [process, setProcess] = useState(false);
    const [finish, setFinish] = useState(false);
    const [url, setUrl] = useState('');
    const [pars, setPars] = useState([])
    const [order, setOrder] = useState([])
    const [role, setRole] = useState("")


    useEffect(() => {
        setRole(info.role.role)
    }, []);

    useEffect(() => {
        let url = window.location.href;
        let parts = url.split('/');
        let lastWord = parts[parts.length - 1];
        let INN = parseInt(lastWord);
        let result = info.contractor[INN];
        setCard(result);
        setUrl(lastWord)
        setPars(parts)
        setOrder(info.contractor.filter(order => order.INN === lastWord))
        console.log('result', result);
        console.log('parts', pars);
        console.log('lastWord', lastWord);
        console.log('url', url);
        console.log("INN", INN)
    }, [info]);


    useEffect(() => {
        // const result = info.contractor.filter(order => order.INN === lastWord)
        const fetch = async () => {
            let url = window.location.href;
            let parts = url.split('/');
            let lastWord = parts[parts.length - 1];
            console.log("lastWord ORDERS", lastWord);

            const result = await getCompanyOrdersByINN(lastWord)
            setOrder(result)
            console.log("setOrder5", result);
        }

        fetch()
    }, []);


    const allClick = () => {
        setAll(true);
        setProcess(false);
        setFinish(false);
    }

    const processClick = () => {
        setAll(false);
        setProcess(true);
        setFinish(false);
    }

    const finishClick = () => {
        setAll(false);
        setProcess(false);
        setFinish(true);
    }

    const location = useLocation();

    const [linkItem, setLinkItem] = useState('')

    useEffect(() => {
        let parts = location.pathname.split('/');
        let lastWord = parts[parts.length - 1];
        setLinkItem(lastWord)
        // if (lastWord === '') {
        //     lastWord = parts[parts.length - 1];
        // }
        // console.log('lastWordOrders', lastWord)

    }, [location]);

    useEffect(() => {
        const timer = setTimeout(() => {
            const targetElementId = localStorage.getItem('scroll_order');
            if (targetElementId) {
                console.log("!SCROLL сработал", targetElementId);
                const targetElement = document.getElementById(targetElementId);
                console.log('!targetElement BEFORE', targetElement)
                if (targetElement) {
                    console.log("!!!!SCROLL сработал", targetElement);
                    let ordersItem = targetElement.querySelector('.orders__item')
                    ordersItem.classList.add('blink-border')
                    targetElement.scrollIntoView({behavior: 'smooth'});
                    localStorage.removeItem('scroll_order'); // Очищаем значение после прокрутки
                }
            }
        }, 700)
        return () => clearTimeout(timer)
    }, []);

    const scrollToElement = (elementId) => {
        localStorage.setItem('scroll_order', elementId);
        const targetElement = document.getElementById(elementId);
        // setScrollBlock(true)
        // targetElement.classList.add('scrollClick');
        if (targetElement) {
            targetElement.scrollIntoView({behavior: 'smooth'});
        }
    };


    return (
        <>
            <div className="orders">
                <div
                    style={{
                        display: role === "admin" || role === "manager" ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    className="contractor_add"
                >
                    <Link to={`/createOrder/${url}`}>
                        <img src={plusImg} className="logo_img" width="25px" height="25px"/>
                    </Link>
                </div>
                <div
                    className="left_links"
                    onClick={() => {
                        localStorage.removeItem('scroll_order');
                    }}
                >
                    <Link to={`/`} style={show ? {display: "flex"} : {display: "none"}}>
                        <img src={leftImg} className="logo_img" width="25px" height="25px"/>
                    </Link>
                    <Link to={`/`} style={!show ? {display: "flex"} : {display: "none"}}>
                        <img src={leftImg} className="logo_img" width="25px" height="25px"/>
                    </Link>
                </div>
                <div className="clickers">
                    <div className="clicker item_card__button" onClick={() => allClick()}
                         style={all ? {borderBottom: "4px solid #ffffff"} : {backgroundColor: "#00b3e6"}}>ВСЕ
                    </div>
                    <div className="clicker item_card__button" onClick={() => processClick()}
                         style={process ? {borderBottom: "4px solid #ffffff"} : {backgroundColor: "#00b3e6"}}>В РАБОТЕ
                    </div>
                    <div className="clicker item_card__button" onClick={() => finishClick()}
                         style={finish ? {borderBottom: "4px solid #ffffff"} : {backgroundColor: "#00b3e6"}}>ВЫПОЛНЕННЫЕ
                    </div>
                </div>
            </div>
            {order.map((order, index) => {
                console.log("order", order);
                return (
                    <div key={order.id}>
                        {order && order.orders.map((companyOrder, index) => {
                            return (
                                <div
                                    key={companyOrder.id}
                                    id={`element-${index}`}
                                    onClick={() => {
                                        scrollToElement(`element-${index}`);
                                    }}
                                >
                                    <div className="orders__wrapper all"
                                         style={all ? {display: "block"} : {display: "none"}}>
                                        <div className="orders__date">{companyOrder.createData}</div>
                                        <Link to={url ? `/order/${url}/${companyOrder.id}` : `/order/${info.role.inn}`}>
                                            <div className="orders__item" style={{display: "flex", flexDirection: "column"}}>
                                                <div style={{display: "flex", marginBottom: "6px"}}>
                                                    <div className="to_adress">Заказ по адресу:</div>
                                                    <div className="item adress up">{order.INN}</div>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                                    <div className="item_left">
                                                        <div className="item group" style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                                            <div className="item number" style={{ marginBottom: "4px"}}>
                                                                <div style={{ fontSize: "14px", marginBottom: "4px"}}>idBitrix:</div>
                                                                <div style={{color: "#000000"}}>{companyOrder.idBitrix}</div></div>

                                                            <div className="item time" style={{ marginBottom: "4px"}}>
                                                                <div style={{ fontSize: "14px", marginBottom: "4px"}}>Создан:</div>
                                                                <div style={{color: "#000000"}}>{normalizeDate(companyOrder.createdAt)}</div></div>

                                                                <div className="item responsible_person" style={{ marginBottom: "4px"}}>
                                                                <div style={{ fontSize: "14px", marginBottom: "4px"}}>Ответсвенный:</div>
                                                                    {/*<div style={{color: "#000000"}}>Ильсияр</div>*/}
                                                                    </div>
                                                        </div>
                                                    </div>
                                                    <div className="item_rigth">
                                                        <div className="item price up"><span>{companyOrder.sum}</span>руб
                                                        </div>
                                                        <div className="item status inProcess">В работе</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </>
    );
};

export {TheOrders};
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import leftImg from "../img/left_arrow.svg";
import plusImg from "../img/plus.svg";
import {registration, updateUser, bunUser, getUser, deleteUserBD} from "../http/userAPI";

const TheEmployees = ({info}) => {
    const [expandedIndex, setExpandedIndex] = useState(-1);

    const [bio, setBio] = useState(false)

    const [regOk, setRegOk] = useState(false)

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [adminPassword, setAdminPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [showMore, setShowMore] = useState(true);
    const [showCount, setShowCount] = useState(5);
    const [names, setNames] = useState(info.userCreate)

    const [selectRole, setSelectRole] = useState('manager')
    const [checked, setChecked] = useState([]);
    const [selectedRow, setSelectedRow] = useState(-1);

    const [showModal, setShowModal] = useState(false)
    const [deleteEmployee, setDeleteEmployee] = useState(false)
    const [blockEmployee, setBlockEmployee] = useState(false)

    const [currentCheck, setCurrentCheck] = useState(true)
    const [currentCheckIndex, setCurrentCheckIndex] = useState(0)
    const [currentDeleteItem, setCurrentDeleteItem] = useState(null)

    const [namesBan, setNamesBan] = useState('')

    const [dis, setDis] = useState(true);
    const [saveBtn, showSaveBtn] = useState(false);
    // const [empPhoto, setEmpPhoto] = useState(null)

    console.log('БАН запущен')



    useEffect(() => {
        const fetchData = async () => {
            try {
                const allUsers = await getUser();
                console.log('allUsers', allUsers);

                const filteredUsers = allUsers.filter(user => user.name === 'true');
                console.log('filteredUsers', filteredUsers);

                const newChecked = names.map((item) => {
                    return filteredUsers.some(filteredUser => filteredUser.email === item.email);
                });

                setChecked(newChecked);
                console.log('newChecked', newChecked);
            } catch (error) {
                console.error('Ошибка при получении данных:', error);
            }
        };

        fetchData();
    }, [info.userCreate, names]);


    useEffect(() => {
        console.log('info.userCreate', info.userCreate)

        const arr = [...info.userCreate]
        // const res = arr.sort((a, b) => (a.email && b.email) ? a.email.localeCompare(b.email) : 0)
        console.log('arrEmpl', arr)

        setNames(arr
            // .slice(0, showCount)
            );

        if (showCount < info.userCreate.length) {
            setShowMore(true)
        } else {
            setShowMore(false)
        }

        setName(arr[0]?.nameTitle || '');
        setEmail(arr[0]?.email || '');
        setPhone(arr[0]?.dop || '');

    }, [info, showCount]);

    const openBio = (index) => {
        if (expandedIndex === index) {
            setExpandedIndex(-1);
        } else {
            setExpandedIndex(index);
        }
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f0f0f0',
            fontFamily: 'Arial, sans-serif',
        },
        formItem: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '20px',
            borderRadius: '5px',
            backgroundColor: 'white',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.15)',
            width: '78vw'
        },
        input: {
            width: '100%', // make the input take the full width of its container
            padding: '10px 0 15px 4px',
            marginLeft: '0',
            marginRight: '0',
            border: 'none',
            borderRadius: '5px',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.15)',
        },
        button: {
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            backgroundColor: '#00b2e2',
            color: 'white',
            cursor: 'pointer',
            transition: 'background-color 0.2s ease',
        },
        buttonHover: {
            backgroundColor: '#0056b3',
        },
        header: {
            backgroundColor: '#00b2e2',
            fontSize: "14px",
        },
        logoImg: {
            marginRight: "10px",
        }
    };

    const handleSelect = (e) => {
        e.preventDefault();
        setSelectRole(e.target.value)
    }

    const handleCheckboxChange = (index, value) => {

        const newChecked = [...checked];
        console.log('newChecked', newChecked)
        console.log('blockEmployee', blockEmployee)
        console.log('index', index)

        newChecked[index] = value;
        console.log('newChecked[index]', newChecked[index])
        setChecked(newChecked);

    };

    const selectBun = (check) => {
        const ban = {
            name: namesBan,
            check: check,
        }
        console.log('ban', ban)

        bunUser(ban)
    }

    const createUsers = (selectRole, email, password, adminPassword, name, phone, inn, update) => {

        const users = {
            role: selectRole,
            email: email,
            password: password,
            adminPassword: adminPassword,
            nameTitle: name,
            dop: phone,
            INN: inn || '',
            update: update || false
        };
        registration(users)
            .then(res => {
                if (res.status === 'ok') {
                    alert("Пользователь успешно создан");
                } else if (res.status === 'yes') {
                    // setModalCreate(true)
                    setRegOk(true)
                } else if (res.status === 'update') {
                    alert("Пароль пользователь успешно обновлен");
                }
            })
            .catch(error => {
                alert("Ошибка при регистрации пользователя")
            });
    }

    const deleteUser = (value) => {
        if (value === null) {
            return
        }

        const userDelete = {
            email: value,
        }

        setNames(info.userCreate.filter(name => name.email !== value))

        deleteUserBD(userDelete)

        setExpandedIndex(-1)
    }

    const handleSentName = (event) => {
        setName(event.target.value);
    };

    const handleSentPhone = (event) => {
        setPhone(event.target.value);
    };

    const handleSentEmail = (event) => {
        setEmail(event.target.value);
    };

    const updateEmployee = () => {
        // const email = empData.filter(item => item.email === user.email)
        // const cloneArr = [...empData]
        // console.log('cloneArr', cloneArr)

        const updateData = {
            name: name || '',
            dop: phone || '',
            email: email || '',
            // dop1: empPhoto || '',
        }

        updateUser(updateData)

        const res = info.userCreate.filter(item => item.email === email)
        res[0].nameTitle = name;
        res[0].dop = phone;


        const res1 = info.userCreate.filter(item => item.email !== email)
        setNames([...res1, ...res])
        // console.log('updateData', updateData)
        //
        // cloneArr[0] = {...cloneArr[0], updateData}
        //
        // console.log('updateEmployee', cloneArr)
        //
        // update(cloneArr)

        setDis(true);


    }

    const openEmployee = (value) => {
        let res = info.userCreate;
        let emp = res.filter(item => item.email === value)
        console.log('emp[0]', emp[0])
        setName(emp[0].nameTitle || '')
        setPhone(emp[0].dop || '')
        setEmail(emp[0].email)
    }


    switch (info.role.role) {
        case 'admin':
            return (
                <>
                    <div
                        className="bg"
                        style={showModal ? {display: "block"} : {display: "none"}}
                    ></div>
                    <div
                        className="modal"
                        style={showModal ? {display: "block"} : {display: "none"}}
                    >
                        <div
                            style={deleteEmployee ? {display: "block"} : {display: "none"}}
                        >
                            Вы точно хотите удалить сотрудника?
                            <div className="modal__btns">
                                <div
                                    className="modal_btn yes"
                                    onClick={() => {
                                        setShowModal(false)
                                        setDeleteEmployee(false)
                                        deleteUser(currentDeleteItem)
                                    }}
                                >Да
                                </div>
                                <div
                                    className="modal_btn no"
                                    onClick={() => {
                                        setShowModal(false)
                                        setDeleteEmployee(false)
                                    }}
                                >Нет
                                </div>
                            </div>
                        </div>
                        <div
                            style={blockEmployee ? {display: "block"} : {display: "none"}}
                        >
                            <div>Вы точно хотите
                                {currentCheck ? ' заблокировать ' : ' разблокировать '}
                                сотрудника?
                            </div>
                            <div className="modal__btns">
                                <div
                                    className="modal_btn yes"
                                    onClick={() => {
                                        setShowModal(false)
                                        setBlockEmployee(false)
                                        handleCheckboxChange(currentCheckIndex, true)
                                        if (currentCheck) {
                                            selectBun(true)
                                        } else {
                                            selectBun(false)
                                            handleCheckboxChange(currentCheckIndex, false)
                                        }

                                    }}
                                >Да
                                </div>
                                <div
                                    className="modal_btn no"
                                    onClick={() => {
                                        setShowModal(false)
                                        setBlockEmployee(false)
                                        handleCheckboxChange(currentCheckIndex, false)
                                        // if (currentCheck) {
                                        //     selectBun(true)
                                        // } else {
                                        //     selectBun(false)
                                        //     handleCheckboxChange(currentCheckIndex, true)
                                        // }
                                    }}
                                >Нет
                                </div>
                            </div>
                        </div>
                    </div>
                    <header
                        className="header order__header"
                        // style={headerName == 'Заказ' ? {display: "flex"} : {display: "none"}}
                    >
                        <div className="header__left">
                            <div>
                                <Link to={`/`}>
                                    <img src={leftImg} className="logo_img" width="25px"
                                         height="25px"/>
                                </Link>
                            </div>
                        </div>
                        <div className="header__name"
                        >
                        </div>
                        <div className="header__right">
                            <div className="filter_"></div>
                        </div>
                    </header>
                    <div className="catalog__items bio__items">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            className="contractor_add"
                            onClick={() => setRegOk(true)}
                        >
                            <Link
                                // to={`/createOrder/${url}`}
                            >
                                <img src={plusImg} className="logo_img" width="25px" height="25px"/>
                            </Link>
                        </div>


                        {names && (
                            <table>
                                <thead>
                                <tr>
                                    <th>Роль/ФИО</th>
                                    <th>Email</th>
                                    {/*<th>Фото</th>*/}
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {names
                                    //.filter(email => email.email !== "super@mail.ru")
                                    // .sort((a, b) => {
                                    //     // Sort the names array based on the 'name' field
                                    //     if (a.name < b.name) {
                                    //         return -1;
                                    //     }
                                    //     if (a.name > b.name) {
                                    //         return 1;
                                    //     }
                                    //     return 0;
                                    // })
                                    .map((item, index) => {
                                    // setArrayNames([...arrayNames, item[index]])
                                    return (
                                        <>
                                            <tr key={index} onClick={() => {
                                                openBio(index);
                                                openEmployee(item.email)
                                                setSelectedRow(index)
                                            }} style={{background: selectedRow === index ? '#f0f8ff' : 'transparent'}}>
                                                <td>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <div style={{color: "#706565"}}>{item.role}</div>
                                                        <div>{item.nameTitle}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="td_mail">{item.email}</div>
                                                </td>
                                                {/*<td>*/}
                                                {/*    <img src={item.dop1} width="30px" height="35px"/>*/}
                                                {/*</td>*/}
                                                <td>
                                                    <div>
                                                        <div>{item.id}</div>
                                                        <input
                                                            type="checkbox"
                                                            className="employees_checkbox"
                                                            id={`myCheckbox${index}`}
                                                            checked={checked[index]}
                                                            onChange={(e) => {
                                                                setBlockEmployee(true);
                                                                setShowModal(true);
                                                                handleCheckboxChange(index, e.target.checked);
                                                                setCurrentCheck(e.target.checked);
                                                                setCurrentCheckIndex(index);
                                                                setNamesBan(item.email);
                                                        }}
                                                        />
                                                        <label className="employees_label"
                                                               htmlFor={`myCheckbox${index}`}></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                expandedIndex === index && (
                                                    <tr>
                                                        <td colSpan="4" >
                                                            <div className="bio"
                                                                // style={info.userCreate[bio] ? {display: "block"} : {display: "none"}}
                                                            >
                                                                <div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between"
                                                                    }}>
                                                                        <div>{item.role}</div>

                                                                        <div style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between"
                                                                        }}>
                                                                            <button
                                                                                onClick={() => {
                                                                                    // window.location.reload();
                                                                                    // deleteEmployee()
                                                                                    setShowModal(true)
                                                                                    setDeleteEmployee(true)
                                                                                    // deleteUser(item.email)
                                                                                    setCurrentDeleteItem(item.email)
                                                                                }}
                                                                                className="menu__main_button delete"
                                                                            >Удалить
                                                                            </button>
                                                                            <button
                                                                                onClick={() => {
                                                                                    showSaveBtn(true)
                                                                                    setDis(false)
                                                                                }}
                                                                                className="menu__main_button update"
                                                                                style={!saveBtn ? {display: "block"} : {display: "none"}}
                                                                            >
                                                                                Редактировать
                                                                            </button>
                                                                            <button
                                                                                onClick={() => {
                                                                                    showSaveBtn(false)
                                                                                    updateEmployee(item.email)
                                                                                }}
                                                                                className="menu__main_button update"
                                                                                style={saveBtn ? {display: "block"} : {display: "none"}}
                                                                            >
                                                                                Сохранить
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div >
                                                                        <div>
                                                                            {/*<div className="bio_item__header">ФИО</div>*/}
                                                                            {/*<div*/}
                                                                            {/*    className="bio_item__desc">{item.name}</div>*/}
                                                                            {/*<div className="bio_item__header">Номер*/}
                                                                            {/*    телефона*/}
                                                                            {/*</div>*/}
                                                                            {/*<div*/}
                                                                            {/*    className="bio_item__desc">{item.dop}</div>*/}
                                                                            {/*<div className="bio_item__header">Email*/}
                                                                            {/*</div>*/}
                                                                            {/*<div className="td_mail">{item.email}</div>*/}

                                                                            <div className="input_item">
                                                                                <input
                                                                                    className="item_"
                                                                                    name="name"
                                                                                    type="text"
                                                                                    value={name}
                                                                                    onChange={handleSentName}
                                                                                    disabled={dis}
                                                                                    placeholder="ФИО"
                                                                                    style={saveBtn ? {borderBottom: "1px solid #ffffff"} : {display: "1px solid transparent"}}
                                                                                />
                                                                            </div>
                                                                            <div className="input_item">
                                                                                <input
                                                                                    className="item_"
                                                                                    name="email"
                                                                                    type="text"
                                                                                    value={email}
                                                                                    onChange={handleSentEmail}
                                                                                    disabled="true"
                                                                                    placeholder="Введите email"
                                                                                    style={saveBtn ? {borderBottom: "1px solid #ffffff"} : {display: "1px solid transparent"}}
                                                                                />
                                                                            </div>
                                                                            <div className="input_item">
                                                                                <input
                                                                                    className="item_"
                                                                                    name="phone"
                                                                                    type="text"
                                                                                    value={phone}
                                                                                    onChange={handleSentPhone}
                                                                                    disabled={dis}
                                                                                    placeholder="Телефон"
                                                                                    style={saveBtn ? {borderBottom: "1px solid #ffffff"} : {display: "1px solid transparent"}}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {/*<div>*/}
                                                                        {/*    <img src={item.dop1}*/}
                                                                        {/*         width="130px" height="145px"/>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                    );
                                    })}
                                {/*<tr>*/}
                                {/*    <td colSpan="4">*/}
                                {/*        <button style={{display: showMore ? "block" : "none"}}*/}
                                {/*                onClick={() => {*/}
                                {/*                    setShowCount(prevCount => prevCount + 5);*/}
                                {/*                    console.log('click', showCount)*/}
                                {/*                }}*/}
                                {/*                className="item_card__button"*/}
                                {/*        >*/}
                                {/*            Показать больше*/}
                                {/*        </button>*/}
                                {/*        <button style={{display: !showMore ? "block" : "none"}}*/}
                                {/*                onClick={() => {*/}
                                {/*                    setShowCount(5)*/}
                                {/*                    console.log('click', showCount)*/}
                                {/*                }}*/}
                                {/*                className="item_card__button"*/}
                                {/*        >*/}
                                {/*            Свернуть*/}
                                {/*        </button>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                                </tbody>
                            </table>
                        )}


                        <div style={{...styles.formItem, display: regOk ? "flex" : "none"}}>
                            <h4>Создание сотрудника</h4>
                            <form style={styles.form}>
                                <header
                                    className="header order__header"
                                    style={styles.header}
                                >
                                    <div className="header__left" onClick={() => setRegOk(false)}>
                                        {/*<div onClick={() => setRegOk(false)} style={styles.logoImg}>*/}
                                        {/*    <img src={leftImg} className="logo_img" width="20px"*/}
                                        {/*         height="20px"/>*/}
                                        {/*</div>*/}
                                        Отменить создание
                                    </div>
                                    <div className="header__name"
                                    >
                                    </div>
                                    <div className="header__right">

                                    </div>
                                </header>
                                <label>Для роли:
                                    <select
                                        name="filterby"
                                        className="filterby"
                                        aria-label="Поле в списке"
                                        onChange={handleSelect}
                                        defaultValue="manager"
                                    >
                                        <option value="admin">Администратор</option>
                                        <option value="buh">Бухгалтер</option>
                                        <option value="manager">Менеджер</option>
                                    </select>
                                </label>
                                <label>Email:
                                    <input
                                        type="email"
                                        value={email}
                                        style={styles.input}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </label>
                                <label>Password:
                                    <input
                                        type="password"
                                        value={password}
                                        style={styles.input}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </label>
                                <label>From Admin Code
                                    <input
                                        type="password"
                                        value={adminPassword}
                                        style={styles.input}
                                        onChange={(e) => setAdminPassword(e.target.value)}
                                    />
                                </label>
                                <label>ФИО:
                                    <input
                                        type="text"
                                        value={name}
                                        style={styles.input}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </label>
                                <label>Телефон:
                                    <input
                                        type="text"
                                        value={phone}
                                        style={styles.input}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </label>
                                <button type="submit" style={styles.button} onClick={(e) => {
                                    e.preventDefault();
                                    setRegOk(false);


                                    createUsers(
                                        selectRole,
                                        email,
                                        password,
                                        adminPassword,
                                        name,
                                        phone,
                                        '',
                                        false)

                                }}>
                                    Создать
                                </button>
                            </form>
                        </div>
                    </div>
                </>
            )
        case 'buh':
            return (
                <>
                    {/*<div*/}
                    {/*    className="bg"*/}
                    {/*    style={showModal ? {display: "block"} : {display: "none"}}*/}
                    {/*></div>*/}
                    {/*<div*/}
                    {/*    className="modal"*/}
                    {/*    style={showModal ? {display: "block"} : {display: "none"}}*/}
                    {/*>*/}
                    {/*    <div*/}
                    {/*        style={deleteEmployee ? {display: "block"} : {display: "none"}}*/}
                    {/*    >*/}
                    {/*        Вы точно хотите удалить сотрудника?*/}
                    {/*        <div className="modal__btns">*/}
                    {/*            <div*/}
                    {/*                className="modal_btn yes"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setDeleteEmployee(false)*/}
                    {/*                }}*/}
                    {/*            >Да*/}
                    {/*            </div>*/}
                    {/*            <div*/}
                    {/*                className="modal_btn no"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setDeleteEmployee(false)*/}
                    {/*                }}*/}
                    {/*            >Нет*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div*/}
                    {/*        style={blockEmployee ? {display: "block"} : {display: "none"}}*/}
                    {/*    >*/}
                    {/*        <div>Вы точно хотите*/}
                    {/*            {currentCheck ? ' заблокировать ' : ' разблокировать '}*/}
                    {/*            сотрудника?*/}
                    {/*        </div>*/}
                    {/*        <div className="modal__btns">*/}
                    {/*            <div*/}
                    {/*                className="modal_btn yes"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setBlockEmployee(false)*/}
                    {/*                    handleCheckboxChange(currentCheckIndex, true)*/}
                    {/*                    if (currentCheck) {*/}
                    {/*                        selectBun(true)*/}
                    {/*                    } else {*/}
                    {/*                        selectBun(false)*/}
                    {/*                        handleCheckboxChange(currentCheckIndex, false)*/}
                    {/*                    }*/}

                    {/*                }}*/}
                    {/*            >Да*/}
                    {/*            </div>*/}
                    {/*            <div*/}
                    {/*                className="modal_btn no"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setBlockEmployee(false)*/}
                    {/*                    handleCheckboxChange(currentCheckIndex, false)*/}
                    {/*                    // if (currentCheck) {*/}
                    {/*                    //     selectBun(true)*/}
                    {/*                    // } else {*/}
                    {/*                    //     selectBun(false)*/}
                    {/*                    //     handleCheckboxChange(currentCheckIndex, true)*/}
                    {/*                    // }*/}
                    {/*                }}*/}
                    {/*            >Нет*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <header
                        className="header order__header"
                        // style={headerName == 'Заказ' ? {display: "flex"} : {display: "none"}}
                    >
                        <div className="header__left">
                            <div>
                                <Link to={`/`}>
                                    <img src={leftImg} className="logo_img" width="25px"
                                         height="25px"/>
                                </Link>
                            </div>
                        </div>
                        <div className="header__name"
                        >
                        </div>
                        <div className="header__right">
                            <div className="filter_"></div>
                        </div>
                    </header>
                    <div className="catalog__items bio__items">
                        {/*<div*/}
                        {/*    style={{*/}
                        {/*        display: "flex",*/}
                        {/*        justifyContent: "center",*/}
                        {/*        alignItems: "center"*/}
                        {/*    }}*/}
                        {/*    className="contractor_add"*/}
                        {/*    onClick={() => setRegOk(true)}*/}
                        {/*>*/}
                        {/*    <Link*/}
                        {/*        // to={`/createOrder/${url}`}*/}
                        {/*    >*/}
                        {/*        <img src={plusImg} className="logo_img" width="25px" height="25px"/>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}


                        {names && (
                            <table>
                                <thead>
                                <tr>
                                    <th>Роль/ФИО</th>
                                    <th>Email</th>
                                    {/*<th>Фото</th>*/}
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {names.map((item, index) => {
                                    // setArrayNames([...arrayNames, item[index]])
                                    return (
                                        <>
                                            <tr key={index} onClick={() => {
                                                openBio(index);
                                                setSelectedRow(index)
                                            }} style={{background: selectedRow === index ? '#f0f8ff' : 'transparent'}}>
                                                <td>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <div style={{color: "#706565"}}>{item.role}</div>
                                                        <div>{item.nameTitle}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="td_mail">{item.email}</div>
                                                </td>
                                                {/*<td>*/}
                                                {/*    <img src={item.dop1} width="30px" height="35px"/>*/}
                                                {/*</td>*/}
                                                <td>
                                                    {/*<div>*/}
                                                    {/*    <div>{item.id}</div>*/}
                                                    {/*    <input*/}
                                                    {/*        type="checkbox"*/}
                                                    {/*        id={`myCheckbox${index}`}*/}
                                                    {/*        checked={checked[index]}*/}
                                                    {/*        onChange={(e) => {*/}
                                                    {/*            setBlockEmployee(true)*/}
                                                    {/*            setShowModal(true)*/}
                                                    {/*            handleCheckboxChange(index, e.target.checked)*/}
                                                    {/*            setCurrentCheck(e.target.checked)*/}
                                                    {/*            setCurrentCheckIndex(index)*/}
                                                    {/*            setNamesBan(item.email)*/}
                                                    {/*        }}*/}
                                                    {/*    />*/}
                                                    {/*    <label className="employees_label"*/}
                                                    {/*           htmlFor={`myCheckbox${index}`}></label>*/}
                                                    {/*</div>*/}
                                                </td>
                                            </tr>
                                            {
                                                expandedIndex === index && (
                                                    <tr>
                                                        <td colSpan="4">
                                                            <div className="bio"
                                                                // style={info.userCreate[bio] ? {display: "block"} : {display: "none"}}
                                                            >
                                                                <div>
                                                                    <div >
                                                                        <div>{item.role}</div>
                                                                        {/*<button*/}
                                                                        {/*    onClick={() => {*/}
                                                                        {/*        // window.location.reload();*/}
                                                                        {/*        // deleteEmployee()*/}
                                                                        {/*        setShowModal(true)*/}
                                                                        {/*        setDeleteEmployee(true)*/}
                                                                        {/*        deleteUser(item.email)*/}
                                                                        {/*    }}*/}
                                                                        {/*    className="menu__main_button delete"*/}
                                                                        {/*>Удалить сотрудника*/}
                                                                        {/*</button>*/}
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between"
                                                                    }}>
                                                                        <div>
                                                                            <div className="bio_item__header">ФИО</div>
                                                                            <div
                                                                                className="bio_item__desc">{item.name}</div>
                                                                            <div className="bio_item__header">Номер
                                                                                телефона
                                                                            </div>
                                                                            <div
                                                                                className="bio_item__desc">{item.dop}</div>
                                                                            <div className="bio_item__header">Email
                                                                            </div>
                                                                            <div className="td_mail">{item.email}</div>
                                                                        </div>
                                                                        {/*<div>*/}
                                                                        {/*    <img src={item.dop1}*/}
                                                                        {/*         width="130px" height="145px"/>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                    );
                                })}
                                {/*<tr>*/}
                                {/*    <td colSpan="4">*/}
                                {/*        <button style={{display: showMore ? "block" : "none"}}*/}
                                {/*                onClick={() => {*/}
                                {/*                    setShowCount(prevCount => prevCount + 5);*/}
                                {/*                    console.log('click', showCount)*/}
                                {/*                }}*/}
                                {/*                className="item_card__button"*/}
                                {/*        >*/}
                                {/*            Показать больше*/}
                                {/*        </button>*/}
                                {/*        <button style={{display: !showMore ? "block" : "none"}}*/}
                                {/*                onClick={() => {*/}
                                {/*                    setShowCount(5)*/}
                                {/*                    console.log('click', showCount)*/}
                                {/*                }}*/}
                                {/*                className="item_card__button"*/}
                                {/*        >*/}
                                {/*            Свернуть*/}
                                {/*        </button>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                                </tbody>
                            </table>
                        )}


                        {/*<div style={{...styles.formItem, display: regOk ? "flex" : "none"}}>*/}
                        {/*    <h4>Создание сотрудника</h4>*/}
                        {/*    <form style={styles.form}>*/}
                        {/*        <header*/}
                        {/*            className="header order__header"*/}
                        {/*            style={styles.header}*/}
                        {/*        >*/}
                        {/*            <div className="header__left" onClick={() => setRegOk(false)}>*/}
                        {/*                /!*<div onClick={() => setRegOk(false)} style={styles.logoImg}>*!/*/}
                        {/*                /!*    <img src={leftImg} className="logo_img" width="20px"*!/*/}
                        {/*                /!*         height="20px"/>*!/*/}
                        {/*                /!*</div>*!/*/}
                        {/*                Отменить создание*/}
                        {/*            </div>*/}
                        {/*            <div className="header__name"*/}
                        {/*            >*/}
                        {/*            </div>*/}
                        {/*            <div className="header__right">*/}

                        {/*            </div>*/}
                        {/*        </header>*/}
                        {/*        <label>Для роли:*/}
                        {/*            <select*/}
                        {/*                name="filterby"*/}
                        {/*                className="filterby"*/}
                        {/*                aria-label="Поле в списке"*/}
                        {/*                onChange={handleSelect}*/}
                        {/*                defaultValue="manager"*/}
                        {/*            >*/}
                        {/*                <option value="admin">Администратор</option>*/}
                        {/*                <option value="buh">Бухгалтер</option>*/}
                        {/*                <option value="manager">Менеджер</option>*/}
                        {/*            </select>*/}
                        {/*        </label>*/}
                        {/*        <label>Email:*/}
                        {/*            <input*/}
                        {/*                type="email"*/}
                        {/*                value={email}*/}
                        {/*                style={styles.input}*/}
                        {/*                onChange={(e) => setEmail(e.target.value)}*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*        <label>Password:*/}
                        {/*            <input*/}
                        {/*                type="password"*/}
                        {/*                value={password}*/}
                        {/*                style={styles.input}*/}
                        {/*                onChange={(e) => setPassword(e.target.value)}*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*        <label>From Admin Code*/}
                        {/*            <input*/}
                        {/*                type="password"*/}
                        {/*                value={adminPassword}*/}
                        {/*                style={styles.input}*/}
                        {/*                onChange={(e) => setAdminPassword(e.target.value)}*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*        <label>ФИО:*/}
                        {/*            <input*/}
                        {/*                type="text"*/}
                        {/*                value={name}*/}
                        {/*                style={styles.input}*/}
                        {/*                onChange={(e) => setName(e.target.value)}*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*        <label>Телефон:*/}
                        {/*            <input*/}
                        {/*                type="text"*/}
                        {/*                value={phone}*/}
                        {/*                style={styles.input}*/}
                        {/*                onChange={(e) => setPhone(e.target.value)}*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*        <button type="submit" style={styles.button} onClick={(e) => {*/}
                        {/*            e.preventDefault();*/}
                        {/*            setRegOk(false);*/}


                        {/*            createUsers(*/}
                        {/*                selectRole,*/}
                        {/*                email,*/}
                        {/*                password,*/}
                        {/*                adminPassword,*/}
                        {/*                name,*/}
                        {/*                phone,*/}
                        {/*                '',*/}
                        {/*                false)*/}

                        {/*        }}>*/}
                        {/*            Создать*/}
                        {/*        </button>*/}
                        {/*    </form>*/}
                        {/*</div>*/}
                    </div>
                </>
            )
        case 'manager':
            return (
                <>
                    {/*<div*/}
                    {/*    className="bg"*/}
                    {/*    style={showModal ? {display: "block"} : {display: "none"}}*/}
                    {/*></div>*/}
                    {/*<div*/}
                    {/*    className="modal"*/}
                    {/*    style={showModal ? {display: "block"} : {display: "none"}}*/}
                    {/*>*/}
                    {/*    <div*/}
                    {/*        style={deleteEmployee ? {display: "block"} : {display: "none"}}*/}
                    {/*    >*/}
                    {/*        Вы точно хотите удалить сотрудника?*/}
                    {/*        <div className="modal__btns">*/}
                    {/*            <div*/}
                    {/*                className="modal_btn yes"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setDeleteEmployee(false)*/}
                    {/*                }}*/}
                    {/*            >Да*/}
                    {/*            </div>*/}
                    {/*            <div*/}
                    {/*                className="modal_btn no"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setDeleteEmployee(false)*/}
                    {/*                }}*/}
                    {/*            >Нет*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div*/}
                    {/*        style={blockEmployee ? {display: "block"} : {display: "none"}}*/}
                    {/*    >*/}
                    {/*        <div>Вы точно хотите*/}
                    {/*            {currentCheck ? ' заблокировать ' : ' разблокировать '}*/}
                    {/*            сотрудника?*/}
                    {/*        </div>*/}
                    {/*        <div className="modal__btns">*/}
                    {/*            <div*/}
                    {/*                className="modal_btn yes"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setBlockEmployee(false)*/}
                    {/*                    handleCheckboxChange(currentCheckIndex, true)*/}
                    {/*                    if (currentCheck) {*/}
                    {/*                        selectBun(true)*/}
                    {/*                    } else {*/}
                    {/*                        selectBun(false)*/}
                    {/*                        handleCheckboxChange(currentCheckIndex, false)*/}
                    {/*                    }*/}

                    {/*                }}*/}
                    {/*            >Да*/}
                    {/*            </div>*/}
                    {/*            <div*/}
                    {/*                className="modal_btn no"*/}
                    {/*                onClick={() => {*/}
                    {/*                    setShowModal(false)*/}
                    {/*                    setBlockEmployee(false)*/}
                    {/*                    handleCheckboxChange(currentCheckIndex, false)*/}
                    {/*                    // if (currentCheck) {*/}
                    {/*                    //     selectBun(true)*/}
                    {/*                    // } else {*/}
                    {/*                    //     selectBun(false)*/}
                    {/*                    //     handleCheckboxChange(currentCheckIndex, true)*/}
                    {/*                    // }*/}
                    {/*                }}*/}
                    {/*            >Нет*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <header
                        className="header order__header"
                        // style={headerName == 'Заказ' ? {display: "flex"} : {display: "none"}}
                    >
                        <div className="header__left">
                            <div>
                                <Link to={`/`}>
                                    <img src={leftImg} className="logo_img" width="25px"
                                         height="25px"/>
                                </Link>
                            </div>
                        </div>
                        <div className="header__name"
                        >
                        </div>
                        <div className="header__right">
                            <div className="filter_"></div>
                        </div>
                    </header>
                    <div className="catalog__items bio__items">
                        {/*<div*/}
                        {/*    style={{*/}
                        {/*        display: "flex",*/}
                        {/*        justifyContent: "center",*/}
                        {/*        alignItems: "center"*/}
                        {/*    }}*/}
                        {/*    className="contractor_add"*/}
                        {/*    onClick={() => setRegOk(true)}*/}
                        {/*>*/}
                        {/*    <Link*/}
                        {/*        // to={`/createOrder/${url}`}*/}
                        {/*    >*/}
                        {/*        <img src={plusImg} className="logo_img" width="25px" height="25px"/>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}


                        {names && (
                            <table>
                                <thead>
                                <tr>
                                    <th>Роль/ФИО</th>
                                    <th>Email</th>
                                    {/*<th>Фото</th>*/}
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {names.map((item, index) => {
                                    // setArrayNames([...arrayNames, item[index]])
                                    return (
                                        <>
                                            <tr key={index} onClick={() => {
                                                openBio(index);
                                                setSelectedRow(index)
                                            }} style={{background: selectedRow === index ? '#f0f8ff' : 'transparent'}}>
                                                <td>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <div style={{color: "#706565"}}>{item.role}</div>
                                                        <div>{item.nameTitle}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="td_mail">{item.email}</div>
                                                </td>
                                                {/*<td>*/}
                                                {/*    <img src={item.dop1} width="30px" height="35px"/>*/}
                                                {/*</td>*/}
                                                <td>
                                                    {/*<div>*/}
                                                    {/*    <div>{item.id}</div>*/}
                                                    {/*    <input*/}
                                                    {/*        type="checkbox"*/}
                                                    {/*        id={`myCheckbox${index}`}*/}
                                                    {/*        checked={checked[index]}*/}
                                                    {/*        onChange={(e) => {*/}
                                                    {/*            setBlockEmployee(true)*/}
                                                    {/*            setShowModal(true)*/}
                                                    {/*            handleCheckboxChange(index, e.target.checked)*/}
                                                    {/*            setCurrentCheck(e.target.checked)*/}
                                                    {/*            setCurrentCheckIndex(index)*/}
                                                    {/*            setNamesBan(item.email)*/}
                                                    {/*        }}*/}
                                                    {/*    />*/}
                                                    {/*    <label className="employees_label"*/}
                                                    {/*           htmlFor={`myCheckbox${index}`}></label>*/}
                                                    {/*</div>*/}
                                                </td>
                                            </tr>
                                            {
                                                expandedIndex === index && (
                                                    <tr>
                                                        <td colSpan="4">
                                                            <div className="bio"
                                                                // style={info.userCreate[bio] ? {display: "block"} : {display: "none"}}
                                                            >
                                                                <div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between"
                                                                    }}>
                                                                        <div>{item.role}</div>
                                                                        {/*<button*/}
                                                                        {/*    onClick={() => {*/}
                                                                        {/*        // window.location.reload();*/}
                                                                        {/*        // deleteEmployee()*/}
                                                                        {/*        setShowModal(true)*/}
                                                                        {/*        setDeleteEmployee(true)*/}
                                                                        {/*        deleteUser(item.email)*/}
                                                                        {/*    }}*/}
                                                                        {/*    className="menu__main_button delete"*/}
                                                                        {/*>Удалить сотрудника*/}
                                                                        {/*</button>*/}
                                                                    </div>
                                                                    <div >
                                                                        <div>
                                                                            <div className="bio_item__header">ФИО</div>
                                                                            <div
                                                                                className="bio_item__desc">{item.name}</div>
                                                                            <div className="bio_item__header">Номер
                                                                                телефона
                                                                            </div>
                                                                            <div
                                                                                className="bio_item__desc">{item.dop}</div>
                                                                            <div className="bio_item__header">Email
                                                                            </div>
                                                                            <div className="td_mail">{item.email}</div>
                                                                        </div>
                                                                        {/*<div>*/}
                                                                        {/*    <img src={item.dop1}*/}
                                                                        {/*         width="130px" height="145px"/>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                    );
                                })}
                                {/*<tr>*/}
                                {/*    <td colSpan="4">*/}
                                {/*        <button style={{display: showMore ? "block" : "none"}}*/}
                                {/*                onClick={() => {*/}
                                {/*                    setShowCount(prevCount => prevCount + 5);*/}
                                {/*                    console.log('click', showCount)*/}
                                {/*                }}*/}
                                {/*                className="item_card__button"*/}
                                {/*        >*/}
                                {/*            Показать больше*/}
                                {/*        </button>*/}
                                {/*        <button style={{display: !showMore ? "block" : "none"}}*/}
                                {/*                onClick={() => {*/}
                                {/*                    setShowCount(5)*/}
                                {/*                    console.log('click', showCount)*/}
                                {/*                }}*/}
                                {/*                className="item_card__button"*/}
                                {/*        >*/}
                                {/*            Свернуть*/}
                                {/*        </button>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                                </tbody>
                            </table>
                        )}


                        {/*<div style={{...styles.formItem, display: regOk ? "flex" : "none"}}>*/}
                        {/*    <h4>Создание сотрудника</h4>*/}
                        {/*    <form style={styles.form}>*/}
                        {/*        <header*/}
                        {/*            className="header order__header"*/}
                        {/*            style={styles.header}*/}
                        {/*        >*/}
                        {/*            <div className="header__left" onClick={() => setRegOk(false)}>*/}
                        {/*                /!*<div onClick={() => setRegOk(false)} style={styles.logoImg}>*!/*/}
                        {/*                /!*    <img src={leftImg} className="logo_img" width="20px"*!/*/}
                        {/*                /!*         height="20px"/>*!/*/}
                        {/*                /!*</div>*!/*/}
                        {/*                Отменить создание*/}
                        {/*            </div>*/}
                        {/*            <div className="header__name"*/}
                        {/*            >*/}
                        {/*            </div>*/}
                        {/*            <div className="header__right">*/}

                        {/*            </div>*/}
                        {/*        </header>*/}
                        {/*        <label>Для роли:*/}
                        {/*            <select*/}
                        {/*                name="filterby"*/}
                        {/*                className="filterby"*/}
                        {/*                aria-label="Поле в списке"*/}
                        {/*                onChange={handleSelect}*/}
                        {/*                defaultValue="manager"*/}
                        {/*            >*/}
                        {/*                <option value="admin">Администратор</option>*/}
                        {/*                <option value="buh">Бухгалтер</option>*/}
                        {/*                <option value="manager">Менеджер</option>*/}
                        {/*            </select>*/}
                        {/*        </label>*/}
                        {/*        <label>Email:*/}
                        {/*            <input*/}
                        {/*                type="email"*/}
                        {/*                value={email}*/}
                        {/*                style={styles.input}*/}
                        {/*                onChange={(e) => setEmail(e.target.value)}*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*        <label>Password:*/}
                        {/*            <input*/}
                        {/*                type="password"*/}
                        {/*                value={password}*/}
                        {/*                style={styles.input}*/}
                        {/*                onChange={(e) => setPassword(e.target.value)}*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*        <label>From Admin Code*/}
                        {/*            <input*/}
                        {/*                type="password"*/}
                        {/*                value={adminPassword}*/}
                        {/*                style={styles.input}*/}
                        {/*                onChange={(e) => setAdminPassword(e.target.value)}*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*        <label>ФИО:*/}
                        {/*            <input*/}
                        {/*                type="text"*/}
                        {/*                value={name}*/}
                        {/*                style={styles.input}*/}
                        {/*                onChange={(e) => setName(e.target.value)}*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*        <label>Телефон:*/}
                        {/*            <input*/}
                        {/*                type="text"*/}
                        {/*                value={phone}*/}
                        {/*                style={styles.input}*/}
                        {/*                onChange={(e) => setPhone(e.target.value)}*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*        <button type="submit" style={styles.button} onClick={(e) => {*/}
                        {/*            e.preventDefault();*/}
                        {/*            setRegOk(false);*/}


                        {/*            createUsers(*/}
                        {/*                selectRole,*/}
                        {/*                email,*/}
                        {/*                password,*/}
                        {/*                adminPassword,*/}
                        {/*                name,*/}
                        {/*                phone,*/}
                        {/*                '',*/}
                        {/*                false)*/}

                        {/*        }}>*/}
                        {/*            Создать*/}
                        {/*        </button>*/}
                        {/*    </form>*/}
                        {/*</div>*/}
                    </div>
                </>
            )
    }
}

export {TheEmployees}
import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import pdfImg from "../img/pdf.svg";
import leftImg from "../img/left_arrow.svg";

const ThePayments = ({info, show}) => {
    const [moneyMove, setMoneyMove] = useState(true);
    const [pays, setPays] = useState(false);

    const [all, setAll] = useState(true);
    const [plus, setPlus] = useState(false);
    const [minus, setMinus] = useState(false);

    const [url, setUrl] = useState('');

    const navigate = useNavigate();

    const goBack = (e) => {
        e.preventDefault();
        navigate(-1);
    };

    const paysClick = () => {
        setMoneyMove(false);
        setPays(true);
        console.log('pays');
    }

    const moneyMoveClick = () => {
        setMoneyMove(true);
        setPays(false);
        console.log('money');
    }

    const allClick = () => {
        setAll(true);
        setPlus(false);
        setMinus(false);
    }

    const plusClick = () => {
        setAll(false);
        setPlus(true);
        setMinus(false);
    }

    const minusClick = () => {
        setAll(false);
        setPlus(false);
        setMinus(true);
    }

    const location = useLocation();

    const [linkItem, setLinkItem] = useState('')

    useEffect(() => {
        let parts = location.pathname.split('/');
        let lastWord = parts[parts.length - 1];
        setLinkItem(lastWord)
        // if (lastWord === '') {
        //     lastWord = parts[parts.length - 1];
        // }
        // console.log('lastWordOrders', lastWord)

    }, [location]);


    return (
        <div>
            <div className="cash">
                {/*<div className="head">Деньги контрагента - <span>Движение средств</span></div>*/}
                {/*<div className="head">{info.name}, ИНН - {info.INN}, {info.city}, ({info.region})</div>*/}
                <div className="left_links">
                    <Link to={`/`}
                          style={show ? {display: "flex"} : {display: "none"}}
                    >
                        <img src={leftImg} className="logo_img" width="25px"
                             height="25px"/>
                    </Link>
                    <Link to={`/card/${linkItem}`}
                          style={!show ? {display: "flex"} : {display: "none"}}
                    >
                        <img src={leftImg} className="logo_img" width="25px"
                             height="25px"/>
                    </Link>
                </div>
                <div className="clickers">
                    <div className="clicker item_card__button" onClick={() => moneyMoveClick()}
                         style={moneyMove ? {borderBottom: "4px solid #ffffff"} : {backgroundColor: "#00b3e6"}}
                    >ДВИЖЕНИЕ СРЕДСТВ
                    </div>

                    <div className="clicker item_card__button" onClick={() => paysClick()}
                         style={!moneyMove ? {borderBottom: "4px solid #ffffff"} : {backgroundColor: "#00b3e6"}}
                    >ВЫПЛАТЫ
                    </div>
                </div>
                {/*<div className="balance body_item">*/}
                {/*    <div className="up">*/}
                {/*        Начальный остаток <span>0</span>р.*/}
                {/*    </div>*/}
                {/*    <div className="balance">*/}
                {/*        <div className="balance__item">Баланс <div>11440<span>р.</span></div></div>*/}
                {/*    </div>*/}
                {/*    <div className="down">*/}
                {/*        Конечный остаток <span>11440</span>р.*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="content body_item">
                    {/*<button onClick={goBack}>Go Back</button>*/}


                    <div className="content__block cashmove"
                         style={moneyMove ? {display: "block"} : {display: "none"}}
                    >
                        <div className="center"
                        >
                            <div className="cashmove__balance">
                                <div className="money"><span>23940,09</span>р</div>
                                <div className="account">Счет ****<span>4565</span></div>
                            </div>
                            <div className="clickers">
                                <div className="clicker item_card__button" onClick={() => allClick()}
                                     style={all ? {borderBottom: "4px solid #ffffff"} : {backgroundColor: "#00b3e6"}}
                                >Все
                                </div>

                                <div className="clicker item_card__button" onClick={() => plusClick()}
                                     style={plus ? {borderBottom: "4px solid #ffffff"} : {backgroundColor: "#00b3e6"}}
                                >Поступления
                                </div>

                                <div className="clicker item_card__button" onClick={() => minusClick()}
                                     style={minus ? {borderBottom: "4px solid #ffffff"} : {backgroundColor: "#00b3e6"}}
                                >Списания
                                </div>
                            </div>
                            <div className="all"
                                 style={all ? {display: "block"} : {display: "none"}}
                            >
                                <div className="item">
                                    <div className="date">
                                        <div className="day">1 февраля 2024 г.</div>
                                        <div className="begin_day_balance">На начало дня: <span>19714,14</span>Р</div>
                                    </div>
                                    <div className="money">
                                        <div className="num">+<span>4269,90</span>Р</div>
                                        <div className="info">
                                            <div className="bank">УРАЛЬСКИЙ БАНК ПАО СБЕРБАНК</div>
                                            <div className="about_money">Зачисление средств по операциям эквайринга.
                                                Мерчант №951000063235. Комиссия 30.10. НДС
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="date">
                                        <div className="day">22 января 2024 г.</div>
                                        <div className="begin_day_balance">На начало дня: <span>10667,12</span>Р</div>
                                    </div>
                                    <div className="money">
                                        <div className="num">+<span>9047,02</span>Р</div>
                                        <div className="info">
                                            <div className="bank">УРАЛЬСКИЙ БАНК ПАО СБЕРБАНК</div>
                                            <div className="about_money">Зачисление средств по операциям эквайринга.
                                                Мерчант №951000063445. Комиссия 30.10. НДС
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="date">
                                        <div className="day">21 января 2024 г.</div>
                                        <div className="begin_day_balance">На начало дня: <span>5878,82</span>Р</div>
                                    </div>
                                    <div className="money">
                                        <div className="num">+<span>4787,30</span>Р</div>
                                        <div className="info">
                                            <div className="bank">УРАЛЬСКИЙ БАНК ПАО СБЕРБАНК</div>
                                            <div className="about_money">Зачисление средств по операциям эквайринга.
                                                Мерчант №951000063445. Комиссия 30.10. НДС
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="date">
                                        <div className="day">1 февраля 2024 г.</div>
                                        <div className="begin_day_balance">На начало дня: <span>19714,14</span>Р</div>
                                    </div>
                                    <div className="money">
                                        <div className="num">-<span>1269,90</span>Р</div>
                                        <div className="info">
                                            <div className="bank">УРАЛЬСКИЙ БАНК ПАО СБЕРБАНК</div>
                                            <div className="about_money">Зачисление средств по операциям эквайринга.
                                                Мерчант №951000063235. Комиссия 30.10. НДС
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="date">
                                        <div className="day">22 января 2024 г.</div>
                                        <div className="begin_day_balance">На начало дня: <span>10667,12</span>Р</div>
                                    </div>
                                    <div className="money">
                                        <div className="num">-<span>3147,02</span>Р</div>
                                        <div className="info">
                                            <div className="bank">УРАЛЬСКИЙ БАНК ПАО СБЕРБАНК</div>
                                            <div className="about_money">Зачисление средств по операциям эквайринга.
                                                Мерчант №951000063445. Комиссия 30.10. НДС
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="date">
                                        <div className="day">21 января 2024 г.</div>
                                        <div className="begin_day_balance">На начало дня: <span>5878,82</span>Р</div>
                                    </div>
                                    <div className="money">
                                        <div className="num">-<span>787,30</span>Р</div>
                                        <div className="info">
                                            <div className="bank">УРАЛЬСКИЙ БАНК ПАО СБЕРБАНК</div>
                                            <div className="about_money">Зачисление средств по операциям эквайринга.
                                                Мерчант №951000063445. Комиссия 30.10. НДС
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="plus"
                                 style={plus ? {display: "block"} : {display: "none"}}
                            >
                                <div className="item">
                                    <div className="date">
                                        <div className="day">1 февраля 2024 г.</div>
                                        <div className="begin_day_balance">На начало дня: <span>19714,14</span>Р</div>
                                    </div>
                                    <div className="money">
                                        <div className="num">+<span>4269,90</span>Р</div>
                                        <div className="info">
                                            <div className="bank">УРАЛЬСКИЙ БАНК ПАО СБЕРБАНК</div>
                                            <div className="about_money">Зачисление средств по операциям эквайринга.
                                                Мерчант №951000063235. Комиссия 30.10. НДС
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="date">
                                        <div className="day">22 января 2024 г.</div>
                                        <div className="begin_day_balance">На начало дня: <span>10667,12</span>Р</div>
                                    </div>
                                    <div className="money">
                                        <div className="num">+<span>9047,02</span>Р</div>
                                        <div className="info">
                                            <div className="bank">УРАЛЬСКИЙ БАНК ПАО СБЕРБАНК</div>
                                            <div className="about_money">Зачисление средств по операциям эквайринга.
                                                Мерчант №951000063445. Комиссия 30.10. НДС
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="date">
                                        <div className="day">21 января 2024 г.</div>
                                        <div className="begin_day_balance">На начало дня: <span>5878,82</span>Р</div>
                                    </div>
                                    <div className="money">
                                        <div className="num">+<span>4787,30</span>Р</div>
                                        <div className="info">
                                            <div className="bank">УРАЛЬСКИЙ БАНК ПАО СБЕРБАНК</div>
                                            <div className="about_money">Зачисление средств по операциям эквайринга.
                                                Мерчант №951000063445. Комиссия 30.10. НДС
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="minus"
                                 style={minus ? {display: "block"} : {display: "none"}}
                            >
                                <div className="item">
                                    <div className="date">
                                        <div className="day">1 февраля 2024 г.</div>
                                        <div className="begin_day_balance">На начало дня: <span>19714,14</span>Р</div>
                                    </div>
                                    <div className="money">
                                        <div className="num">-<span>1269,90</span>Р</div>
                                        <div className="info">
                                            <div className="bank">УРАЛЬСКИЙ БАНК ПАО СБЕРБАНК</div>
                                            <div className="about_money">Зачисление средств по операциям эквайринга.
                                                Мерчант №951000063235. Комиссия 30.10. НДС
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="date">
                                        <div className="day">22 января 2024 г.</div>
                                        <div className="begin_day_balance">На начало дня: <span>10667,12</span>Р</div>
                                    </div>
                                    <div className="money">
                                        <div className="num">-<span>3147,02</span>Р</div>
                                        <div className="info">
                                            <div className="bank">УРАЛЬСКИЙ БАНК ПАО СБЕРБАНК</div>
                                            <div className="about_money">Зачисление средств по операциям эквайринга.
                                                Мерчант №951000063445. Комиссия 30.10. НДС
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="date">
                                        <div className="day">21 января 2024 г.</div>
                                        <div className="begin_day_balance">На начало дня: <span>5878,82</span>Р</div>
                                    </div>
                                    <div className="money">
                                        <div className="num">-<span>787,30</span>Р</div>
                                        <div className="info">
                                            <div className="bank">УРАЛЬСКИЙ БАНК ПАО СБЕРБАНК</div>
                                            <div className="about_money">Зачисление средств по операциям эквайринга.
                                                Мерчант №951000063445. Комиссия 30.10. НДС
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content__block pays"
                         style={pays ? {display: "block"} : {display: "none"}}
                    >
                        <div className="orders__wrapper all"

                        >
                            <div className="orders__date">16 февраля 2024</div>
                            <Link to={`/pay/${linkItem}`}>
                                <div className="orders__item">
                                    <div className="item_up">
                                        <div className="item_left">
                                            <div className="item adress up">
                                                <div className="period">Период выплаты</div>
                                                <div>
                                                    <div className="item tm">01.02.2024</div>
                                                    <div className="item tm">08.02.2024 (включительно)</div>
                                                </div>
                                            </div>
                                            <div className="item date">
                                                <div className="period">Время выплаты</div>
                                                <div>
                                                    <div className="item tm">09.02.2024</div>
                                                    <div className="item tm">15:28:00</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item_rigth">
                                            <div className="item price up"><span>1500,00</span>руб</div>
                                            <div className="item status inProcess">На исполнении</div>
                                            <div className="item pdf">
                                                <img src={pdfImg} className="pdf_img" width="25px"
                                                     height="25px"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item_down">
                                        <div className="item group">Оплата по договору №223 от 4.01.2024 за период 01.02.2024 по 08.02.2024 . НДС не облагается</div>
                                    </div>
                                </div>
                            </Link>


                            <div className="orders__date">15 февраля 2024</div>

                            <div className="orders__item">
                                <div className="item_up">
                                    <div className="item_left">
                                        <div className="item adress up">
                                            <div className="period">Период выплаты</div>
                                            <div>
                                                <div className="item tm">01.02.2024</div>
                                                <div className="item tm">08.02.2024 (включительно)</div>
                                            </div>
                                        </div>
                                        <div className="item date">
                                            <div className="period">Время выплаты</div>
                                            <div>
                                                <div className="item tm">09.02.2024</div>
                                                <div className="item tm">15:28:00</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item_rigth">
                                        <div className="item price up"><span>1500,00</span>руб</div>
                                        <div className="item status inProcess">На исполнении</div>
                                        <div className="item pdf">
                                            <img src={pdfImg} className="pdf_img" width="25px"
                                                 height="25px"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="item_down">
                                    <div className="item group">Оплата по договору №223 от 4.01.2024 за период 01.02.2024 по 08.02.2024 . НДС не облагается</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {ThePayments};
import React, {useEffect, useRef, useState} from 'react';
import phoneImg from "../img/phone.svg";
import waImg from "../img/whatsapp.svg";
import tgImg from "../img/telegram.svg";
import vbImg from "../img/viber.svg";
import copyImg from "../img/copy.svg";
import shareImg from "../img/share2.svg";
import {Link, useLocation} from "react-router-dom";
import leftImg from "../img/left_arrow.svg";
import rightImg from "../img/right-arrow.svg";
import {getCompany, getOne} from "../http/catalogAPI";
import {updateOneCardBD} from "../http/catalogAPI";
import shareFlowers from "../img/flowers.svg";
import shareStrawberry from "../img/strawberry.svg";
import shareBaloon from "../img/baloon.svg";
import shareCake from "../img/cake.svg";
//
const TheCard = ({info, role, open, inn, setRender, setResultCard}) => {
    console.log('infoCARD', info)

    const [disAddCity, setDisAddCity] = useState(false)
    const [disAddPhone, setDisAddPhone] = useState(false)
    const [searchedResult, setSearchedResult] = useState(false)

    const [modalResults, setModalResults] = useState([]);

    const [card, setCard] = useState([]);

    const [cities, setCities] = useState([]);
    const [additionalCity, setAdditionalCity] = useState([])

    const [dis, setDis] = useState(true);
    const [id, setId] = useState(0);
    const [name, setName] = useState("");
    const [INN, setINN] = useState("");
    const [OGRN, setOGRN] = useState("");
    const [rs, setRS] = useState("");
    const [bank, setBank] = useState("");
    const [BIK, setBIK] = useState("");
    const [contract, setContract] = useState("");
    const [city, setCity] = useState([]);
    const [cityMain, setCityMain] = useState("");
    const [region, setRegion] = useState("");
    const [leaderPhone, setLeaderPhone] = useState("");
    const [workPhone1, setWorkPhone1] = useState("");
    const [workPhone2, setWorkPhone2] = useState("");
    const [messenger1, setMessenger1] = useState("");
    const [messenger2, setMessenger2] = useState("");
    const [site, setSite] = useState('');
    const [QRrs, setQRrs] = useState("");
    const [QRstatic, setQRstatic] = useState("");
    const [soc1, setSoc1] = useState("");
    const [soc2, setSoc2] = useState("");

    const [saveBtn, showSaveBtn] = useState(false);

    const [innState, setInnState] = useState('');

    const location = useLocation();

    const [checked, setChecked] = useState(
        {
            flowers: false,
            strawberry: false,
            balloon: false,
            cakes: false,
        }
    );

    // useEffect(() => {
    //     // let result = info.contractor;
    //     let parts = location.pathname.split('/');
    //     let lastWord = parts[parts.length - 1];
    //     let cardOne1 = result.filter(card => card.INN === lastWord);
    //     setCard(cardOne1)
    //     console.log('lastCard1111', cardOne1)
    //     if (cardOne1.length > 0) {
    //         setId('cardOne1[0].id ||');
    //     }
    //
    //     if (cardOne1.length > 0) {
    //         const companyName = cardOne1[0].companyName || 'Название отсутствует';
    //         const city = cardOne1[0].city || 'Город не указан';
    //         document.title = `${companyName} - ${city}`;
    //
    //         let content = `${companyName} - ${city}`;
    //         localStorage.setItem("companyTitle", content);
    //
    //         // const metaDescription = document.querySelector('meta[name="description"]');
    //         // if (metaDescription) {
    //         //     metaDescription.setAttribute("content", content);
    //         // } else {
    //         //     const newMeta = document.createElement('meta');
    //         //     newMeta.name = "description";
    //         //     newMeta.content = `${companyName} - ${city}`;
    //         //     document.head.appendChild(newMeta);
    //         // }
    //     } else {
    //         document.title = 'MyBestFlowers';
    //     }
    //     // setName(cardOne1[0].companyName || '');
    //     // setINN(cardOne1[0].INN || '');
    //     // setOGRN(cardOne1[0].OGRN);
    //     // setBIK(cardOne1[0].BIK);
    //     // setContract(cardOne1[0].contract);
    //     // setCity(cardOne1[0].city);
    //     // setRegion(cardOne1[0].region);
    //     // setLeaderPhone(cardOne1[0].leaderPhone);
    //     // setWorkPhone1(cardOne1[0].workPhone1);
    //     // setWorkPhone2(cardOne1[0].workPhone2);
    //     // setMessenger1(cardOne1[0].comment1);
    //     // setMessenger2(cardOne1[0].comment2);
    //     // setSoc1(cardOne1[0].soc1);
    //     // setSoc2(cardOne1[0].soc2);
    //     // setQRrs(cardOne1[0].QRrs);
    //     // setQRstatic(cardOne1[0].QRstatic);
    //     // setBank(cardOne1[0].bankName);
    //     // setRS(cardOne1[0].rs);
    //     // setSite(cardOne1[0].siteName);
    //
    //     console.log('lastCard', cardOne1)
    //
    // }, [location, info, setId, setName, setINN]);

    useEffect(() => {
        if (card.length > 0) {
            const currentINN = card[0].INN
            console.log('currentINN', currentINN)
            setInnState(currentINN)
        }

    }, [card]);

    // const handleCheckboxChange = (key) => {
    //     setChecked(prevChecked => {
    //         console.log("THE CREATE", { ...prevChecked, [key]: !prevChecked[key] });
    //         return { ...prevChecked, [key]: !prevChecked[key] };
    //     });
    // };

    const handleCheckboxChange = (key) => {
        setChecked(prevChecked => {
            console.log('prevChecked THE CARD', prevChecked)
            const updatedChecked = {
                ...prevChecked,
                [key]: !prevChecked[key]
            };

            // Удаление лишнего ключа "true"
            delete updatedChecked.true;

            console.log("THE CREATE", updatedChecked);

            return updatedChecked;
        });
    };


    const handleSentName = (event) => {
        setName(event.target.value);
    };
    const handleSentINN = (event) => {
        setINN(event.target.value);
    };
    const handleSentOGRN = (event) => {
        setOGRN(event.target.value);
    };
    const handleSentBIK = (event) => {
        setBIK(event.target.value);
    };
    const handleSentContract = (event) => {
        setContract(event.target.value);
    };
    const handleSentQRrs = (event) => {
        setQRrs(event.target.value);
    };
    const handleSentQRstatic = (event) => {
        setQRstatic(event.target.value);
    };
    const handleSentRegion = (event) => {
        setRegion(event.target.value);
    };
    const handleSentCity = (event) => {
        setCity(event.target.value);
    };
    const handleSentWorkPhone1 = (event) => {
        setWorkPhone1(event.target.value);
    };
    const handleSentWorkPhone2 = (event) => {
        setWorkPhone2(event.target.value);
    };
    const handleSentLeaderPhone = (event) => {
        setLeaderPhone(event.target.value);
    };
    const handleSentMessenger1 = (event) => {
        setMessenger1(event.target.value);
    };
    const handleSentMessenger2 = (event) => {
        setMessenger2(event.target.value);
    };

    const handleSentBankName = (event) => {
        setBank(event.target.value);
    };
    const handleSentRS = (event) => {
        setRS(event.target.value);
    };
    const handleSentSite = (event) => {
        setSite(event.target.value);
    };


    const handleSentSoc1 = (event) => {
        setSoc1(event.target.value);
    };

    const handleSentSoc2 = (event) => {
        setSoc2(event.target.value);
    };

    const inputRefs = {
        textToCopyINNRef: useRef(null),
        textToCopyRSRef: useRef(null),
        textToCopyBIKRef: useRef(null),
        textToCopyOGRNRef: useRef(null),
    };

    const copyToClipboardOnClick = (inputName) => {
        if (inputRefs[inputName].current) {
            const valueText = inputRefs[inputName].current.value;

            // Создаем временный элемент textarea
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = valueText;
            document.body.appendChild(tempTextArea);

            // Выделяем текст внутри textarea
            tempTextArea.select();
            tempTextArea.setSelectionRange(0, 99999); // Для поддержки выбора текста в различных браузерах

            // Копируем текст в буфер обмена
            document.execCommand('copy');
            alert('Текст скопирован в буфер обмена');

            // Удаляем временный элемент
            document.body.removeChild(tempTextArea);
        }
    };

    const copyCurrentUrlToClipboard = () => {


        const valueText = window.location.toString();

        // Создаем временный элемент textarea
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = valueText;
        document.body.appendChild(tempTextArea);

        // Выделяем текст внутри textarea
        tempTextArea.select();
        tempTextArea.setSelectionRange(0, 99999); // Для поддержки выбора текста в различных браузерах

        // Копируем текст в буфер обмена
        document.execCommand('copy');
        alert('Ссылка скопирована в буфер обмена');

        // Удаляем временный элемент
        document.body.removeChild(tempTextArea);

    };

    const openCard = async (inn) => {
        // const result = await getCompany('1');
        let data = await getOne({
            name: inn
        });

        let groupedOrders = {};
        for (let i = 0; i < data.length; i++) {
            const order = data[i];
            if (!groupedOrders[order.INN]) {
                groupedOrders[order.INN] = [order];
            } else {
                groupedOrders[order.INN].push(order);
            }
        }

        console.log('!!!!groupedOrders', groupedOrders)

        const result = [];
        Object.keys(groupedOrders).forEach(inn => {
            const comp = groupedOrders[inn];
            let sumPayment = 0;
            comp.forEach(order => {
                if (order.storePayment) { // Добавляем проверку на существование свойств amount и profit
                    const storePayment = order.storePayment.replace(/[^\d.]/g, ''); // Оставляем только цифры в amount
                    sumPayment += parseFloat(storePayment);
                }
            });
            result.push({
                companyName: comp[0].name,
                INN: comp[0].INN,
                comment1: comp[0].messenger1,
                comment2: comp[0].messenger2,
                OGRN: comp[0].OGRN,
                region: comp[0].region,
                city: comp[0].city,
                id: comp[0].id,
                leaderPhone: comp[0].leaderPhone,
                contract: comp[0].contract,
                BIK: comp[0].BIK,
                QRrs: comp[0].QRrs,
                QRstatic: comp[0].QRstatic,
                workPhone1: comp[0].workPhone1,
                workPhone2: comp[0].workPhone2,
                turnover: comp[0].turnover,
                rating: comp[0].rating,
                quantity: comp[0].quantity,
                rs: comp[0].rs,
                soc1: comp[0].soc1,
                soc2: comp[0].soc2,
                siteName: comp[0].siteName,
                bankName: comp[0].bankName,

                companyOrder: comp.map(order => ({
                    city: order.city,
                    id: order.idBitrix,
                    createData: order.createData,
                    updateData: order.updateData,
                    moveData: order.moveData,
                    price: parseFloat(order.storePayment.replace(/[^\d.]/g, '')), // Оплачено магазину
                    amount: parseFloat(order.amount.replace(/[^\d.]/g, '')),   // всего пришло

                })),
                sumAll: sumPayment,
            });
        });
        console.log('!!!!!!!!!!!res1', result)

        result.sort((a, b) => {
            const completenessA = (a.contract !== '') * 2 + (a.QRstatic !== '') + (+(a.rs || a.QRrs ? 1 : 0));
            const completenessB = (b.contract !== '') * 2 + (b.QRstatic !== '') + (+(b.rs || b.QRrs ? 1 : 0));

            if (completenessB !== completenessA) {
                return completenessB - completenessA;
            } else {
                return b.sumAll - a.sumAll;
            }
        });

        console.log('result THE CARD', result)
        let cardOne = result;
        setCard(cardOne);
        setId(cardOne[0].id);
        setName(cardOne[0].companyName);
        setINN(cardOne[0].INN);
        setOGRN(cardOne[0].OGRN);
        setBIK(cardOne[0].BIK);
        setContract(cardOne[0].contract);
        setCity(cardOne[0].city);
        setRegion(cardOne[0].region);
        setLeaderPhone(cardOne[0].leaderPhone);
        setWorkPhone1(cardOne[0].workPhone1);
        setWorkPhone2(cardOne[0].workPhone2);
        setMessenger1(cardOne[0].comment1);
        setMessenger2(cardOne[0].comment2);
        setSoc1(cardOne[0].soc1);
        setSoc2(cardOne[0].soc2);
        setQRrs(cardOne[0].QRrs);
        setQRstatic(cardOne[0].QRstatic);
        setBank(cardOne[0].bankName);
        setRS(cardOne[0].rs);
        setSite(cardOne[0].siteName);
        console.log('name', cardOne[0].companyName);
        console.log('comment1', cardOne[0].comment1);
        console.log('cardOne', cardOne);
        // console.log('card', card);

        if (cardOne.length > 0 && cardOne[0].siteName) {
            try {
                const jsonCheck = JSON.parse(cardOne[0].siteName);

                console.log('jsonCheck', jsonCheck)

                // Determine if flowers should be true based on companyName
                // const companyNameStartsWithFl = cardOne[0].companyName.toLowerCase().startsWith('фл');

                // Update the checked state based on parsed data and companyName check

                // if (companyNameStartsWithFl) {
                //     setChecked(prevData => ({
                //             ...prevData,
                //             flowers: false,
                //             strawberry: true,
                //             baloon: jsonCheck.baloon,
                //             cakes: jsonCheck.cakes,
                //         })
                //     )
                //
                //     return
                // }

                setChecked(prevData => ({
                        ...prevData,
                        flowers: jsonCheck.flowers,
                        strawberry: jsonCheck.strawberry,
                        balloon: jsonCheck.balloon,
                        cakes: jsonCheck.cakes,
                    })
                )


            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        }

        console.log('ягодки', checked)
        // setChecked(prevChecked => {
        //     const updatedChecked = {
        //         ...prevChecked,
        //         [key]: !prevChecked[key]
        //     };
        //
        //     // Удаление лишнего ключа "true"
        //     delete updatedChecked.true;
        //
        //     console.log("THE CREATE загрузка по текущей ", updatedChecked);
        //
        //     return updatedChecked;
        // });
    }

    // useEffect(() => {
    //     if (open) {
    //         console.log('fdf')
    //         openCard(inn)
    //         console.log('inn7', inn)
    //         // console.log('card.name', card.name)
    //     }
    // }, [open])


    const makeData = (id) => {
        const normalizePhoneNumber = (phoneNumber) => {
            if (phoneNumber !== '') {
                const digits = phoneNumber.replace(/\D/g, ''); // Удаление всех нечисловых символов
                if (digits.length > 10) {
                    return '+7' + digits.slice(-10); // Если больше 10 цифр, обрезаем до 10 и добавляем +7 в начале
                } else {
                    return '+7' + digits; // Если меньше или равно 10 цифр, добавляем +7 в начале
                }
            }
        }
        let inns = info.contractor.filter(inn => inn.INN === INN && inn.id !== id);
        console.log('normalizePhoneNumber(leaderPhone))', normalizePhoneNumber(leaderPhone));
        console.log('normalizePhoneNumber(workPhone1)', normalizePhoneNumber(workPhone1));
        console.log('normalizePhoneNumber(workPhone2)', normalizePhoneNumber(workPhone2));

        if (inns[0]?.INN === INN) {
            const updateCard = {
                id: id,
                name: name,
                INN: INN,
                innState: innState,
                OGRN: OGRN,
                region: region,
                city: city,
                citybd: city.replace(/[\s-(){}\[\].,]/g, "").toLowerCase(),
                leaderPhone: normalizePhoneNumber(leaderPhone),
                comment1: messenger1,
                comment2: messenger2,
                contract: contract,
                BIK: BIK,
                QRrs: QRrs,
                QRstatic: QRstatic,
                workPhone1: normalizePhoneNumber(workPhone1),
                workPhone2: normalizePhoneNumber(workPhone2),
                bankName: bank,
                rs: rs,
                siteName: checked,
                soc1: soc1,
                soc2: soc2,
            }

            setDis(true);
            console.log('updateCard', updateCard)

            // let res = info.contractor.filter(item => item.INN === INN)
            setSite(JSON.stringify(checked))


            // console.log('res UPDATE', info.contractor)

            updateOneCardBD(updateCard).then(async data => {
                alert('Изменения успешно выполнены');
            })
                .catch(error => {
                    alert(error.message);
                });
        } else {
            let inns = info.contractor.filter(inn => inn.INN === INN && inn.id !== id);

            if (inns.length > 0) {
                alert('Введенный ИНН уже существует!');
                showSaveBtn(true);
                return;
            }
            if (INN.length === 0) {
                alert('Введите ИНН!');
                showSaveBtn(true);
                return;
            }

            const updateCard = {
                id: id,
                name: name,
                INN: INN,
                innState: innState,
                OGRN: OGRN,
                region: region,
                city: city,
                citybd: city.replace(/[\s-(){}\[\].,]/g, "").toLowerCase(),
                leaderPhone: normalizePhoneNumber(leaderPhone),
                comment1: messenger1,
                comment2: messenger2,
                contract: contract,
                BIK: BIK,
                QRrs: QRrs,
                QRstatic: QRstatic,
                workPhone1: normalizePhoneNumber(workPhone1) || '',
                workPhone2: normalizePhoneNumber(workPhone2) || '',
                bankName: bank,
                rs: rs,
                siteName: checked,
                soc1: soc1,
                soc2: soc2,
            }

            setDis(true);
            console.log('updateCard', updateCard)

            // let res = info.contractor.filter(item => item.INN === INN)
            setSite(JSON.stringify(checked))


            // console.log('res UPDATE', info.contractor)

            updateOneCardBD(updateCard).then(async data => {
                alert('Изменения успешно выполнены');
            })
                .catch(error => {
                    alert(error.message);
                });
        }


        console.log('id', id)
        console.log('cardMakeData', card)

    }

    // useEffect(() => {
    //     addCities().then()
    // }, [card]);


    useEffect(() => {
        // console.log('ИЗМЕНЕНИЯ после СОХРАНЕНИЯ', info.contractor)
        let parts = location.pathname.split('/');
        let lastWord = parts[parts.length - 1];
        let lastWord2 = decodeURIComponent(parts[parts.length - 2]).replace(/[\s-(){}\[\].,]/g, "").toLowerCase();
        const cityInEnglish = transliterate(lastWord2);
        console.log("lastWord ЧЧЧ", lastWord)
        console.log("lastWord2", lastWord2)

        // const fetch = async () => {
        //     let data = await getOne({
        //         name: lastWord
        //     });
        //
        //     let groupedOrders = {};
        //     for (let i = 0; i < data.length; i++) {
        //         const order = data[i];
        //         const key = `${order.INN}-${order.citybd}`;
        //
        //         if (transliterate(order.citybd) === cityInEnglish) {
        //             if (!groupedOrders[key]) {
        //                 groupedOrders[key] = [order];
        //             } else {
        //                 groupedOrders[key].push(order);
        //             }
        //         }
        //     }
        //
        //     console.log('!!!!groupedOrders', groupedOrders)
        //
        //     const result = [];
        //     Object.keys(groupedOrders).forEach(inn => {
        //         const comp = groupedOrders[inn];
        //         let sumPayment = 0;
        //         comp.forEach(order => {
        //             if (order.storePayment) { // Добавляем проверку на существование свойств amount и profit
        //                 const storePayment = order.storePayment.replace(/[^\d.]/g, ''); // Оставляем только цифры в amount
        //                 sumPayment += parseFloat(storePayment);
        //             }
        //         });
        //         result.push({
        //             companyName: comp[0].name,
        //             INN: comp[0].INN,
        //             comment1: comp[0].messenger1,
        //             comment2: comp[0].messenger2,
        //             OGRN: comp[0].OGRN,
        //             region: comp[0].region,
        //             city: comp[0].city,
        //             id: comp[0].id,
        //             leaderPhone: comp[0].leaderPhone,
        //             contract: comp[0].contract,
        //             BIK: comp[0].BIK,
        //             QRrs: comp[0].QRrs,
        //             QRstatic: comp[0].QRstatic,
        //             workPhone1: comp[0].workPhone1,
        //             workPhone2: comp[0].workPhone2,
        //             turnover: comp[0].turnover,
        //             rating: comp[0].rating,
        //             quantity: comp[0].quantity,
        //             rs: comp[0].rs,
        //             soc1: comp[0].soc1,
        //             soc2: comp[0].soc2,
        //             siteName: comp[0].siteName,
        //             bankName: comp[0].bankName,
        //
        //             companyOrder: comp.map(order => ({
        //                 city: order.city,
        //                 id: order.idBitrix,
        //                 createData: order.createData,
        //                 updateData: order.updateData,
        //                 moveData: order.moveData,
        //                 price: parseFloat(order.storePayment.replace(/[^\d.]/g, '')), // Оплачено магазину
        //                 amount: parseFloat(order.amount.replace(/[^\d.]/g, '')),   // всего пришло
        //
        //             })),
        //             sumAll: sumPayment,
        //         });
        //     });
        //     console.log('!!!!!!!!!!!res1', result)
        //
        //     result.sort((a, b) => {
        //         const completenessA = (a.contract !== '') * 2 + (a.QRstatic !== '') + (+(a.rs || a.QRrs ? 1 : 0));
        //         const completenessB = (b.contract !== '') * 2 + (b.QRstatic !== '') + (+(b.rs || b.QRrs ? 1 : 0));
        //
        //         if (completenessB !== completenessA) {
        //             return completenessB - completenessA;
        //         } else {
        //             return b.sumAll - a.sumAll;
        //         }
        //     });
        //
        //     console.log('result THE CARD', result)
        //
        //     // let cardOne = result;
        //
        //     let cardOne1 = result.filter(card => card.INN === lastWord);
        //     //let cardResult = cardOne1.filter(card => card.citybd === lastWord2)
        //     console.log("result", result)
        //     console.log("cardOne1", cardOne1)
        //     setCard(cardOne1)
        //     console.log('lastCard1111', cardOne1)
        //     if (cardOne1.length > 0) {
        //         console.log('lastCard2222', cardOne1)
        //         setName(cardOne1[0].companyName || '');
        //         setINN(cardOne1[0].INN || '');
        //         setOGRN(cardOne1[0].OGRN || '');
        //         setBIK(cardOne1[0].BIK || '');
        //         setContract(cardOne1[0].contract || '');
        //         setCity(cardOne1[0].city || '');
        //         setRegion(cardOne1[0].region || '');
        //         setLeaderPhone(cardOne1[0].leaderPhone || '');
        //         setWorkPhone1(cardOne1[0].workPhone1 || '');
        //         setWorkPhone2(cardOne1[0].workPhone2 || '');
        //         setMessenger1(cardOne1[0].comment1 || '');
        //         setMessenger2(cardOne1[0].comment2 || '');
        //         setSoc1(cardOne1[0].soc1 || '');
        //         setSoc2(cardOne1[0].soc2 || '');
        //         setQRrs(cardOne1[0].QRrs || '');
        //         setQRstatic(cardOne1[0].QRstatic || '');
        //         setBank(cardOne1[0].bankName || '');
        //         setRS(cardOne1[0].rs || '');
        //         setSite(cardOne1[0].siteName || '');
        //
        //         if (cardOne1.length > 0 && cardOne1[0].siteName) {
        //             try {
        //                 const jsonCheck = JSON.parse(cardOne1[0].siteName);
        //                 console.log('Card0', jsonCheck)
        //                 // const companyNameStartsWithFl = cardOne1[0].companyName.toLowerCase().startsWith('фл');
        //
        //                 // if (companyNameStartsWithFl) {
        //                 //     setChecked(prevData => ({
        //                 //             ...prevData,
        //                 //             flowers: false,
        //                 //             strawberry: true,
        //                 //             baloon: jsonCheck.baloon,
        //                 //             cakes: jsonCheck.cakes,
        //                 //         })
        //                 //     )
        //                 //
        //                 //     return
        //                 // }
        //
        //                 setChecked(prevData => ({
        //                         ...prevData,
        //                         flowers: jsonCheck.flowers,
        //                         strawberry: jsonCheck.strawberry,
        //                         balloon: jsonCheck.balloon,
        //                         cakes: jsonCheck.cakes,
        //                     })
        //                 )
        //
        //             } catch (error) {
        //                 console.error("Error parsing JSON:", error);
        //             }
        //         }
        //
        //     }
        //     console.log('lastCard', cardOne1)
        // }
        //
        // fetch()

        const fetch = async () => {
            let data = await getOne({
                name: lastWord
            });

            setCard(data)

            console.log('data THE CARD', data)

            setName(data[0].nameCompanyTitle || '');
            setINN(data[0].innCompany || '');
            setOGRN(data[0].ogRn || '');
            setBIK(data[0].bik || '');
            setContract(data[0].agreement || '');
            setCity(data[0].cities || '');

            setAdditionalCity(data[0].cities || '')

            // if (data[0].cities.length < 2 && data[0].cities.length > 0) {
            //     setCityMain(data[0].cities[0].nameCityTitle || '')
            //     setRegion(data[0].cities[0].region || '');
            // } else {
            //     setCities(data[0].cities)
            // }

            setLeaderPhone(data.leaderPhone || '');
            setWorkPhone1(data.workPhone1 || '');
            setWorkPhone2(data.workPhone2 || '');
            setMessenger1(data[0].commentariesMain || '');
            setMessenger2(data[0].commentariesFirst || '');
            setSoc1(data[0].siteCompanyMain || '');
            setSoc2(data[0].siteCompanyDop || '');
            setQRrs(data[0].qrRs || '');
            setQRstatic(data[0].qrStatic || '');
            setBank(data[0].bankName || '');
            setRS(data[0].rs || '');
            setSite(data[0].typeOfActivity || '');
        }

        fetch()

    }, [location, info, setId, setName, setINN, setCard, setChecked]);

    const normalizeINN = (inn) => {
        if (inn !== '') {
            return inn.replace(/\D/g, ''); // Remove non-numeric characters
        }
    }

    const transliterate = (text) => {
        const cyrillicToLatinMap = {
            а: 'a', б: 'b', в: 'v', г: 'g', д: 'd', е: 'e', ё: 'yo', ж: 'zh',
            з: 'z', и: 'i', й: 'y', к: 'k', л: 'l', м: 'm', н: 'n', о: 'o',
            п: 'p', р: 'r', с: 's', т: 't', у: 'u', ф: 'f', х: 'h', ц: 'ts',
            ч: 'ch', ш: 'sh', щ: 'sch', ь: '', ы: 'y', ъ: '', э: 'e', ю: 'yu',
            я: 'ya', А: 'A', Б: 'B', В: 'V', Г: 'G', Д: 'D', Е: 'E', Ё: 'YO',
            Ж: 'ZH', З: 'Z', И: 'I', Й: 'Y', К: 'K', Л: 'L', М: 'M', Н: 'N',
            О: 'O', П: 'P', Р: 'R', С: 'S', Т: 'T', У: 'U', Ф: 'F', Х: 'H',
            Ц: 'TS', Ч: 'CH', Ш: 'SH', Щ: 'SCH', Ь: '', Ы: 'Y', Ъ: '', Э: 'E',
            Ю: 'YU', Я: 'YA'
        };
        return text.split('').map(char => cyrillicToLatinMap[char] || char).join('');
    };

    const tokenSentCity = (e, aim) => {
        if (e.target.value.length > 3) {
            let url = "http://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
            let token = "80a7758e1c4d6412649c75b3f4e070057bef8234";
            let query = e.target.value;

            let options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({query: query})
            }

            fetch(url, options)
                .then(response => response.json())
                .then(result => {
                    console.log("result ЧИСТЫЙ", result)

                    const filteredSuggestions = result.suggestions.filter(suggestion =>
                        suggestion.data.city && suggestion.data.city.toLowerCase() === query?.toLowerCase() ||
                        suggestion.data.settlement && suggestion.data.settlement.toLowerCase() === query?.toLowerCase()
                    );

                    console.log("filteredSuggestions THE CREATE", filteredSuggestions)

                    const suggestions = filteredSuggestions.map((suggestion, index) => ({
                        postcode: suggestion.data.postal_code || "",
                        nameCityTitle: suggestion.data.city || suggestion.data.settlement,
                        countryTitle: suggestion.data.country || "",
                        regionTitle: suggestion.data.region_with_type || "",
                        area_with_typeTitle: suggestion.data.area_with_type || "",
                        geo_lat: suggestion.data.geo_lat || "",
                        geo_lon: suggestion.data.geo_lon || "",
                        // district: suggestion.data.city_district || ""
                    }));

                    setModalResults(suggestions);

                })
                .catch(error => console.log("error", error));
        }
    }

    const handleCityChange = (index, e) => {
        console.log('additionalCity', additionalCity)
        const {value} = e.target;
        const updatedCities = [...additionalCity]; // Создаем копию массива additionalCity
        updatedCities[index] = {...updatedCities[index], address_custom: value, disabled: false}; // Обновляем объект города по индексу
        setAdditionalCity(updatedCities); // Устанавливаем обновленный массив в состояние
    };

    const showChange = (event) => {
        event.stopPropagation()
        // Remove the "active" class from all NavLink elements
        const navLinks = document.querySelectorAll('.search_results .search_results__wrapper');
        navLinks.forEach(link => link.classList.remove('active'));

        // Add the "active" class to the clicked NavLink
        event.target.classList.add('active');
    }


    useEffect(() => {
        // console.log('formData ADDED CITY', formData)
        console.log('formData ADDED DOP CITY', additionalCity)
        // console.log('formData ADDED DOP PHONE', additionalPhone)
    }, [additionalCity,
        // additionalPhone
    ]);

    const arrangementAddress = (address, result) => {
        setAdditionalCity(prevState => {
            const createCity = {
                ...prevState,
                postcode: result.postcode || "",
                nameCityTitle: result.nameCityTitle || "",
                countryTitle: result.countryTitle || "",
                regionTitle: result.regionTitle || "",
                area_with_typeTitle: result.area_with_typeTitle || "",
                geo_lat: result.geo_lat || "",
                geo_lon: result.geo_lon || "",
                address_custom: `${result.nameCityTitle}, ${result.regionTitle}, ${result.area_with_typeTitle}, ${result.postcode}`
            };
            return [...prevState.slice(0, prevState.length - 1), createCity];
        });
        console.log('result suggestions', result);
    }


    let numUseEffects = 0
    useEffect(() => {
        numUseEffects += 1

        if (numUseEffects === 1) {
            setCities(prevCities => {
                const newCities = [...prevCities];
                const citiesToAdd = newCities.length;
                console.log('citiesToAdd', citiesToAdd)
                if (citiesToAdd > 1) {
                    for (let i = 0; i < citiesToAdd; i++) {
                        newCities.push({ name: `Доп. город ${newCities.length + 1}` });
                    }
                }
                return newCities;
            });
        }

        console.log('cities ADDCITIES, numUseEffects', cities, numUseEffects)
    }, []);

    const handleAddCity = () => {
        setAdditionalCity([...additionalCity, { nameCityTitle: '' }]);
    };

    const handleDeleteCity = (index) => {
        setAdditionalCity(prev => {
            const newData = [...prev];
            newData.splice(index, 1);
            return newData;
        });
    };

    const handleCityChangeAdded = (index, e) => {
        if (e && e.target) {
            const { value } = e.target;
            console.log('CityChange value', value)
            const updatedCities = [...additionalCity];
            updatedCities[index] = { nameCityTitle: value };
            setAdditionalCity(updatedCities);
        }
    };

    useEffect(() => {
        console.log('additionalCity THE CARD', additionalCity)
    }, [additionalCity]);

    const [openInput, setOpenInput] = useState([])

    useEffect(() => {
        console.log('openInput', openInput)
    }, [openInput]);

    switch (info.role.role) {
        case 'admin':
            return (
                <>
                    <header
                        className="header order__header"
                        // style={headerName == 'Заказ' ? {display: "flex"} : {display: "none"}}
                    >
                        <div className="header__left">
                            <div>
                                <Link to={`/`}
                                    // onClick={setRender}
                                >
                                    <img src={leftImg} className="logo_img" width="25px"
                                         height="25px"/>
                                </Link>
                            </div>
                        </div>
                        <div className="header__name"
                        >

                        </div>
                        <div className="header__right">
                            <div className="filter_"></div>
                        </div>
                    </header>
                    <div className="item_card"
                        // style={showCard ? {display: "block"} : {display: "none"}}
                    >
                        {card && card.map(card => {

                            return (
                                <div key={card.id}>
                                    <div className="city_header">{card.city}</div>
                                    <div className="share icon"
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             copyCurrentUrlToClipboard();
                                         }}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <div>Поделиться</div>
                                            <img src={shareImg} className="share_img" width="20px"
                                                 height="20px"/>
                                        </div>

                                        <div className="item_card__products" style={{display: "flex"}}>

                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{
                                                    display: checked.flowers ? "block" : "none"
                                                }}
                                            >
                                                <img src={shareFlowers} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </div>
                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{display: checked.strawberry ? "block" : "none"}}
                                            >
                                                <img src={shareStrawberry} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </div>
                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{display: checked.balloon ? "block" : "none"}}
                                            >
                                                <img src={shareBaloon} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </div>
                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{display: checked.cakes ? "block" : "none"}}
                                            >
                                                <img src={shareCake} className="share_img products" width="25px"
                                                     alt="QR"
                                                     height="25px"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="create_products" style={{display: dis ? "none" : "flex"}}>
                                        <div>
                                            <label htmlFor="flowers" className="create_products__label">
                                                <input
                                                    type="checkbox"
                                                    id="flowers"
                                                    className="create_checkbox"
                                                    onChange={() => handleCheckboxChange("flowers")}
                                                    checked={checked.flowers}
                                                />
                                                <img src={shareFlowers} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </label>
                                        </div>
                                        <div>
                                            <label htmlFor="strawberry" className="create_products__label">
                                                <input type="checkbox" id="strawberry" className="create_checkbox"
                                                       onChange={() => handleCheckboxChange("strawberry")}
                                                       checked={checked.strawberry}/>
                                                <img src={shareStrawberry} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </label>
                                        </div>
                                        <div>
                                            <label htmlFor="balloon" className="create_products__label">
                                                <input type="checkbox" id="balloon" className="create_checkbox"
                                                       onChange={() => handleCheckboxChange("balloon")}
                                                       checked={checked.balloon}/>
                                                <img src={shareBaloon} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </label>
                                        </div>
                                        <div>
                                            <label htmlFor="cake" className="create_products__label">
                                                <input type="checkbox" id="cake" className="create_checkbox"
                                                       onChange={() => handleCheckboxChange("cakes")}
                                                       checked={checked.cakes}/>
                                                <img src={shareCake} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="buttons_block">
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                                className="item_card__button"
                                        ><Link to={`/orders/${card.INN}`}>Заказы контрагента</Link>
                                        </button>
                                        <div className="center"></div>
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                                className="item_card__button"
                                        >
                                            <Link to={`/payments/${card.INN}`}>Деньги контрагента</Link>
                                        </button>
                                    </div>

                                    <div className="input_item">

                                        <div>Магазин</div>
                                        <input
                                            className="item_"
                                            name="name"
                                            type="text"
                                            value={name}
                                            onChange={handleSentName}
                                            disabled={dis}
                                            placeholder="Введите название магазина"
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div>ИНН</div>
                                        <input
                                            className={INN ? "item_" : "item_-empty"}
                                            // className="item_"
                                            id="inn"
                                            name="INN"
                                            value={normalizeINN(INN)}
                                            onChange={handleSentINN}
                                            readOnly={dis}
                                            placeholder="Введите ИНН"
                                            ref={inputRefs.textToCopyINNRef}
                                            required
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyINNRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>Р/С</div>
                                        <input className="item_" id="rs" name="rs" value={rs} onChange={handleSentRS}
                                               readOnly={dis}
                                               placeholder="Введите Р/С"
                                               ref={inputRefs.textToCopyRSRef}
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyRSRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>БИК</div>
                                        <input className="item_" id="bik" name="BIK" value={BIK}
                                               onChange={handleSentBIK} readOnly={dis}
                                               placeholder="Введите БИК"
                                               ref={inputRefs.textToCopyBIKRef}
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyBIKRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>ОГРН</div>
                                        <input className="item_" id="ogrn" name="OGRN" value={OGRN}
                                               onChange={handleSentOGRN}
                                               readOnly={dis} placeholder="Введите ОГРН"
                                               ref={inputRefs.textToCopyOGRNRef}
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyOGRNRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>Название банка</div>
                                        <input className="item_" name="bank" value={bank} onChange={handleSentBankName}
                                               disabled={dis}
                                               placeholder="Введите название банка"
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div>QR на Р/С</div>
                                        <input className="item_" name="QRrs" value={QRrs}
                                               onChange={handleSentQRrs} disabled={dis}
                                               placeholder="Введите QR на Р/С"/>
                                        <a href={QRrs} target="_blank" className="copy icon"
                                           style={QRrs ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    <div className="input_item">
                                        <div>QR статичный</div>
                                        <input className="item_" name="QRstatic" value={QRstatic}
                                               onChange={handleSentQRstatic} disabled={dis}
                                               placeholder="Введите QR статичный"/>
                                        <a href={QRstatic} target="_blank" className="copy icon"
                                           style={QRstatic ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    <div className="input_item">
                                        <div>Договор</div>
                                        <input className="item_" name="contract" value={contract}
                                               onChange={handleSentContract} disabled={dis}
                                               placeholder="Введите договор"/>
                                        <a href={contract} target="_blank" className="copy icon"
                                           style={contract ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    <div className="input_item">
                                        <div>Регион</div>
                                        <input className="item_" name="region" value={region}
                                               onChange={handleSentRegion} disabled={dis} placeholder="Введите регион"/>
                                    </div>
                                    <div className="input_item__cities">
                                        {/*<div className="input_item">*/}
                                        {/*    <div>Главный город</div>*/}
                                        {/*    <input className="item_" name="city" value={cityMain}*/}
                                        {/*           onChange={handleSentCity} disabled={dis}*/}
                                        {/*           placeholder="Введите город"/>*/}
                                        {/*</div>*/}
                                        <div className="click_plus">
                                            Нажмите на <b>+</b> чтобы добавить город
                                        </div>
                                        <button disabled={disAddCity ? true : false} className="add_city"
                                                onClick={() => {
                                                    handleAddCity();
                                                    setDisAddCity(true)
                                                    setOpenInput(prevState =>
                                                        [...prevState, false]
                                                    )
                                                }}>+
                                        </button>
                                        {/* Дополнительные города */}
                                        {additionalCity && additionalCity.map((city, index) => (
                                            <div key={index} className="input_item">
                                                <div>Город {index + 1} </div>
                                                <input
                                                    className="item_"
                                                    disabled={openInput[index]}
                                                    name={`city${index + 1}`}
                                                    value={city.nameCityTitle}
                                                    onChange={(e) => {
                                                        handleCityChange(index, e);
                                                        tokenSentCity(e);
                                                        setSearchedResult(true);
                                                    }}
                                                    placeholder={`Введите город ${index + 1}`}
                                                />
                                                <button className="delete_city" onClick={() => {
                                                    handleDeleteCity(index);
                                                }}>x
                                                </button>
                                            </div>
                                        ))}
                                        <div
                                            className="search_results"
                                            style={searchedResult ? {display: "block"} : {display: "none"}}
                                        >
                                            {modalResults && modalResults.map((result) => {
                                                // console.log("FRONT", result)
                                                if (result.postal_code !== null) {
                                                    // setSearchedResult(true)
                                                    return (
                                                        <div className="search_results__wrapper" key={result.id}
                                                             onClick={showChange}>
                                                            <div
                                                                className="search_results__item"
                                                                onClick={() => {
                                                                    setSearchedResult(false)
                                                                    const res = result.nameCityTitle + ', ' + result.regionTitle + ', ' +
                                                                        result.area_with_typeTitle + ', ' + result.postcode;
                                                                    arrangementAddress(res, result)
                                                                    setDisAddCity(false)
                                                                    setOpenInput(prevState => {
                                                                        // Создаем новый массив, в котором все элементы копируются из предыдущего состояния
                                                                        // Затем устанавливаем значение true для последнего элемента
                                                                        return prevState.map((item, index) => (index === prevState.length - 1 ? true : item));
                                                                    });
                                                                }}
                                                            >
                                                                <div>
                                                                    {`${result.nameCityTitle}, ${result.regionTitle}, 
                                                    ${result.area_with_typeTitle}, ${result.postcode}`}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    );
                                                } else {
                                                    return null; // Или другое действие, если city пустой
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div className="input_item"
                                        // style={workPhone1 != '' ? {display: "block"} : {display: "none"}}
                                    >
                                        <div className="input_phone">
                                            <div>Рабочий телефон 1</div>
                                            <div className="catalog__contacts">
                                                <div className="phone_links">
                                                    <div className="call icon">
                                                        <a href={`tel:${card.leaderPhone}`}>
                                                            <img src={phoneImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="wa icon">
                                                        <a href={`whatsapp://send?phone=${card.leaderPhone}`}>
                                                            <img src={waImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="telegram icon">
                                                        <a href={`https://t.me/${card.leaderPhone}`}>
                                                            <img src={tgImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="vk icon">
                                                        <a href={`viber://add?number=${card.leaderPhone && (card.leaderPhone).replace(/^\+/, '')}`}>
                                                            <img src={vbImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input className="item_" name="leaderPhone" value={leaderPhone}
                                               onChange={handleSentLeaderPhone} disabled={dis}
                                               placeholder="Рабочий телефон 1 (главный телефон)"
                                        />
                                    </div>
                                    <div className="input_item"
                                        // style={workPhone2 != '' ? {display: "block"} : {display: "none"}}
                                    >
                                        <div className="input_phone">
                                            <div>Рабочий телефон 2</div>
                                            <div className="catalog__contacts">
                                                <div className="phone_links">
                                                    <div className="call icon">
                                                        <a href={`tel:${card.workPhone1}`}>
                                                            <img src={phoneImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="wa icon">
                                                        <a href={`whatsapp://send?phone=${card.workPhone1}`}>
                                                            <img src={waImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="telegram icon">
                                                        <a href={`https://t.me/${card.workPhone1}`}>
                                                            <img src={tgImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="vk icon">
                                                        <a href={`viber://add?number=${card.workPhone1 && (card.workPhone1).replace(/^\+/, '')}`}>
                                                            <img src={vbImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input className="item_" name="workPhone1" value={workPhone1}
                                               onChange={handleSentWorkPhone1} disabled={dis}
                                               placeholder="Рабочий телефон 2 (доп телефон)"
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div className="input_phone">
                                            <div>Телефон руководителя</div>
                                            <div className="catalog__contacts">
                                                <div className="phone_links">
                                                    <div className="call icon">
                                                        <a href={`tel:${card.workPhone2}`}>
                                                            <img src={phoneImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="wa icon">
                                                        <a href={`whatsapp://send?phone=${card.workPhone2}`}>
                                                            <img src={waImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="telegram icon">
                                                        <a href={`https://t.me/${card.workPhone2}`}>
                                                            <img src={tgImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="vk icon">
                                                        <a href={`viber://add?number=${card.workPhone2 && (card.workPhone2).replace(/^\+/, '')}`}>
                                                            <img src={vbImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input className="item_" name="workPhone2" value={workPhone2}
                                               onChange={handleSentWorkPhone2} disabled={dis}
                                               placeholder="Телефон руководителя (доп. телефон)"
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div>Соцсеть 1</div>
                                        <input className="item_" name="soc1" value={soc1}
                                               onChange={handleSentSoc1} disabled={dis}
                                               placeholder="Введите ссылку"/>
                                        <a href={soc1} target="_blank" className="copy icon"
                                           style={soc1 ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    <div className="input_item">
                                        <div>Соцсеть 2</div>
                                        <input className="item_" name="soc2" value={soc2}
                                               onChange={handleSentSoc2} disabled={dis}
                                               placeholder="Введите ссылку"/>
                                        <a href={soc2} target="_blank" className="copy icon"
                                           style={soc2 ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    {/*<div className="input_item">*/}
                                    {/*    <div>Сайт</div>*/}
                                    {/*    <input className="item_" name="site" value={site}*/}
                                    {/*           onChange={handleSentSite} disabled={dis}*/}
                                    {/*           placeholder="Введите ссылку"*/}
                                    {/*    />*/}
                                    {/*    <a href={site} target="_blank" className="copy icon"*/}
                                    {/*       style={site ? {display: "block"} : {display: "none"}}*/}
                                    {/*    >*/}
                                    {/*        <img src={rightImg} className="logo_img" width="25px" height="25px"/>*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}
                                    <div className="input_item">
                                        <div>Комментарий 1</div>
                                        <textarea className="item_" name="com1" value={messenger1}
                                                  onChange={handleSentMessenger1} style={{height: "300px"}}
                                                  placeholder="Введите комментарий"
                                                  disabled={dis}
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div>Комментарий 2</div>
                                        <textarea className="item_" name="com2" value={messenger2}
                                                  onChange={handleSentMessenger2}
                                                  style={{height: "100px"}}
                                                  placeholder="Введите комментарий"
                                                  disabled={dis}
                                        />
                                    </div>
                                </div>
                            )
                        })}

                        <button
                            onClick={() => {
                                showSaveBtn(true);
                                setDis(false);
                            }}
                            style={!saveBtn ? {display: "block"} : {display: "none"}}
                            className="item_card__button"
                        >Редактировать
                        </button>
                        <button
                            onClick={() => {
                                showSaveBtn(false);
                                console.log('card.id', id)
                                makeData(id)
                            }}
                            style={saveBtn ? {display: "block"} : {display: "none"}}
                            className="item_card__button"
                        >
                            {/*<Link to={`/card/${INN}`}>*/}
                            Сохранить
                            {/*</Link>*/}
                        </button>
                    </div>
                </>
            );
        case 'buh':
            return (
                <>
                    <header
                        className="header order__header"
                        // style={headerName == 'Заказ' ? {display: "flex"} : {display: "none"}}
                    >
                        <div className="header__left">
                            <div>
                                <Link to={`/`}
                                    // onClick={setRender}
                                >
                                    <img src={leftImg} className="logo_img" width="25px"
                                         height="25px"/>
                                </Link>
                            </div>
                        </div>
                        <div className="header__name"
                        >

                        </div>
                        <div className="header__right">
                            <div className="filter_"></div>
                        </div>
                    </header>
                    <div className="item_card"
                        // style={showCard ? {display: "block"} : {display: "none"}}
                    >
                        {card && card.map(card => {

                            return (
                                <div key={card.id}>
                                    <div className="city_header">{card.city}</div>
                                    <div className="share icon"
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             copyCurrentUrlToClipboard();
                                         }}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <div>Поделиться</div>
                                            <img src={shareImg} className="share_img" width="20px"
                                                 height="20px"/>
                                        </div>

                                        <div className="item_card__products" style={{display: "flex"}}>
                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{
                                                    display: checked.flowers || (card.companyName.trim() !== '' && !/^фл/i.test(card.companyName)) ? "block" : "none"
                                                }}
                                            >
                                                <img src={shareFlowers} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </div>
                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{display: checked.strawberry ? "block" : "none"}}
                                            >
                                                <img src={shareStrawberry} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </div>
                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{display: checked.balloon ? "block" : "none"}}
                                            >
                                                <img src={shareBaloon} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </div>
                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{display: checked.cakes ? "block" : "none"}}
                                            >
                                                <img src={shareCake} className="share_img products" width="25px"
                                                     alt="QR"
                                                     height="25px"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="buttons_block">
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                                className="item_card__button"
                                        ><Link to={`/orders/${card.INN}`}>Заказы контрагента</Link>
                                        </button>
                                        <div className="center"></div>
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                                className="item_card__button"
                                        >
                                            <Link to={`/payments/${card.INN}`}>Деньги контрагента</Link>
                                        </button>
                                    </div>

                                    <div className="input_item">

                                        <div>Магазин</div>
                                        <input
                                            className="item_"
                                            name="name"
                                            type="text"
                                            value={name}
                                            onChange={handleSentName}
                                            disabled={dis}
                                            placeholder="Введите название магазина"
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div>ИНН</div>
                                        <input
                                            className={INN ? "item_" : "item_-empty"}
                                            // className="item_"
                                            id="inn"
                                            name="INN"
                                            value={INN}
                                            onChange={handleSentINN}
                                            readOnly={dis}
                                            placeholder="Введите ИНН"
                                            ref={inputRefs.textToCopyINNRef}
                                            required
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyINNRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>Р/С</div>
                                        <input className="item_" id="rs" name="rs" value={rs} onChange={handleSentRS}
                                               readOnly={dis}
                                               placeholder="Введите Р/С"
                                               ref={inputRefs.textToCopyRSRef}
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyRSRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>БИК</div>
                                        <input className="item_" id="bik" name="BIK" value={BIK}
                                               onChange={handleSentBIK} readOnly={dis}
                                               placeholder="Введите БИК"
                                               ref={inputRefs.textToCopyBIKRef}
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyBIKRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>ОГРН</div>
                                        <input className="item_" id="ogrn" name="OGRN" value={OGRN}
                                               onChange={handleSentOGRN}
                                               readOnly={dis} placeholder="Введите ОГРН"
                                               ref={inputRefs.textToCopyOGRNRef}
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyOGRNRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>Название банка</div>
                                        <input className="item_" name="bank" value={bank} onChange={handleSentBankName}
                                               disabled={dis}
                                               placeholder="Введите название банка"
                                        />
                                    </div>

                                    <div className="input_item">
                                        <div>QR на Р/С</div>
                                        <input className="item_" name="QRrs" value={QRrs}
                                               onChange={handleSentQRrs} disabled={dis}
                                               placeholder="Введите QR на Р/С"/>
                                        <a href={QRrs} target="_blank" className="copy icon"
                                           style={QRrs ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    <div className="input_item">
                                        <div>QR статичный</div>
                                        <input className="item_" name="QRstatic" value={QRstatic}
                                               onChange={handleSentQRstatic} disabled={dis}
                                               placeholder="Введите QR статичный"/>
                                        <a href={QRstatic} target="_blank" className="copy icon"
                                           style={QRstatic ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    <div className="input_item">
                                        <div>Договор</div>
                                        <input className="item_" name="contract" value={contract}
                                               onChange={handleSentContract} disabled={dis}
                                               placeholder="Введите договор"/>
                                        <a href={contract} target="_blank" className="copy icon"
                                           style={contract ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    <div className="create_cities">
                                        <div className="input_item">
                                            <div>Город</div>
                                            <input className="item_" name="city" value={city}
                                                   onChange={handleSentCity} disabled={dis} placeholder="Введите город"/>
                                            <div className="add_city" onClick={handleAddCity}>+</div>
                                            {/* Дополнительные города */}
                                            {additionalCity.map((cityB, index) => (
                                                <div key={index} className="input_item">
                                                    <div>Доп. город {index + 1}</div>
                                                    <input
                                                        className="item_"
                                                        name={`city${index + 1}`}
                                                        value={cityB.nameCityTitle}
                                                        onChange={(e) => handleCityChange(index, e)}
                                                        placeholder={`Введите доп. город ${index + 1}`}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>Регион</div>
                                        <input className="item_" name="region" value={region}
                                               onChange={handleSentRegion} disabled={dis} placeholder="Введите регион"/>
                                    </div>
                                    <div className="input_item"
                                        // style={workPhone1 != '' ? {display: "block"} : {display: "none"}}
                                    >
                                        <div className="input_phone">
                                            <div>Рабочий телефон 1</div>
                                            <div className="catalog__contacts">
                                                <div className="phone_links">
                                                    <div className="call icon">
                                                        <a href={`tel:${card.leaderPhone}`}>
                                                            <img src={phoneImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="wa icon">
                                                        <a href={`whatsapp://send?phone=${card.leaderPhone}`}>
                                                            <img src={waImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="telegram icon">
                                                        <a href={`https://t.me/${card.leaderPhone}`}>
                                                            <img src={tgImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="vk icon">
                                                        <a href={`viber://add?number=${card.leaderPhone && (card.leaderPhone).replace(/^\+/, '')}`}>
                                                            <img src={vbImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input className="item_" name="leaderPhone" value={leaderPhone}
                                               onChange={handleSentLeaderPhone} disabled={dis}
                                               placeholder="Рабочий телефон 1 (главный телефон)"
                                        />
                                    </div>
                                    <div className="input_item"
                                        // style={workPhone2 != '' ? {display: "block"} : {display: "none"}}
                                    >
                                        <div className="input_phone">
                                            <div>Рабочий телефон 2</div>
                                            <div className="catalog__contacts">
                                                <div className="phone_links">
                                                    <div className="call icon">
                                                        <a href={`tel:${card.workPhone1}`}>
                                                            <img src={phoneImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="wa icon">
                                                        <a href={`whatsapp://send?phone=${card.workPhone1}`}>
                                                            <img src={waImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="telegram icon">
                                                        <a href={`https://t.me/${card.workPhone1}`}>
                                                            <img src={tgImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="vk icon">
                                                        <a href={`viber://add?number=${card.workPhone1 && (card.workPhone1).replace(/^\+/, '')}`}>
                                                            <img src={vbImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input className="item_" name="workPhone1" value={workPhone1}
                                               onChange={handleSentWorkPhone1} disabled={dis}
                                               placeholder="Рабочий телефон 2 (доп телефон)"
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div className="input_phone">
                                            <div>Телефон руководителя</div>
                                            <div className="catalog__contacts">
                                                <div className="phone_links">
                                                    <div className="call icon">
                                                        <a href={`tel:${card.workPhone2}`}>
                                                            <img src={phoneImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="wa icon">
                                                        <a href={`whatsapp://send?phone=${card.workPhone2}`}>
                                                            <img src={waImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="telegram icon">
                                                        <a href={`https://t.me/${card.workPhone2}`}>
                                                            <img src={tgImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="vk icon">
                                                        <a href={`viber://add?number=${card.workPhone2 && (card.workPhone2).replace(/^\+/, '')}`}>
                                                            <img src={vbImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input className="item_" name="workPhone2" value={workPhone2}
                                               onChange={handleSentWorkPhone2} disabled={dis}
                                               placeholder="Телефон руководителя (доп. телефон)"
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div>Соцсеть 1</div>
                                        <input className="item_" name="soc1" value={soc1}
                                               onChange={handleSentSoc1} disabled={dis}
                                               placeholder="Введите ссылку"/>
                                        <a href={soc1} target="_blank" className="copy icon"
                                           style={soc1 ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    <div className="input_item">
                                        <div>Соцсеть 2</div>
                                        <input className="item_" name="soc2" value={soc2}
                                               onChange={handleSentSoc2} disabled={dis}
                                               placeholder="Введите ссылку"/>
                                        <a href={soc2} target="_blank" className="copy icon"
                                           style={soc2 ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    {/*<div className="input_item">*/}
                                    {/*    <div>Сайт</div>*/}
                                    {/*    <input className="item_" name="site" value={site}*/}
                                    {/*           onChange={handleSentSite} disabled={dis}*/}
                                    {/*           placeholder="Введите ссылку"*/}
                                    {/*    />*/}
                                    {/*    <a href={site} target="_blank" className="copy icon"*/}
                                    {/*       style={site ? {display: "block"} : {display: "none"}}*/}
                                    {/*    >*/}
                                    {/*        <img src={rightImg} className="logo_img" width="25px" height="25px"/>*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}
                                    <div className="input_item">
                                        <div>Комментарий 1</div>
                                        <textarea className="item_" name="com1" value={messenger1}
                                                  onChange={handleSentMessenger1} style={{height: "300px"}}
                                                  placeholder="Введите комментарий"
                                                  disabled={dis}
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div>Комментарий 2</div>
                                        <textarea className="item_" name="com2" value={messenger2}
                                                  onChange={handleSentMessenger2}
                                                  style={{height: "100px"}}
                                                  placeholder="Введите комментарий"
                                                  disabled={dis}
                                        />
                                    </div>
                                </div>
                            )
                        })}

                        {/*<button*/}
                        {/*    onClick={() => {*/}
                        {/*        showSaveBtn(true);*/}
                        {/*        setDis(false);*/}
                        {/*    }}*/}
                        {/*    style={!saveBtn ? {display: "block"} : {display: "none"}}*/}
                        {/*    className="item_card__button"*/}
                        {/*>Редактировать*/}
                        {/*</button>*/}
                        {/*<button*/}
                        {/*    onClick={() => {*/}
                        {/*        showSaveBtn(false);*/}
                        {/*        console.log('card.id', id)*/}
                        {/*        makeData(id)*/}
                        {/*    }}*/}
                        {/*    style={saveBtn ? {display: "block"} : {display: "none"}}*/}
                        {/*    className="item_card__button"*/}
                        {/*>*/}
                        {/*    /!*<Link to={`/card/${INN}`}>*!/*/}
                        {/*    Сохранить*/}
                        {/*    /!*</Link>*!/*/}
                        {/*</button>*/}
                    </div>
                </>
            );
        case 'manager':
            return (
                <>
                    <header
                        className="header order__header"
                        // style={headerName == 'Заказ' ? {display: "flex"} : {display: "none"}}
                    >
                        <div className="header__left">
                            <div>
                                <Link to={`/`}
                                    // onClick={setRender}
                                >
                                    <img src={leftImg} className="logo_img" width="25px"
                                         height="25px"/>
                                </Link>
                            </div>
                        </div>
                        <div className="header__name"
                        >

                        </div>
                        <div className="header__right">
                            <div className="filter_"></div>
                        </div>
                    </header>
                    <div className="item_card"
                        // style={showCard ? {display: "block"} : {display: "none"}}
                    >
                        {card && card.map(card => {

                            return (
                                <div key={card.id}>
                                    <div className="city_header">{card.city}</div>
                                    <div className="share icon"
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             copyCurrentUrlToClipboard();
                                         }}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <div>Поделиться</div>
                                            <img src={shareImg} className="share_img" width="20px"
                                                 height="20px"/>
                                        </div>

                                        <div className="item_card__products" style={{display: "flex"}}>
                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{
                                                    display: checked.flowers || (card.companyName.trim() !== '' && !/^фл/i.test(card.companyName)) ? "block" : "none"
                                                }}
                                            >
                                                <img src={shareFlowers} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </div>
                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{display: checked.strawberry ? "block" : "none"}}
                                            >
                                                <img src={shareStrawberry} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </div>
                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{display: checked.balloon ? "block" : "none"}}
                                            >
                                                <img src={shareBaloon} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </div>
                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{display: checked.cakes ? "block" : "none"}}
                                            >
                                                <img src={shareCake} className="share_img products" width="25px"
                                                     alt="QR"
                                                     height="25px"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="buttons_block">*/}
                                    {/*    <button onClick={(e) => {*/}
                                    {/*        e.stopPropagation();*/}
                                    {/*    }}*/}
                                    {/*            className="item_card__button"*/}
                                    {/*    ><Link to={`/orders/${card.INN}`}>Заказы контрагента</Link>*/}
                                    {/*    </button>*/}
                                    {/*    <div className="center"></div>*/}
                                    {/*    <button onClick={(e) => {*/}
                                    {/*        e.stopPropagation();*/}
                                    {/*    }}*/}
                                    {/*            className="item_card__button"*/}
                                    {/*    >*/}
                                    {/*        <Link to={`/payments/${card.INN}`}>Деньги контрагента</Link>*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}

                                    <div className="input_item">

                                        <div>Магазин</div>
                                        <input
                                            className="item_"
                                            name="name"
                                            type="text"
                                            value={name}
                                            onChange={handleSentName}
                                            disabled={dis}
                                            placeholder="Введите название магазина"
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div>ИНН</div>
                                        <input
                                            className={INN ? "item_" : "item_-empty"}
                                            // className="item_"
                                            id="inn"
                                            name="INN"
                                            value={INN}
                                            onChange={handleSentINN}
                                            readOnly={dis}
                                            placeholder="Введите ИНН"
                                            ref={inputRefs.textToCopyINNRef}
                                            required
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyINNRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>Р/С</div>
                                        <input className="item_" id="rs" name="rs" value={rs} onChange={handleSentRS}
                                               readOnly={dis}
                                               placeholder="Введите Р/С"
                                               ref={inputRefs.textToCopyRSRef}
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyRSRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>БИК</div>
                                        <input className="item_" id="bik" name="BIK" value={BIK}
                                               onChange={handleSentBIK} readOnly={dis}
                                               placeholder="Введите БИК"
                                               ref={inputRefs.textToCopyBIKRef}
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyBIKRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>ОГРН</div>
                                        <input className="item_" id="ogrn" name="OGRN" value={OGRN}
                                               onChange={handleSentOGRN}
                                               readOnly={dis} placeholder="Введите ОГРН"
                                               ref={inputRefs.textToCopyOGRNRef}
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyOGRNRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>Название банка</div>
                                        <input className="item_" name="bank" value={bank} onChange={handleSentBankName}
                                               disabled={dis}
                                               placeholder="Введите название банка"
                                        />
                                    </div>

                                    <div className="input_item">
                                        <div>QR на Р/С</div>
                                        <input className="item_" name="QRrs" value={QRrs}
                                               onChange={handleSentQRrs} disabled={dis}
                                               placeholder="Введите QR на Р/С"/>
                                        <a href={QRrs} target="_blank" className="copy icon"
                                           style={QRrs ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    <div className="input_item">
                                        <div>QR статичный</div>
                                        <input className="item_" name="QRstatic" value={QRstatic}
                                               onChange={handleSentQRstatic} disabled={dis}
                                               placeholder="Введите QR статичный"/>
                                        <a href={QRstatic} target="_blank" className="copy icon"
                                           style={QRstatic ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    {/*<div className="input_item">*/}
                                    {/*    <div>Договор</div>*/}
                                    {/*    <input className="item_" name="contract" value={contract}*/}
                                    {/*           onChange={handleSentContract} disabled={dis}*/}
                                    {/*           placeholder="Введите договор"/>*/}
                                    {/*    <a href={contract} target="_blank" className="copy icon"*/}
                                    {/*       style={contract ? {display: "block"} : {display: "none"}}*/}
                                    {/*    >*/}
                                    {/*        <img src={rightImg} className="logo_img" width="25px" height="25px"/>*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}
                                    <div className="input_item">
                                        <div>Город</div>
                                        <input className="item_" name="city" value={city}
                                               onChange={handleSentCity} disabled={dis} placeholder="Введите город"/>
                                    </div>
                                    <div className="input_item">
                                        <div>Регион</div>
                                        <input className="item_" name="region" value={region}
                                               onChange={handleSentRegion} disabled={dis} placeholder="Введите регион"/>
                                    </div>
                                    <div className="input_item"
                                        // style={workPhone1 != '' ? {display: "block"} : {display: "none"}}
                                    >
                                        <div className="input_phone">
                                            <div>Рабочий телефон 1</div>
                                            <div className="catalog__contacts">
                                                <div className="phone_links">
                                                    <div className="call icon">
                                                        <a href={`tel:${card.leaderPhone}`}>
                                                            <img src={phoneImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="wa icon">
                                                        <a href={`whatsapp://send?phone=${card.leaderPhone}`}>
                                                            <img src={waImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="telegram icon">
                                                        <a href={`https://t.me/${card.leaderPhone}`}>
                                                            <img src={tgImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="vk icon">
                                                        <a href={`viber://add?number=${card.leaderPhone && (card.leaderPhone).replace(/^\+/, '')}`}>
                                                            <img src={vbImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input className="item_" name="leaderPhone" value={leaderPhone}
                                               onChange={handleSentLeaderPhone} disabled={dis}
                                               placeholder="Рабочий телефон 1 (главный телефон)"
                                        />
                                    </div>
                                    <div className="input_item"
                                        // style={workPhone2 != '' ? {display: "block"} : {display: "none"}}
                                    >
                                        <div className="input_phone">
                                            <div>Рабочий телефон 2</div>
                                            <div className="catalog__contacts">
                                                <div className="phone_links">
                                                    <div className="call icon">
                                                        <a href={`tel:${card.workPhone1}`}>
                                                            <img src={phoneImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="wa icon">
                                                        <a href={`whatsapp://send?phone=${card.workPhone1}`}>
                                                            <img src={waImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="telegram icon">
                                                        <a href={`https://t.me/${card.workPhone1}`}>
                                                            <img src={tgImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="vk icon">
                                                        <a href={`viber://add?number=${card.workPhone1 && (card.workPhone1).replace(/^\+/, '')}`}>
                                                            <img src={vbImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input className="item_" name="workPhone1" value={workPhone1}
                                               onChange={handleSentWorkPhone1} disabled={dis}
                                               placeholder="Рабочий телефон 2 (доп телефон)"
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div className="input_phone">
                                            <div>Телефон руководителя</div>
                                            <div className="catalog__contacts">
                                                <div className="phone_links">
                                                    <div className="call icon">
                                                        <a href={`tel:${card.workPhone2}`}>
                                                            <img src={phoneImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="wa icon">
                                                        <a href={`whatsapp://send?phone=${card.workPhone2}`}>
                                                            <img src={waImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="telegram icon">
                                                        <a href={`https://t.me/${card.workPhone2}`}>
                                                            <img src={tgImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="vk icon">
                                                        <a href={`viber://add?number=${card.workPhone2 && (card.workPhone2).replace(/^\+/, '')}`}>
                                                            <img src={vbImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input className="item_" name="workPhone2" value={workPhone2}
                                               onChange={handleSentWorkPhone2} disabled={dis}
                                               placeholder="Телефон руководителя (доп. телефон)"
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div>Соцсеть 1</div>
                                        <input className="item_" name="soc1" value={soc1}
                                               onChange={handleSentSoc1} disabled={dis}
                                               placeholder="Введите ссылку"/>
                                        <a href={soc1} target="_blank" className="copy icon"
                                           style={soc1 ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    <div className="input_item">
                                        <div>Соцсеть 2</div>
                                        <input className="item_" name="soc2" value={soc2}
                                               onChange={handleSentSoc2} disabled={dis}
                                               placeholder="Введите ссылку"/>
                                        <a href={soc2} target="_blank" className="copy icon"
                                           style={soc2 ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    {/*<div className="input_item">*/}
                                    {/*    <div>Сайт</div>*/}
                                    {/*    <input className="item_" name="site" value={site}*/}
                                    {/*           onChange={handleSentSite} disabled={dis}*/}
                                    {/*           placeholder="Введите ссылку"*/}
                                    {/*    />*/}
                                    {/*    <a href={site} target="_blank" className="copy icon"*/}
                                    {/*       style={site ? {display: "block"} : {display: "none"}}*/}
                                    {/*    >*/}
                                    {/*        <img src={rightImg} className="logo_img" width="25px" height="25px"/>*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}
                                    <div className="input_item">
                                        <div>Комментарий 1</div>
                                        <textarea className="item_" name="com1" value={messenger1}
                                                  onChange={handleSentMessenger1} style={{height: "300px"}}
                                                  placeholder="Введите комментарий"
                                                  disabled={dis}
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div>Комментарий 2</div>
                                        <textarea className="item_" name="com2" value={messenger2}
                                                  onChange={handleSentMessenger2}
                                                  style={{height: "100px"}}
                                                  placeholder="Введите комментарий"
                                                  disabled={dis}
                                        />
                                    </div>
                                </div>
                            )
                        })}

                        {/*<button*/}
                        {/*    onClick={() => {*/}
                        {/*        showSaveBtn(true);*/}
                        {/*        setDis(false);*/}
                        {/*    }}*/}
                        {/*    style={!saveBtn ? {display: "block"} : {display: "none"}}*/}
                        {/*    className="item_card__button"*/}
                        {/*>Редактировать*/}
                        {/*</button>*/}
                        {/*<button*/}
                        {/*    onClick={() => {*/}
                        {/*        showSaveBtn(false);*/}
                        {/*        console.log('card.id', id)*/}
                        {/*        makeData(id)*/}
                        {/*    }}*/}
                        {/*    style={saveBtn ? {display: "block"} : {display: "none"}}*/}
                        {/*    className="item_card__button"*/}
                        {/*>*/}
                        {/*    /!*<Link to={`/card/${INN}`}>*!/*/}
                        {/*    Сохранить*/}
                        {/*    /!*</Link>*!/*/}
                        {/*</button>*/}
                    </div>
                </>
            );
        case 'shop':
            return (
                <>
                    <header
                        className="header order__header"
                        // style={headerName == 'Заказ' ? {display: "flex"} : {display: "none"}}
                    >
                        <div className="header__left">
                            <div>
                                <Link to={`/`}
                                    // onClick={setRender}
                                >
                                    <img src={leftImg} className="logo_img" width="25px"
                                         height="25px"/>
                                </Link>
                            </div>
                        </div>
                        <div className="header__name"
                        >

                        </div>
                        <div className="header__right">
                            <div className="filter_"></div>
                        </div>
                    </header>
                    <div className="item_card"
                        // style={showCard ? {display: "block"} : {display: "none"}}
                    >
                        {card && card.map(card => {

                            return (
                                <div key={card.id}>
                                    <div className="city_header">{card.city}</div>
                                    <div className="share icon"
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             copyCurrentUrlToClipboard();
                                         }}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <div>Поделиться</div>
                                            <img src={shareImg} className="share_img" width="20px"
                                                 height="20px"/>
                                        </div>

                                        <div className="item_card__products" style={{display: "flex"}}>
                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{
                                                    display: checked.flowers || (card.companyName.trim() !== '' && !/^фл/i.test(card.companyName)) ? "block" : "none"
                                                }}
                                            >
                                                <img src={shareFlowers} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </div>
                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{display: checked.strawberry ? "block" : "none"}}
                                            >
                                                <img src={shareStrawberry} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </div>
                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{display: checked.balloon ? "block" : "none"}}
                                            >
                                                <img src={shareBaloon} className="share_img products" width="25px"
                                                     height="25px"/>
                                            </div>
                                            <div
                                                className="icon"
                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                style={{display: checked.cakes ? "block" : "none"}}
                                            >
                                                <img src={shareCake} className="share_img products" width="25px"
                                                     alt="QR"
                                                     height="25px"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="buttons_block">
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                                className="item_card__button"
                                        ><Link to={`/orders/${card.INN}`}>Заказы контрагента</Link>
                                        </button>
                                        <div className="center"></div>
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                                className="item_card__button"
                                        >
                                            <Link to={`/payments/${card.INN}`}>Деньги контрагента</Link>
                                        </button>
                                    </div>

                                    <div className="input_item">

                                        <div>Магазин</div>
                                        <input
                                            className="item_"
                                            name="name"
                                            type="text"
                                            value={name}
                                            onChange={handleSentName}
                                            disabled={dis}
                                            placeholder="Введите название магазина"
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div>ИНН</div>
                                        <input
                                            className={INN ? "item_" : "item_-empty"}
                                            // className="item_"
                                            id="inn"
                                            name="INN"
                                            value={INN}
                                            onChange={handleSentINN}
                                            readOnly={dis}
                                            placeholder="Введите ИНН"
                                            ref={inputRefs.textToCopyINNRef}
                                            required
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyINNRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>Р/С</div>
                                        <input className="item_" id="rs" name="rs" value={rs} onChange={handleSentRS}
                                               readOnly={dis}
                                               placeholder="Введите Р/С"
                                               ref={inputRefs.textToCopyRSRef}
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyRSRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>БИК</div>
                                        <input className="item_" id="bik" name="BIK" value={BIK}
                                               onChange={handleSentBIK} readOnly={dis}
                                               placeholder="Введите БИК"
                                               ref={inputRefs.textToCopyBIKRef}
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyBIKRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>ОГРН</div>
                                        <input className="item_" id="ogrn" name="OGRN" value={OGRN}
                                               onChange={handleSentOGRN}
                                               readOnly={dis} placeholder="Введите ОГРН"
                                               ref={inputRefs.textToCopyOGRNRef}
                                        />
                                        <div className="copy icon"
                                             onClick={() => copyToClipboardOnClick('textToCopyOGRNRef')}>
                                            <img src={copyImg} className="logo_img" width="20px"
                                                 height="20px"/>
                                        </div>
                                    </div>
                                    <div className="input_item">
                                        <div>Название банка</div>
                                        <input className="item_" name="bank" value={bank} onChange={handleSentBankName}
                                               disabled={dis}
                                               placeholder="Введите название банка"
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div>Комментарий 1</div>
                                        <textarea className="item_" name="com1" value={messenger1}
                                                  onChange={handleSentMessenger1} style={{height: "300px"}}
                                                  placeholder="Введите комментарий"
                                                  disabled={dis}
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div>QR на Р/С</div>
                                        <input className="item_" name="QRrs" value={QRrs}
                                               onChange={handleSentQRrs} disabled={dis}
                                               placeholder="Введите QR на Р/С"/>
                                        <a href={QRrs} target="_blank" className="copy icon"
                                           style={QRrs ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    <div className="input_item">
                                        <div>QR статичный</div>
                                        <input className="item_" name="QRstatic" value={QRstatic}
                                               onChange={handleSentQRstatic} disabled={dis}
                                               placeholder="Введите QR статичный"/>
                                        <a href={QRstatic} target="_blank" className="copy icon"
                                           style={QRstatic ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    <div className="input_item">
                                        <div>Договор</div>
                                        <input className="item_" name="contract" value={contract}
                                               onChange={handleSentContract} disabled={dis}
                                               placeholder="Введите договор"/>
                                        <a href={contract} target="_blank" className="copy icon"
                                           style={contract ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    <div className="input_item">
                                        <div>Город</div>
                                        <input className="item_" name="city" value={city}
                                               onChange={handleSentCity} disabled={dis} placeholder="Введите город"/>
                                    </div>
                                    <div className="input_item">
                                        <div>Регион</div>
                                        <input className="item_" name="region" value={region}
                                               onChange={handleSentRegion} disabled={dis} placeholder="Введите регион"/>
                                    </div>
                                    <div className="input_item"
                                        // style={workPhone1 != '' ? {display: "block"} : {display: "none"}}
                                    >
                                        <div className="input_phone">
                                            <div>Рабочий телефон 1</div>
                                            <div className="catalog__contacts">
                                                <div className="phone_links">
                                                    <div className="call icon">
                                                        <a href={`tel:${card.leaderPhone}`}>
                                                            <img src={phoneImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="wa icon">
                                                        <a href={`whatsapp://send?phone=${card.leaderPhone}`}>
                                                            <img src={waImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="telegram icon">
                                                        <a href={`https://t.me/${card.leaderPhone}`}>
                                                            <img src={tgImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="vk icon">
                                                        <a href={`viber://add?number=${card.leaderPhone && (card.leaderPhone).replace(/^\+/, '')}`}>
                                                            <img src={vbImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input className="item_" name="leaderPhone" value={leaderPhone}
                                               onChange={handleSentLeaderPhone} disabled={dis}
                                               placeholder="Рабочий телефон 1 (главный телефон)"
                                        />
                                    </div>
                                    <div className="input_item"
                                        // style={workPhone2 != '' ? {display: "block"} : {display: "none"}}
                                    >
                                        <div className="input_phone">
                                            <div>Рабочий телефон 2</div>
                                            <div className="catalog__contacts">
                                                <div className="phone_links">
                                                    <div className="call icon">
                                                        <a href={`tel:${card.workPhone1}`}>
                                                            <img src={phoneImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="wa icon">
                                                        <a href={`whatsapp://send?phone=${card.workPhone1}`}>
                                                            <img src={waImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="telegram icon">
                                                        <a href={`https://t.me/${card.workPhone1}`}>
                                                            <img src={tgImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="vk icon">
                                                        <a href={`viber://add?number=${card.workPhone1 && (card.workPhone1).replace(/^\+/, '')}`}>
                                                            <img src={vbImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input className="item_" name="workPhone1" value={workPhone1}
                                               onChange={handleSentWorkPhone1} disabled={dis}
                                               placeholder="Рабочий телефон 2 (доп телефон)"
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div className="input_phone">
                                            <div>Телефон руководителя</div>
                                            <div className="catalog__contacts">
                                                <div className="phone_links">
                                                    <div className="call icon">
                                                        <a href={`tel:${card.workPhone2}`}>
                                                            <img src={phoneImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="wa icon">
                                                        <a href={`whatsapp://send?phone=${card.workPhone2}`}>
                                                            <img src={waImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="telegram icon">
                                                        <a href={`https://t.me/${card.workPhone2}`}>
                                                            <img src={tgImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                    <div className="vk icon">
                                                        <a href={`viber://add?number=${card.workPhone2 && (card.workPhone2).replace(/^\+/, '')}`}>
                                                            <img src={vbImg} className="logo_img" width="25px"
                                                                 height="25px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input className="item_" name="workPhone2" value={workPhone2}
                                               onChange={handleSentWorkPhone2} disabled={dis}
                                               placeholder="Телефон руководителя (доп. телефон)"
                                        />
                                    </div>
                                    <div className="input_item">
                                        <div>Соцсеть 1</div>
                                        <input className="item_" name="soc1" value={soc1}
                                               onChange={handleSentSoc1} disabled={dis}
                                               placeholder="Введите ссылку"/>
                                        <a href={soc1} target="_blank" className="copy icon"
                                           style={soc1 ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    <div className="input_item">
                                        <div>Соцсеть 2</div>
                                        <input className="item_" name="soc2" value={soc2}
                                               onChange={handleSentSoc2} disabled={dis}
                                               placeholder="Введите ссылку"/>
                                        <a href={soc2} target="_blank" className="copy icon"
                                           style={soc2 ? {display: "block"} : {display: "none"}}
                                        >
                                            <img src={rightImg} className="logo_img" width="25px" height="25px"/>
                                        </a>
                                    </div>
                                    {/*<div className="input_item">*/}
                                    {/*    <div>Сайт</div>*/}
                                    {/*    <input className="item_" name="site" value={site}*/}
                                    {/*           onChange={handleSentSite} disabled={dis}*/}
                                    {/*           placeholder="Введите ссылку"*/}
                                    {/*    />*/}
                                    {/*    <a href={site} target="_blank" className="copy icon"*/}
                                    {/*       style={site ? {display: "block"} : {display: "none"}}*/}
                                    {/*    >*/}
                                    {/*        <img src={rightImg} className="logo_img" width="25px" height="25px"/>*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}

                                    <div className="input_item">
                                        <div>Комментарий 2</div>
                                        <textarea className="item_" name="com2" value={messenger2}
                                                  onChange={handleSentMessenger2}
                                                  style={{height: "100px"}}
                                                  placeholder="Введите комментарий"
                                                  disabled={dis}
                                        />
                                    </div>
                                </div>
                            )
                        })}

                        <button
                            onClick={() => {
                                showSaveBtn(true);
                                setDis(false);
                            }}
                            style={!saveBtn ? {display: "block"} : {display: "none"}}
                            className="item_card__button"
                        >Редактировать
                        </button>
                        <button
                            onClick={() => {
                                showSaveBtn(false);
                                console.log('card.id', id)
                                makeData(id)
                            }}
                            style={saveBtn ? {display: "block"} : {display: "none"}}
                            className="item_card__button"
                        >
                            {/*<Link to={`/card/${INN}`}>*/}
                            Сохранить
                            {/*</Link>*/}
                        </button>
                    </div>
                </>
            );
    }
};

export {TheCard};
import {$host} from "./index";

export const getOrderShopBD = async () => {
    const {data} = await $host.post('api/orderShop/getOrderShopBD')
    return data
}

export const createOrderShopBD = async () => {
    const {data} = await $host.post('api/orderShop/createOrderShopBDMain')
    return data
}

export const getOrderShopMainBD = async () => {
    const {data} = await $host.post('api/orderShop/getOrderShopMainBD')
    return data
}

export const getCitySearch = async (city) => {
    const {data} = await $host.post('api/orderShop/getCitySearch', {city})
    return data
}
export const getPars = async (url) => {
    const {data} = await $host.post('/api/orderShop/getPars', {url});
    return data;
};





import React, {useEffect, useState} from 'react';
import phoneImg from "../img/phone.svg";
import waImg from "../img/whatsapp.svg";
import tgImg from "../img/telegram.svg";
import vbImg from "../img/viber.svg";
import {Link} from "react-router-dom";
import leftImg from "../img/left_arrow.svg";

const ThePayCard = ({info, headerName, changeMenu}) => {
    const [url, setUrl] = useState('');
    //
    // const [all, setAll] = useState(true);
    // const [process, setProcess] = useState(false);
    // const [finish, setFinish] = useState(false);

    // const allClick = () => {
    //     setAll(true);
    //     setProcess(false);
    //     setFinish(false);
    // }
    //
    // const processClick = () => {
    //     setAll(false);
    //     setProcess(true);
    //     setFinish(false);
    // }
    //
    // const finishClick = () => {
    //     setAll(false);
    //     setProcess(false);
    //     setFinish(true);
    // }

    useEffect(() => {
        let url = window.location.href;
        let parts = url.split('/');
        let lastWord = parts[parts.length - 1];
        let INN = parseInt(lastWord);
        let result = info.contractor[INN];
        // setCard(result);
        setUrl(lastWord)
        console.log('result', result);
        console.log('url', url);
    }, [info]);




    return (
        <div>
            <header
                className="header order__header"
                // style={headerName == 'Заказ' ? {display: "flex"} : {display: "none"}}
            >
                <div className="header__left">
                    <div>
                        <Link to={`/payments/${url}`}>
                            <img src={leftImg} className="logo_img" width="25px"
                                 height="25px"/>
                        </Link>
                    </div>
                </div>
                <div className="header__name"
                >

                </div>
                <div className="header__right">

                </div>
            </header>
            <div className="orders pay">
                <div className="center">
                    <div className="input_item">
                        <div>Период выплаты</div>
                        <div className="item_">01.02.2024 - 08.02.2024 (включительно)</div>
                    </div>
                    <div className="input_item">
                        <div>Статус (берем по API)</div>
                        <div className="item_">Отправлен на исполнение</div>
                    </div>
                    <div className="input_item">
                        <div>Начисление руб.</div>
                        <div className="item_"><span>1400</span>р.</div>
                    </div>
                    <div className="input_item">
                        <div>Платежка</div>
                        <div className="item_">pdf</div>
                    </div>
                    <div className="input_item">
                        <div>Описание выплаты</div>
                        <div className="item_">Оплата по договору №223 от 4.01.2024 за период 01.02.2024
                            по 08.02.2024 . НДС не облагается
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {ThePayCard};
import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import leftImg from "../img/left_arrow.svg";
import {DateTime} from "luxon";
import {callGetDayReport, getBitrix, getCompanyOrdersByDatePeriod, getRating, sendRating} from "../http/ordersAPI";
import preloadImg from "../img/tube-spinner.svg";
import {countAdvBDNotification, dataFromNotifications} from "../http/notificationAPI";
import {getUser} from "../http/userAPI";
import {clearBd, postBd, updateCompanyLive, createComponyWordPress} from "../http/cityAPI";


const TheDealsStatistics = ({show, info}) => {
    const [preloader, setPreloader] = useState(false)
    const [sortOption, setSortOption] = useState("");
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dealsStatistics, setDealsStatistics] = useState([])
    const [advValues, setAdvValues] = useState({});
    const [profitValues, setProfitValues] = useState([0]);
    const [incomeValues, setIncomeValues] = useState([]);
    const [profitAll, setProfitAll] = useState(0)
    const [advAll, setAdvAll] = useState(0)
    const [incomeAll, setIncomeAll] = useState(0)
    const [allTdSum, setAllTdSum] = useState(0)
    const [failedTdSum, setFailedTdSum] = useState(0)
    const [winTdSum, setWinTdSum] = useState(0)
    const [workTdSum, setWorkTdSum] = useState(0)
    const [dataToNotif, setDataToNotif] = useState(null)
    const [linkBtx, setLinkBtx] = useState('')
    const [showTable,setShowTable] = useState(false)
    const [periodManagers, setPeriodManagers] = useState([])
    const [noTableData, setNoTableData] = useState(false)
    const [users, setUsers] = useState([])
    const [middleConv, setMiddleConv] = useState(null)
    const [managersInfo, setManagersInfo] = useState([])
    const [hoveredItem, setHoveredItem] = useState(null);
    const [formData, setFormData] = useState({
        conversionRate: {
            weight: 0.5, base: 30, norm: 40, goal: 55
        },
        successfulDeals: {
            weight: 0.3, base: 7, norm: 15, goal: 27
        },
        averageRevenue: {
            weight: 0.2, base: 700, norm: 1200, goal: 2700
        },
    });

    // const [formData, setFormData] = useState(null)

    useEffect(() => {
        // setPreloader(true)
        const fetchData = async () => {
            try {
                setAdvAll(0)

                let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                let now = DateTime.now().setZone(timeZone);
                let firstMonthDate = now.startOf('month').toFormat('yyyy-MM-dd');
                let currentDate = now.toISODate();

                setStartDate(firstMonthDate);
                setEndDate(currentDate);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
            // setPreloader(false)
        };

        fetchData();
    }, []);

    // useEffect(() => {
    //     // console.log('incomeValues values', incomeValues)
    //     // console.log('advValues values', advValues)
    //     // console.log('profitValues values', profitValues)
    //     // console.log('hoveredItem values', hoveredItem)
    //     console.log('formData values', formData)
    // }, [
    //     // incomeValues, advValues, profitValues, hoveredItem,
    //     formData
    // ]);

    const getDataToTable = async () => {
        setPreloader(true)
        setAdvValues([])
        setAdvAll(0)
        setHoveredItem(null)

        const ordersByDate = await getCompanyOrdersByDatePeriod(startDate, endDate, sortOption, formData)

        setDealsStatistics(Object.entries(ordersByDate))

        console.log('ordersByDate', ordersByDate)

        if (Object.entries(ordersByDate).length === 0) {
            setNoTableData(true)
        }

        const incomes = Object.entries(ordersByDate)
            .sort((a, b) => new Date(b[0]) - new Date(a[0]))
            .map((item, index) => ({
                dateBitrix: item[0],
                incomeValue: item[1].orders.reduce((total, orderItem) => {
                    if ((orderItem.status === null && orderItem.sentSum !== '0' && orderItem.sentSum !== null && orderItem.sentSum !== '' && orderItem.sentSum !== undefined)
                        ||
                        (orderItem.status === null && orderItem.sum !== '0' && orderItem.sum !== null && orderItem.sum !== '' && orderItem.sum !== undefined)
                    ) {
                        let sum = Math.round(Number(orderItem.sum));
                        let sentSum = Math.round(Number(orderItem.sentSum));
                        if (sum === 0 || sentSum === 0) {
                            return total; // Return current total without adding anything if either sum or sentSum is 0
                        } else {
                            return total + (sum - sentSum);
                        }
                    }
                    return total; // Return current total if conditions are not met
                }, 0)
            }));

        // const incomes = Object.entries(ordersByDate)
        //     .sort((a, b) => new Date(b[0]) - new Date(a[0]))
        //     .map((item, index) => ({
        //         dateBitrix: item[0],
        //         incomeValue: item[1].orders
        //             .filter(item => item.status === null &&
        //                 item.sentSum !== undefined && item.sentSum !== '' && item.sentSum !== null &&
        //                 item.sum !== undefined && item.sum !== '' && item.sum !== null &&
        //                 item.sum !== "0" && item.sentSum !== "0")
        //             .reduce((total, item) => {
        //                 let sum = Math.round(Number(item.sum));
        //                 let sentSum = Math.round(Number(item.sentSum));
        //                 if (sum === 0 || sentSum === 0) {
        //                     return total; // Return current total without adding anything if either sum or sentSum is 0
        //                 } else {
        //                     return total + (sum - sentSum);
        //                 }
        //             }, 0)
        //     }));

        setIncomeValues(incomes);

        setIncomeAll(incomes.reduce((total, item) => total + item.incomeValue, 0));


        const datas = await dataFromNotifications()
        const data = datas.filter(item => item.link === sortOption);

        let advs = []
        let profits = []

        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                advs[data[i].dateBitrix] = {
                    dateBitrix: data[i].dateBitrix,
                    currentAdv: Number(data[i].countAdv),
                    link: sortOption
                };
            }

            setAdvValues(advs);

            const filteredAdvs = Object.values(advs).filter(item => {
                const itemDate = new Date(item?.dateBitrix);
                const startDateObj = new Date(startDate);
                const endDateObj = new Date(endDate);

                return itemDate >= startDateObj && itemDate <= endDateObj;
            });

            const totalCountAdv = filteredAdvs.reduce((total, item) => total + item.currentAdv, 0);

            setAdvAll(totalCountAdv);

            let filteredAdvsFull = []

            for (let i = 0; i < filteredAdvs.length; i++) {
                filteredAdvsFull[filteredAdvs[i].dateBitrix] = {
                    dateBitrix: filteredAdvs[i].dateBitrix,
                    currentAdv: Number(filteredAdvs[i].currentAdv),
                    link: sortOption
                };
            }

            for (const adv of Object.entries(filteredAdvsFull)) {
                const income = incomes.find(income => income?.dateBitrix === adv[0]);
                if (income) {
                    profits[adv[0]] = {
                        dateBitrix: adv[0],
                        countAdv: Number(income.incomeValue) - Number(adv[1].currentAdv)
                    }
                    setProfitValues(profits);
                }
            }
        } else {
            setAdvValues([]);
            setProfitValues([]);
        }


        setProfitAll(Object.values(profits).reduce((total, item) => total + item.countAdv, 0));

        let allSumm = Object.entries(ordersByDate).reduce((total, item) => {
            return total + item[1].orders.length;
        }, 0);

        setAllTdSum(allSumm)

        let winSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
            let filteredOrders = value.orders.filter(order => order.status === null);
            return total + filteredOrders.length;
        }, 0);

        setWinTdSum(winSumm)

        let failedSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
            let filteredOrders = value.orders.filter(order => order.status === 'failed');
            return total + filteredOrders.length;
        }, 0);

        setFailedTdSum(failedSumm)

        let workSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
            let filteredOrders = value.orders.filter(order => order.status === 'work');
            return total + filteredOrders.length;
        }, 0);

        setWorkTdSum(workSumm)

        let middleConversion = `${(winSumm * 100 / allSumm).toFixed(2)}%`

        setMiddleConv(middleConversion)

        let orderByDateArr = Object.entries(ordersByDate)

        let arr = []
        for (let i = 0; i < orderByDateArr.length; i++) {
            arr = arr.concat(Object.entries(orderByDateArr[i][1].managers));
        }

        let managers = {};

        for (let i = 0; i < arr.length; i++) {
            let key = arr[i][0];
            let orderCount = arr[i][1].orderCount;
            let winCount = arr[i][1].status.win;
            let failedCount = arr[i][1].status.failed;
            let incomCount = arr[i][1].status.incom;
            let workCount = arr[i][1].status.work;
            let ratingCount = arr[i][1].status.rating;


            if (managers[key]) {
                managers[key].orderCount += orderCount;
                managers[key].winCount += winCount;
                managers[key].failedCount += failedCount;
                managers[key].incomCount += incomCount;
                managers[key].workCount += workCount;
                managers[key].conversion = `${(managers[key].winCount * 100 / managers[key].orderCount).toFixed(2)}%`;
                let middleCash = (managers[key].incomCount / managers[key].winCount).toFixed(0);
                managers[key].middleCashCount = isNaN(middleCash) ? '0' : middleCash;
                managers[key].ratingCount += ratingCount
            } else {
                managers[key] = {
                    orderCount: orderCount,
                    winCount: winCount,
                    failedCount: failedCount,
                    incomCount: incomCount,
                    workCount: workCount,
                    conversion: `${(winCount * 100 / orderCount).toFixed(2)}%`,
                    middleCashCount: (incomCount / winCount).toFixed(0),
                    ratingCount: ratingCount,
                };
            }

            // console.log(`ratingCount_${i}`, ratingCount)
        }

        let managersArray = Object.keys(managers).map(key => ({
            name: key,
            orderCount: managers[key].orderCount,
            winCount: managers[key].winCount,
            failedCount: managers[key].failedCount,
            incomCount: managers[key].incomCount,
            workCount: managers[key].workCount,
            conversion: managers[key].conversion,
            middleCashCount: managers[key].middleCashCount,
            ratingCount: (managers[key].ratingCount).toFixed(2),
        }));

        managersArray.sort((a, b) => b.ratingCount - a.ratingCount);

        setManagersInfo(managersArray)

        let sortedManagerNames = managersArray.map(manager => manager.name);

        setPeriodManagers(sortedManagerNames)

        setNoTableData(false)
        setPreloader(false)
    }

    useEffect(() => {
        const fetch = async () => {
            setPreloader(true)
            setAdvAll(0)
            setAdvValues([])
            setHoveredItem(null)

            getRating(sortOption).then(response => {
                // console.log('response', response?.params[0]);
                if (response?.params[0]) {
                    // setFormData(prev => ({
                    //     ...prev,  // Maintain the previous state
                    //     conversionRate: {
                    //         ...prev.conversionRate,  // Maintain the nested structure
                    //         ...response.params[0].conversionRate  // Update specific nested properties
                    //     },
                    //     successfulDeals: {
                    //         ...prev.successfulDeals,
                    //         ...response.params[0].successfulDeals
                    //     },
                    //     averageRevenue: {
                    //         ...prev.averageRevenue,
                    //         ...response.params[0].averageRevenue
                    //     }
                    // }));
                    setFormData(response?.params[0])
                }
            });

            let users = await getUser();
            setUsers(users)

            if (sortOption === '') {
                setSortOption("https://miamor.bitrix24.ru/")
            }

            if (startDate && endDate && sortOption) {
                // console.log('ordersByDate sortOption', sortOption)
                const ordersByDate = await getCompanyOrdersByDatePeriod(startDate, endDate, sortOption, formData)

                console.log('ordersByDate', ordersByDate)

                setDealsStatistics(Object.entries(ordersByDate))

                if (Object.entries(ordersByDate).length === 0) {
                    setNoTableData(true)
                }

                const incomes = Object.entries(ordersByDate)
                    .sort((a, b) => new Date(b[0]) - new Date(a[0]))
                    .map((item, index) => ({
                        dateBitrix: item[0],
                        incomeValue: item[1].orders.reduce((total, orderItem) => {
                            if ((orderItem.status === null && orderItem.sentSum !== '0' && orderItem.sentSum !== null && orderItem.sentSum !== '' && orderItem.sentSum !== undefined)
                                ||
                                (orderItem.status === null && orderItem.sum !== '0' && orderItem.sum !== null && orderItem.sum !== '' && orderItem.sum !== undefined)
                            ) {
                                let sum = Math.round(Number(orderItem.sum));
                                let sentSum = Math.round(Number(orderItem.sentSum));
                                if (sum === 0 || sentSum === 0) {
                                    return total; // Return current total without adding anything if either sum or sentSum is 0
                                } else {
                                    return total + (sum - sentSum);
                                }
                            }
                            return total; // Return current total if conditions are not met
                        }, 0)
                    }));

                setIncomeValues(incomes);

                setIncomeAll(incomes.reduce((total, item) => total + item.incomeValue, 0));


                const datas = await dataFromNotifications()
                const data = datas.filter(item => item.link === sortOption);

                let advs = []
                let profits = []

                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        advs[data[i].dateBitrix] = {
                            dateBitrix: data[i].dateBitrix,
                            currentAdv: Number(data[i].countAdv),
                            link: sortOption
                        };
                    }
                    setAdvValues(advs);

                    const filteredAdvs = Object.values(advs).filter(item => {
                        const itemDate = new Date(item?.dateBitrix);
                        const startDateObj = new Date(startDate);
                        const endDateObj = new Date(endDate);

                        return itemDate >= startDateObj && itemDate <= endDateObj;
                    });


                    const totalCountAdv = filteredAdvs.reduce((total, item) => total + item.currentAdv, 0);

                    setAdvAll(totalCountAdv);

                    let filteredAdvsFull = []

                    for (let i = 0; i < filteredAdvs.length; i++) {
                        filteredAdvsFull[filteredAdvs[i].dateBitrix] = {
                            dateBitrix: filteredAdvs[i].dateBitrix,
                            currentAdv: Number(filteredAdvs[i].currentAdv),
                            link: sortOption
                        };
                    }

                    for (const adv of Object.entries(filteredAdvsFull)) {
                        const income = incomes.find(income => income?.dateBitrix === adv[0]);
                        if (income) {
                            profits[adv[0]] = {
                                dateBitrix: adv[0],
                                countAdv: Number(income.incomeValue) - Number(adv[1].currentAdv)
                            }
                            setProfitValues(profits);
                        }
                    }

                } else {
                    setAdvValues([]);
                    setProfitValues([]);
                }


                setProfitAll(Object.values(profits).reduce((total, item) => total + item.countAdv, 0));

                let allSumm = Object.entries(ordersByDate).reduce((total, item) => {
                    return total + item[1].orders.length;
                }, 0);

                setAllTdSum(allSumm)

                let winSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
                    let filteredOrders = value.orders.filter(order => order.status === null);
                    return total + filteredOrders.length;
                }, 0);

                setWinTdSum(winSumm)

                let failedSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
                    let filteredOrders = value.orders.filter(order => order.status === 'failed');
                    return total + filteredOrders.length;
                }, 0);

                setFailedTdSum(failedSumm)

                let workSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
                    let filteredOrders = value.orders.filter(order => order.status === 'work');
                    return total + filteredOrders.length;
                }, 0);

                setWorkTdSum(workSumm)

                let middleConversion = `${(winSumm * 100 / allSumm).toFixed(2)}%`

                setMiddleConv(middleConversion)

                let orderByDateArr = Object.entries(ordersByDate)

                let arr = []
                for (let i = 0; i < orderByDateArr.length; i++) {
                    arr = arr.concat(Object.entries(orderByDateArr[i][1].managers));
                }

                let managers = {};

                for (let i = 0; i < arr.length; i++) {
                    let key = arr[i][0];
                    let orderCount = arr[i][1].orderCount;
                    let winCount = arr[i][1].status.win;
                    let failedCount = arr[i][1].status.failed;
                    let incomCount = arr[i][1].status.incom;
                    let workCount = arr[i][1].status.work;
                    let ratingCount = arr[i][1].status.rating;


                    if (managers[key]) {
                        managers[key].orderCount += orderCount;
                        managers[key].winCount += winCount;
                        managers[key].failedCount += failedCount;
                        managers[key].incomCount += incomCount;
                        managers[key].workCount += workCount;
                        managers[key].conversion = `${(managers[key].winCount * 100 / managers[key].orderCount).toFixed(2)}%`;
                        let middleCash = (managers[key].incomCount / managers[key].winCount).toFixed(0);
                        managers[key].middleCashCount = isNaN(middleCash) ? '0' : middleCash;
                        managers[key].ratingCount += ratingCount
                    } else {
                        managers[key] = {
                            orderCount: orderCount,
                            winCount: winCount,
                            failedCount: failedCount,
                            incomCount: incomCount,
                            workCount: workCount,
                            conversion: `${(winCount * 100 / orderCount).toFixed(2)}%`,
                            middleCashCount: (incomCount / winCount).toFixed(0),
                            ratingCount: ratingCount,
                        };
                    }

                    // console.log(`ratingCount_${i}`, ratingCount)
                }

                let managersArray = Object.keys(managers).map(key => ({
                    name: key,
                    orderCount: managers[key].orderCount,
                    winCount: managers[key].winCount,
                    failedCount: managers[key].failedCount,
                    incomCount: managers[key].incomCount,
                    workCount: managers[key].workCount,
                    conversion: managers[key].conversion,
                    middleCashCount: managers[key].middleCashCount,
                    ratingCount: (managers[key].ratingCount).toFixed(2),
                }));

                managersArray.sort((a, b) => b.ratingCount - a.ratingCount);

                setManagersInfo(managersArray)

                let sortedManagerNames = managersArray.map(manager => manager.name);

                setPeriodManagers(sortedManagerNames)

                setPreloader(false)
                setNoTableData(false)
            }
        }
        fetch()
    }, [sortOption]);


    useEffect(() => {
        if (dataToNotif !== null) {
            countAdvBDNotification(dataToNotif)
                .then(data => {
                })
                .catch(error => {
                    console.error('Ошибка при редактировании расходов по рекламе:', error);
                });
        }
    }, [dataToNotif]);


    const handleInputChange = (e, index, dateSent) => {
        let advs = { ...advValues }; // Assuming advValues is an object

        let inputValue = e.target.value;
        if (/^\d*$/.test(inputValue)) {
            advs[dateSent] = {
                dateBitrix: dateSent,
                currentAdv: Number(inputValue),
                status: 'countAdv',
                link: linkBtx // Assuming linkBtx is defined in your context
            };

            setAdvValues(advs);
            // console.log('Updated advs:', advs);

            const data = {
                currentAdv: Number(inputValue),
                status: 'countAdv',
                dateBitrix: dateSent,
                link: linkBtx
            };

            setDataToNotif(data)
        }

        const filteredAdvs = Object.values(advs).filter(item => {
            const itemDate = new Date(item?.dateBitrix);
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);

            return itemDate >= startDateObj && itemDate <= endDateObj;
        });

        const totalCountAdv = filteredAdvs.reduce((total, item) => total + item.currentAdv, 0);


        setAdvAll(totalCountAdv);

        let profits = []
        let advsArr = Object.values(advs)

        let filteredAdvsFull = []

        for (let i = 0; i < advsArr.length; i++) {
            filteredAdvsFull[advsArr[i].dateBitrix] = {
                dateBitrix: advsArr[i].dateBitrix,
                currentAdv: Number(advsArr[i].currentAdv),
                link: sortOption
            };
        }

        for (const adv of Object.entries(filteredAdvsFull)) {
            const income = incomeValues.find(income => income?.dateBitrix === adv[0]);
            if (income) {
                profits[adv[0]] = {
                    dateBitrix: adv[0],
                    countAdv: Number(income.incomeValue) - Number(adv[1].currentAdv)
                }
                setProfitValues(profits);
            }
        }

        setProfitValues(profits);

        setProfitAll(Object.values(profits).reduce((total, item) => total + item.countAdv, 0));

    }

    const handleChangeSort = (orderBy) => {
        setSortOption(orderBy)
    }

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    // const handleMouseEnter = (value) => {
    //     setHoveredItem(value);
    // };

    const handleClick = (itemKey) => {
        setHoveredItem(itemKey); // Установка текущего элемента при клике
    };

    const handleInputChangeKPI = (event, category, property) => {
        const { value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                [property]: value
            }
        }));
    };

    const reverseDate = (date) => {
        if (date !== null) {
            return date.toString().split('-').reverse().join('.')
        }
    }

    const reverseDateWithOutYear = (date) => {
        if (date !== null) {
            return date.toString().split('-').reverse().join('.').slice(0, -5);
        }
    }

    const showManagersTables = async (item) => {
        setLinkBtx(item[1].orders[0].link)

        if (!showTable) {
            setShowTable(true)
        } else {
            setShowTable(false)
        }
    }

    const showManagers = async () => {
        if (startDate && endDate && sortOption) {
            await getDataToTable()
        }
    }

    const updateRatio = async () => {
        if (startDate && endDate && sortOption) {
            // console.log('sortOption', sortOption)
            formData.link = sortOption
            await sendRating(formData)
            await getDataToTable()
        }
    }

    // const getDayReport = async () => {
    //     let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //     const nowDate = DateTime.now().setZone(timeZone);
    //     // const formattedCurrentDate = nowDate.toFormat('yyyy-MM-dd');
    //     const formattedCurrentDate = '2024-07-02'
    //
    //     if (formattedCurrentDate && sortOption) {
    //         console.log('ordersByDate formattedCurrentDate', formattedCurrentDate)
    //         const ordersByDate = await getCompanyOrdersByDatePeriod(formattedCurrentDate, formattedCurrentDate, sortOption)
    //
    //         console.log('ordersByDate getDayReport', Object.values(ordersByDate)[0].managers)
    //     }
    // }

    const getOrdersByDatePeriod = async (sortPeriod) => {
        setAdvValues([])
        setAdvAll(0)
        setPreloader(true)
        setHoveredItem(null)
        let startDate, endDate, formattedStartDate, nowDate;
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        switch (sortPeriod) {
            case 'неделя':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 7 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            case '10 дней':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 10 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            case 'месяц':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 30 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            case 'вчера':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 1 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            case 'текущий':
                timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.startOf('month').toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                // console.log('formattedStartDate, endDate', formattedStartDate, endDate)
                setStartDate(startDate); // Устанавливаем дату начала текущего месяца
                setEndDate(endDate); // Устанавливаем текущую дату
                break;
            default:
                let now1 = DateTime.now().setZone(timeZone);
                let firstMonthDate1 = now1.startOf('month').toFormat('yyyy-MM-dd');
                let currentDate1 = now1.toISODate();
                setStartDate(firstMonthDate1);
                setEndDate(currentDate1);
        }

        if (startDate && endDate && sortOption) {
            const ordersByDate = await getCompanyOrdersByDatePeriod(startDate, endDate, sortOption, formData)

            setDealsStatistics(Object.entries(ordersByDate))

            // console.log('ordersByDate', ordersByDate)


            if (Object.entries(ordersByDate).length === 0) {
                setNoTableData(true)
            }

            const incomes = Object.entries(ordersByDate)
                .sort((a, b) => new Date(b[0]) - new Date(a[0]))
                .map((item, index) => ({
                    dateBitrix: item[0],
                    incomeValue: item[1].orders.reduce((total, orderItem) => {
                        if ((orderItem.status === null && orderItem.sentSum !== '0' && orderItem.sentSum !== null && orderItem.sentSum !== '' && orderItem.sentSum !== undefined)
                            ||
                            (orderItem.status === null && orderItem.sum !== '0' && orderItem.sum !== null && orderItem.sum !== '' && orderItem.sum !== undefined)
                        ) {
                            let sum = Math.round(Number(orderItem.sum));
                            let sentSum = Math.round(Number(orderItem.sentSum));
                            if (sum === 0 || sentSum === 0) {
                                return total; // Return current total without adding anything if either sum or sentSum is 0
                            } else {
                                return total + (sum - sentSum);
                            }
                        }
                        return total; // Return current total if conditions are not met
                    }, 0)
                }));

            // const incomes = Object.entries(ordersByDate)
            //     .sort((a, b) => new Date(b[0]) - new Date(a[0]))
            //     .map((item, index) => ({
            //         dateBitrix: item[0],
            //         incomeValue: item[1].orders
            //             .filter(item => item.status === null &&
            //                 item.sentSum !== undefined && item.sentSum !== '' && item.sentSum !== null &&
            //                 item.sum !== undefined && item.sum !== '' && item.sum !== null &&
            //                 item.sum !== "0" && item.sentSum !== "0")
            //             .reduce((total, item) => {
            //                 let sum = Math.round(Number(item.sum));
            //                 let sentSum = Math.round(Number(item.sentSum));
            //                 if (sum === 0 || sentSum === 0) {
            //                     return total; // Return current total without adding anything if either sum or sentSum is 0
            //                 } else {
            //                     return total + (sum - sentSum);
            //                 }
            //             }, 0)
            //     }));

            setIncomeValues(incomes);

            setIncomeAll(incomes.reduce((total, item) => total + item.incomeValue, 0));


            const datas = await dataFromNotifications()
            const data = datas.filter(item => item.link === sortOption);

            let advs = []
            let profits = []

            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    advs[data[i].dateBitrix] = {
                        dateBitrix: data[i].dateBitrix,
                        currentAdv: Number(data[i].countAdv),
                        link: sortOption
                    };
                }
                setAdvValues(advs);

                const filteredAdvs = Object.values(advs).filter(item => {
                    const itemDate = new Date(item?.dateBitrix);
                    const startDateObj = new Date(startDate);
                    const endDateObj = new Date(endDate);

                    return itemDate >= startDateObj && itemDate <= endDateObj;
                });

                const totalCountAdv = filteredAdvs.reduce((total, item) => total + item.currentAdv, 0);

                setAdvAll(totalCountAdv);

                let filteredAdvsFull = []

                for (let i = 0; i < filteredAdvs.length; i++) {
                    filteredAdvsFull[filteredAdvs[i].dateBitrix] = {
                        dateBitrix: filteredAdvs[i].dateBitrix,
                        currentAdv: Number(filteredAdvs[i].currentAdv),
                        link: sortOption
                    };
                }

                for (const adv of Object.entries(filteredAdvsFull)) {
                    const income = incomes.find(income => income?.dateBitrix === adv[0]);
                    if (income) {
                        profits[adv[0]] = {
                            dateBitrix: adv[0],
                            countAdv: Number(income.incomeValue) - Number(adv[1].currentAdv)
                        }
                        setProfitValues(profits);
                    }
                }
            } else {
                setAdvValues([]);
                setProfitValues([]);
            }


            setProfitAll(Object.values(profits).reduce((total, item) => total + item.countAdv, 0));

            let allSumm = Object.entries(ordersByDate).reduce((total, item) => {
                return total + item[1].orders.length;
            }, 0);

            setAllTdSum(allSumm)

            let winSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
                let filteredOrders = value.orders.filter(order => order.status === null);
                return total + filteredOrders.length;
            }, 0);

            setWinTdSum(winSumm)

            let failedSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
                let filteredOrders = value.orders.filter(order => order.status === 'failed');
                return total + filteredOrders.length;
            }, 0);

            setFailedTdSum(failedSumm)

            let workSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
                let filteredOrders = value.orders.filter(order => order.status === 'work');
                return total + filteredOrders.length;
            }, 0);

            setWorkTdSum(workSumm)

            let middleConversion = `${(winSumm * 100 / allSumm).toFixed(2)}%`

            setMiddleConv(middleConversion)

            let orderByDateArr = Object.entries(ordersByDate)

            let arr = []
            for (let i = 0; i < orderByDateArr.length; i++) {
                arr = arr.concat(Object.entries(orderByDateArr[i][1].managers));
            }

            let managers = {};

            for (let i = 0; i < arr.length; i++) {
                let key = arr[i][0];
                let orderCount = arr[i][1].orderCount;
                let winCount = arr[i][1].status.win;
                let failedCount = arr[i][1].status.failed;
                let incomCount = arr[i][1].status.incom;
                let workCount = arr[i][1].status.work;
                let ratingCount = arr[i][1].status.rating;


                if (managers[key]) {
                    managers[key].orderCount += orderCount;
                    managers[key].winCount += winCount;
                    managers[key].failedCount += failedCount;
                    managers[key].incomCount += incomCount;
                    managers[key].workCount += workCount;
                    managers[key].conversion = `${(managers[key].winCount * 100 / managers[key].orderCount).toFixed(2)}%`;
                    let middleCash = (managers[key].incomCount / managers[key].winCount).toFixed(0);
                    managers[key].middleCashCount = isNaN(middleCash) ? '0' : middleCash;
                    managers[key].ratingCount += ratingCount
                } else {
                    managers[key] = {
                        orderCount: orderCount,
                        winCount: winCount,
                        failedCount: failedCount,
                        incomCount: incomCount,
                        workCount: workCount,
                        conversion: `${(winCount * 100 / orderCount).toFixed(2)}%`,
                        middleCashCount: (incomCount / winCount).toFixed(0),
                        ratingCount: ratingCount,
                    };
                }

                // console.log(`ratingCount_${i}`, ratingCount)
            }

            let managersArray = Object.keys(managers).map(key => ({
                name: key,
                orderCount: managers[key].orderCount,
                winCount: managers[key].winCount,
                failedCount: managers[key].failedCount,
                incomCount: managers[key].incomCount,
                workCount: managers[key].workCount,
                conversion: managers[key].conversion,
                middleCashCount: managers[key].middleCashCount,
                ratingCount: (managers[key].ratingCount).toFixed(2),
            }));

            managersArray.sort((a, b) => b.ratingCount - a.ratingCount);

            setManagersInfo(managersArray)

            let sortedManagerNames = managersArray.map(manager => manager.name);

            setPeriodManagers(sortedManagerNames)

            setPreloader(false)
            setNoTableData(false)
        }
    };


    const getNextDayISOString = () => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1); // Adding 1 day
        return currentDate.toISOString().split('T')[0];
    }

    const sentGetBitrix = async () => {
        const password = prompt('Введите пароль для запуска синхронизации:');
        if (password === '0111') {
            // Password is correct, start synchronization
            let result  = await getBitrix();
            alert('Синхронизация запущена!');
            console.log("result", result)
        } else {
            alert('Неверный пароль или отмена.');
        }
    };
    const getDayReport = (link) => {
        const sent = {
            link: link,
        }
        callGetDayReport(sent)
            .then((response) => {
                console.log("Ответ в консоли:", response);
            })
            .catch((error) => {
                console.error("Произошла ошибка при вызове callGetDayReport:", error);
            });
    }

    const sentPostBd = () => {
        postBd()
    }

    const sentClearBd = () => {
        clearBd()
    }

    const sentCreateComponyWordPressBd = () => {
        createComponyWordPress()
    }

    switch (info.role.role) {
        case 'admin':
            return (
                <>
                    <div
                        className="left_links"
                        onClick={() => {
                            localStorage.removeItem('scroll_order');
                        }}
                    >
                        <Link to={`/statistics`} style={show ? {display: "flex"} : {display: "none"}}>
                            <img src={leftImg} className="logo_img" width="25px" height="25px"/>
                        </Link>
                        <Link to={`/statistics`} style={!show ? {display: "flex"} : {display: "none"}}>
                            <img src={leftImg} className="logo_img" width="25px" height="25px"/>
                        </Link>
                    </div>
                    <div className="deals">
                        <div
                            className="preloader"
                            style={preloader ? {display: "block"} : {display: "none"}}
                        >
                            <img src={preloadImg} className="logo_img" width="125px"
                                 height="125px"/>
                        </div>
                        <div>
                            <div className="select__wrapper" style={{marginBottom: "20px"}}>
                                Выбрать магазин
                                <select name="orderby" className="orderby" aria-label="Магазин" onChange={(e) => {
                                    handleChangeSort(e.target.value)
                                }} value={sortOption}>
                                    <option value="">Выберите магазин</option>
                                    <option value="https://tsvetochnyi.bitrix24.ru/">Цветочный</option>
                                    <option value="https://miamor.bitrix24.ru/">Ми-амор</option>
                                    <option value="https://sladkoeshka.bitrix24.ru/">Сладкоешка</option>
                                    <option value="https://kami-flow.bitrix24.ru/">Камелия</option>
                                </select>
                            </div>
                        </div>
                        <div style={{marginBottom: "20px"}}>
                            <label>Дата начала:</label>
                            <input
                                type="date"
                                value={startDate}
                                max={getNextDayISOString()}
                                onChange={(e) => handleStartDateChange(e.target.value)}
                            />

                            <label style={{marginLeft: "10px"}}>Дата окончания:</label>
                            <input
                                type="date"
                                value={endDate}
                                max={getNextDayISOString()}
                                onChange={(e) => handleEndDateChange(e.target.value)}
                            />

                            <button style={{marginLeft: "10px"}} onClick={showManagers}>Применить</button>
                        </div>
                        <div style={{marginBottom: "20px"}}>
                            <select id="sortPeriod" onChange={(e) => {
                                getOrdersByDatePeriod(e.target.value).then()
                            }}>
                                <option value="текущий">Текущий</option>
                                <option value="неделя">За неделю</option>
                                <option value="10 дней">За 10 дней</option>
                                <option value="месяц">За месяц</option>
                                <option value="вчера">За вчера</option>
                            </select>
                        </div>
                        <div style={{marginBottom: "20px"}}>


                            <button onClick={() => {
                                sentGetBitrix()
                            }}>Синхронизация
                            </button>

                            <button onClick={() => {
                                    getDayReport(sortOption)
                                }}>Отчет в бота</button>
                            <button onClick={() => {
                                sentPostBd()
                            }}>создаем базу из файлов</button>

                            <button onClick={() => {
                                sentClearBd()
                            }}>чистим базу</button>

                            <button onClick={() => {
                                sentCreateComponyWordPressBd()
                            }}>слияние базы</button>


                            {/*<button style={{marginLeft: "10px"}}>Отчет за неделю</button>*/}
                            {/*<button style={{marginLeft: "10px"}}>Отчет за месяц</button>*/}

                        </div>
                        <div
                            style={{display: "flex", position: "relative"}}
                        >
                            <div className="wide-table-wrapper deals-wide-table-wrapper fixed-wide-table-wrapper"
                                // style={{
                                //     display: 'flex', top: "0",
                                //     position: "absolute"
                                // }}
                            >
                                <table style={{
                                    width: "100%", display: 'flex', flexDirection: "column",
                                }} className="wide-table">
                                    <thead>
                                    <tr style={{height: "49px", display: "table-row"}}>
                                        <th rowSpan="2"
                                            style={{
                                                // height: "49px",
                                                // width: "69px",
                                                // padding: "0 2px"
                                            }}
                                        >{`${reverseDate(startDate)}`} <br/> {`${reverseDate(endDate)}`}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        dealsStatistics
                                            .sort((a, b) => new Date(b[0]) - new Date(a[0]))
                                            .map((item, index) => {
                                                const filteredOrders = item[1].orders.filter(order => order.status === null);
                                                if (filteredOrders.length === 0) {
                                                    return null;
                                                }

                                                return (
                                                    <tr key={index} className={`main_tr`}
                                                        // onMouseEnter={() => handleMouseEnter(item[0])}
                                                        onClick={() => handleClick(item[0])}
                                                        style={{backgroundColor: hoveredItem === item[0] ? '#cbe1e0' : 'transparent', height: "27px"}}
                                                    >
                                                        <td className="city_item city_item_fixed">{reverseDateWithOutYear(item[0])}</td>
                                                    </tr>
                                                )
                                            })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="wide-table-wrapper deals-wide-table-wrapper" style={{display: 'flex'}}>
                                <table className="wide-table">
                                    <thead>
                                    <tr>
                                        <th rowSpan="2">{`${reverseDate(startDate)}`} <br/> {`${reverseDate(endDate)}`}</th>
                                        <th colSpan="4">Общие показатели</th>
                                        <th>{middleConv}</th>
                                        <th>{incomeAll}</th>
                                        <th>{advAll}</th>
                                        <th style={{backgroundColor: profitAll < 0 ? 'rgba(250,0,0,0.42)' : 'transparent'}}>{profitAll}</th>
                                        {
                                            periodManagers.map((manager, i) => {
                                                return (
                                                    <th className="th_wide-table-min" key={i}>
                                                        <table className="wide-table-min">
                                                            <tbody>
                                                            <tr>
                                                                <th className="td_div td_div_name">
                                                                    {users &&
                                                                        users.map(user => {
                                                                            if (user.email === manager) {
                                                                                return user.nameTitle; // Display user's name if email matches manager
                                                                            }
                                                                            return null
                                                                        })}
                                                                </th>
                                                                {/*<th className="td_div"></th>*/}
                                                                <th className="td_div"
                                                                    style={{backgroundColor: 'rgba(250,243,155,0.42)'}}>Conv
                                                                </th>
                                                                {/*<th style={{*/}
                                                                {/*    backgroundColor: 'rgba(250,169,114,0.42)',*/}
                                                                {/*    width: '48px'*/}
                                                                {/*}}*/}
                                                                {/*    className={`manager_${i}_incom td_div`}>*/}
                                                                {/*    р.{*/}
                                                                {/*    managersInfo && managersInfo.map(item => {*/}
                                                                {/*        if (item.name === manager) {*/}
                                                                {/*            let conversionRate = item.winCount !== 0 ? (item.incomCount / item.winCount).toFixed(0) : 0;*/}
                                                                {/*            return isNaN(conversionRate) ? '0.00' : conversionRate;*/}
                                                                {/*        }*/}
                                                                {/*        return null;*/}
                                                                {/*    })*/}
                                                                {/*}*/}
                                                                {/*</th>*/}
                                                                <th style={{
                                                                    backgroundColor: 'rgba(250,169,114,0.42)',
                                                                    width: '50px'
                                                                }}
                                                                    className={`manager_${i}_incom td_div`}>Доход
                                                                </th>
                                                                <th className="td_div" style={{
                                                                    width: "50px",
                                                                    backgroundColor: 'rgba(166,205,241,0.42)'
                                                                }}>Ср.чек
                                                                </th>
                                                                <th className="td_div" style={{
                                                                    width: "44px",
                                                                    backgroundColor: 'rgba(155,153,153,0.35)'
                                                                }}>Рейтинг
                                                                </th>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                    <tr>
                                        <th>Всего</th>
                                        <th>Успех</th>
                                        <th>В работе</th>
                                        <th>Провал</th>
                                        <th>Конверсия</th>
                                        <th>Доход</th>
                                        <th>Реклама</th>
                                        <th>Прибыль</th>
                                        {
                                            managersInfo.map((manager, i) => {

                                                // console.log('manager', manager)
                                                return (
                                                    <th className="th_wide-table-min_header" key={i}>
                                                        <table className="wide-table-min wide-table-min_header">
                                                            <tbody>
                                                            <tr>
                                                                <th className={`manager_${i}_all all_td`}>{manager.orderCount}</th>
                                                                <th className={`manager_${i}_win win_td`}
                                                                    style={{backgroundColor: 'rgba(152,234,119,0.42)'}}>{
                                                                    manager.winCount
                                                                }
                                                                </th>
                                                                <th className={`manager_${i}_work work_td`}
                                                                    style={{backgroundColor: 'rgba(169,175,248,0.42)'}}>{
                                                                    manager.workCount
                                                                }
                                                                </th>
                                                                <th className={`manager_${i}_failed failed_td`}
                                                                    style={{backgroundColor: 'rgba(248,101,101,0.42)'}}>{
                                                                    manager.failedCount
                                                                }
                                                                </th>
                                                                <th className={`manager_${i}_conversion conversion_td`}
                                                                    style={{backgroundColor: 'rgba(250,243,155,0.42)'}}>{
                                                                    manager.conversion
                                                                }
                                                                </th>
                                                                <th className={`manager_${i}_incom_middle incom_middle_td`}
                                                                    style={{backgroundColor: 'rgba(250,169,114,0.42)'}}>{
                                                                    manager.incomCount
                                                                }
                                                                </th>
                                                                <th className={`manager_${i}_incom_middle incom_middle_td`}
                                                                    style={{backgroundColor: 'rgba(166,205,241,0.42)'}}>{
                                                                    manager.middleCashCount
                                                                }
                                                                </th>
                                                                <th className={`manager_${i}_rating rating_td`}
                                                                    style={{backgroundColor: 'rgba(155,153,153,0.35)'}}>{
                                                                    manager.ratingCount
                                                                }
                                                                </th>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        dealsStatistics
                                            .sort((a, b) => new Date(b[0]) - new Date(a[0]))
                                            .map((item, index) => {
                                                const filteredOrders = item[1].orders
                                                    .filter(order => order.status === null
                                                        // &&
                                                        // order.sentSum !== undefined && order.sentSum !== '' && order.sentSum !== null &&
                                                        // order.sum !== undefined && order.sum !== '' && order.sum !== null &&
                                                        // order.sum !== "0" && order.sentSum !== "0"
                                                    );
                                                if (filteredOrders.length === 0) {
                                                    return null;
                                                }

                                                const incomeReduce = item[1].orders.reduce((total, orderItem) => {
                                                    if ((orderItem.status === null && orderItem.sentSum !== '0' && orderItem.sentSum !== null && orderItem.sentSum !== '' && orderItem.sentSum !== undefined)
                                                        ||
                                                        (orderItem.status === null && orderItem.sum !== '0' && orderItem.sum !== null && orderItem.sum !== '' && orderItem.sum !== undefined)
                                                    ) {
                                                        let sum = Math.round(Number(orderItem.sum));
                                                        let sentSum = Math.round(Number(orderItem.sentSum));
                                                        if (sum === 0 || sentSum === 0) {
                                                            return total; // Return current total without adding anything if either sum or sentSum is 0
                                                        } else {
                                                            return total + (sum - sentSum);
                                                        }
                                                    }
                                                    return total; // Return current total if conditions are not met
                                                }, 0)

                                                // console.log('incomeReduce', incomeReduce)

                                                return (
                                                    <tr className="main_tr"
                                                        style={{backgroundColor: hoveredItem === item[0] ? '#cbe1e0' : 'transparent'}}
                                                        key={index} onClick={() => {
                                                        showManagersTables(item).then();
                                                        handleClick(item[0]);
                                                    }}
                                                        // onMouseEnter={() => handleMouseEnter(item[0])}
                                                    >
                                                        <td className="city_item">{reverseDateWithOutYear(item[0])}</td>
                                                        <td className="all_item">{item[1].orders.length}</td>
                                                        <td style={{backgroundColor: 'rgba(152,234,119,0.42)'}}
                                                            className="win_item">
                                                            {filteredOrders.length}
                                                        </td>
                                                        <td style={{backgroundColor: 'rgba(169,175,248,0.42)'}}
                                                            className="work_item">
                                                            {item[1].orders.filter(order => order.status === 'work').length}
                                                        </td>
                                                        <td style={{backgroundColor: 'rgba(248,101,101,0.42)'}}
                                                            className="failed_item">
                                                            {item[1].orders.filter(order => order.status === 'failed').length}
                                                        </td>
                                                        <td style={{backgroundColor: 'rgba(250,243,155,0.42)'}}>
                                                            {`${(filteredOrders.length * 100 / item[1].orders.length).toFixed(2)}%`}
                                                        </td>
                                                        <td className="incomeTd">
                                                            {/*{filteredOrders.reduce((total, order) => total + Math.round(Number(order.sum) - Math.round(Number(order.sentSum))), 0)}*/}
                                                            {incomeReduce}
                                                        </td>
                                                        <td className="advTd" id={`${item[0]}`}>
                                                            <input
                                                                style={{maxWidth: "58px"}}
                                                                value={advValues[item[0]] ? advValues[item[0]].currentAdv : ''}
                                                                onChange={(e) => handleInputChange(e, index, item[0])}
                                                            />
                                                        </td>
                                                        <td className="profitTd"
                                                            style={{backgroundColor: profitValues[item[0]]?.countAdv < 0 ? 'rgba(250,0,0,0.42)' : 'transparent'}}>
                                                            {profitValues[item[0]] ? profitValues[item[0]]?.countAdv : '--'}
                                                        </td>
                                                        {
                                                            periodManagers.map((manager, j) => (
                                                                <td key={`manager_${j}`}>
                                                                    {
                                                                        Object.entries(item[1].managers).map((item, idxNum) => {
                                                                            if (manager === item[0]) {
                                                                                return (
                                                                                    <div key={`manager_${j}_${idxNum}`}>
                                                                                        <table
                                                                                            className="wide-table-min wide-table-min_body">
                                                                                            <tbody>
                                                                                            <tr>
                                                                                                <td className={`manager_${j}_all__item manager_item_td`}
                                                                                                    key={`manager_${j}_all__item`}
                                                                                                    style={{backgroundColor: 'transparent'}}
                                                                                                >
                                                                                                    {item[1].orderCount}
                                                                                                </td>
                                                                                                <td className={`manager_${j}_win__item manager_item_td`}
                                                                                                    key={`manager_${j}_win__item`}
                                                                                                    style={{backgroundColor: 'rgba(152,234,119,0.42)'}}
                                                                                                >
                                                                                                    {item[1].status.win}
                                                                                                </td>
                                                                                                <td className={`manager_${j}_work__item manager_item_td`}
                                                                                                    key={`manager_${j}_work__item`}
                                                                                                    style={{backgroundColor: 'rgba(169,175,248,0.42)'}}
                                                                                                >
                                                                                                    {item[1].status.work}
                                                                                                </td>
                                                                                                <td className={`manager_${j}_failed__item manager_item_td`}
                                                                                                    key={`manager_${j}_failed__item`}
                                                                                                    style={{backgroundColor: 'rgba(248,101,101,0.42)'}}>
                                                                                                    {item[1].status.failed}
                                                                                                </td>
                                                                                                <td className={`manager_${j}_conversion__item td_width manager_item_td_conv`}
                                                                                                    key={`manager_${j}_conversion__item`}
                                                                                                    style={{backgroundColor: 'rgba(250,243,155,0.42)'}}
                                                                                                >
                                                                                                    {item[1].status.conversion}
                                                                                                </td>
                                                                                                <td className={`manager_${j}_incom__item td_width manager_item_td_incom`}
                                                                                                    key={`mmanager_${j}_incom__item`}
                                                                                                    style={{backgroundColor: 'rgba(250,169,114,0.42)'}}
                                                                                                >
                                                                                                    {item[1].status.incom}
                                                                                                </td>
                                                                                                <td className={`manager_${j}_middleCash__item td_width manager_item_td_middleCash`}
                                                                                                    key={`manager_${j}_middleCash__item`}
                                                                                                    style={{backgroundColor: 'rgba(166,205,241,0.42)'}}
                                                                                                >
                                                                                                    {item[1].status.middleCash}
                                                                                                </td>
                                                                                                <td className={`manager_${j}_rating__item td_width manager_item_td_rating`}
                                                                                                    key={`manager_${j}_rating__item`}
                                                                                                    style={{backgroundColor: 'rgba(155,153,153,0.35)'}}
                                                                                                >
                                                                                                    {item[1].status.rating}
                                                                                                </td>
                                                                                            </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        })
                                                                    }
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                );
                                            })
                                    }
                                    <tr>
                                        <th>Итого</th>
                                        <th>{allTdSum}</th>
                                        <th>{winTdSum}</th>
                                        <th>{workTdSum}</th>
                                        <th>{failedTdSum}</th>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={{marginTop: "20px", display: "flex"}}>
                            <table className="wide-table wide-table-ratio">
                                <thead>
                                <tr>
                                    <th rowSpan="2">KPI</th>
                                    <th rowSpan="2">Вес <br/> показателя</th>
                                    <th colSpan="3">Значения</th>
                                </tr>
                                <tr>
                                    <th>База <br/> (если ниже,<br/> то работа <br/> не эффективна)</th>
                                    <th>Норма <br/> (значение, при котором <br/> поддерживается <br/> положительный баланс)</th>
                                    <th>Цель</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th>Конверсия <br/>в продажу, %</th>
                                    <th><input value={formData.conversionRate.weight}
                                               onChange={(e) => handleInputChangeKPI(e, 'conversionRate', 'weight')}/></th>
                                    <th><input value={formData.conversionRate.base}
                                               onChange={(e) => handleInputChangeKPI(e, 'conversionRate', 'base')}/></th>
                                    <th><input value={formData.conversionRate.norm}
                                               onChange={(e) => handleInputChangeKPI(e, 'conversionRate', 'norm')}/></th>
                                    <th><input value={formData.conversionRate.goal}
                                               onChange={(e) => handleInputChangeKPI(e, 'conversionRate', 'goal')}/></th>
                                </tr>
                                <tr>
                                    <th>Успешные<br/> сделки, шт</th>
                                    <th><input value={formData.successfulDeals.weight}
                                               onChange={(e) => handleInputChangeKPI(e, 'successfulDeals', 'weight')}/></th>
                                    <th><input value={formData.successfulDeals.base}
                                               onChange={(e) => handleInputChangeKPI(e, 'successfulDeals', 'base')}/></th>
                                    <th><input value={formData.successfulDeals.norm}
                                               onChange={(e) => handleInputChangeKPI(e, 'successfulDeals', 'norm')}/></th>
                                    <th><input value={formData.successfulDeals.goal}
                                               onChange={(e) => handleInputChangeKPI(e, 'successfulDeals', 'goal')}/></th>
                                </tr>
                                <tr>
                                    <th>Средний чек<br/> прибыли, руб</th>
                                    <th><input value={formData.averageRevenue.weight}
                                               onChange={(e) => handleInputChangeKPI(e, 'averageRevenue', 'weight')}/></th>
                                    <th><input value={formData.averageRevenue.base}
                                               onChange={(e) => handleInputChangeKPI(e, 'averageRevenue', 'base')}/></th>
                                    <th><input value={formData.averageRevenue.norm}
                                               onChange={(e) => handleInputChangeKPI(e, 'averageRevenue', 'norm')}/></th>
                                    <th><input value={formData.averageRevenue.goal}
                                               onChange={(e) => handleInputChangeKPI(e, 'averageRevenue', 'goal')}/></th>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <button style={{marginTop: "20px"}} onClick={updateRatio}>Применить</button>
                        <div style={{display: noTableData ? 'block' : 'none', marginTop: '20px', fontWeight: "bold"}}>Нет
                            данных
                        </div>
                    </div>
                </>
            );

        case 'buh':
            return (
                <>
                    <div
                        className="left_links"
                        onClick={() => {
                            localStorage.removeItem('scroll_order');
                        }}
                    >
                        <Link to={`/statistics`} style={show ? {display: "flex"} : {display: "none"}}>
                            <img src={leftImg} className="logo_img" width="25px" height="25px"/>
                        </Link>
                        <Link to={`/statistics`} style={!show ? {display: "flex"} : {display: "none"}}>
                            <img src={leftImg} className="logo_img" width="25px" height="25px"/>
                        </Link>
                    </div>
                    <div className="deals">
                        <div
                            className="preloader"
                            style={preloader ? {display: "block"} : {display: "none"}}
                        >
                            <img src={preloadImg} className="logo_img" width="125px"
                                 height="125px"/>
                        </div>
                        <div>
                            <div className="select__wrapper" style={{marginBottom: "20px"}}>
                                Выбрать магазин
                                <select name="orderby" className="orderby" aria-label="Магазин" onChange={(e) => {
                                    handleChangeSort(e.target.value)
                                }} value={sortOption}>
                                    <option value="">Выберите магазин</option>
                                    <option value="https://tsvetochnyi.bitrix24.ru/">Цветочный</option>
                                    <option value="https://miamor.bitrix24.ru/">Ми-амор</option>
                                    <option value="https://sladkoeshka.bitrix24.ru/">Сладкоешка</option>
                                    <option value="https://kami-flow.bitrix24.ru/">Камелия</option>
                                </select>
                            </div>
                        </div>
                        <div style={{marginBottom: "20px"}}>
                            <label>Дата начала:</label>
                            <input
                                type="date"
                                value={startDate}
                                max={getNextDayISOString()}
                                onChange={(e) => handleStartDateChange(e.target.value)}
                            />

                            <label style={{marginLeft: "10px"}}>Дата окончания:</label>
                            <input
                                type="date"
                                value={endDate}
                                max={getNextDayISOString()}
                                onChange={(e) => handleEndDateChange(e.target.value)}
                            />

                            <button style={{marginLeft: "10px"}} onClick={showManagers}>Применить</button>
                        </div>
                        <div style={{marginBottom: "20px"}}>
                            <select id="sortPeriod" onChange={(e) => {
                                getOrdersByDatePeriod(e.target.value).then()
                            }}>
                                <option value="текущий">Текущий</option>
                                <option value="неделя">За неделю</option>
                                <option value="10 дней">За 10 дней</option>
                                <option value="месяц">За месяц</option>
                                <option value="вчера">За вчера</option>
                            </select>
                        </div>
                        {/*<div style={{marginBottom: "20px"}}>*/}


                        {/*    <button onClick={() => {*/}
                        {/*        sentGetBitrix()*/}
                        {/*    }}>Синхронизация*/}
                        {/*    </button>*/}

                        {/*    <button*/}

                        {/*        onClick={() => {*/}
                        {/*            getDayReport(sortOption)*/}
                        {/*        }}>Отчет в бота</button>*/}
                        {/*    /!*<button style={{marginLeft: "10px"}}>Отчет за неделю</button>*!/*/}
                        {/*    /!*<button style={{marginLeft: "10px"}}>Отчет за месяц</button>*!/*/}

                        {/*</div>*/}
                        <div
                            style={{display: "flex", position: "relative"}}
                        >
                            <div className="wide-table-wrapper deals-wide-table-wrapper fixed-wide-table-wrapper"
                                // style={{
                                //     display: 'flex', top: "0",
                                //     position: "absolute"
                                // }}
                            >
                                <table style={{
                                    width: "100%", display: 'flex', flexDirection: "column",
                                }} className="wide-table">
                                    <thead>
                                    <tr style={{height: "49px", display: "table-row"}}>
                                        <th rowSpan="2"
                                            style={{
                                                // height: "49px",
                                                // width: "69px",
                                                // padding: "0 2px"
                                            }}
                                        >{`${reverseDate(startDate)}`} <br/> {`${reverseDate(endDate)}`}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        dealsStatistics
                                            .sort((a, b) => new Date(b[0]) - new Date(a[0]))
                                            .map((item, index) => {
                                                const filteredOrders = item[1].orders.filter(order => order.status === null);
                                                if (filteredOrders.length === 0) {
                                                    return null;
                                                }

                                                return (
                                                    <tr key={index} className={`main_tr`}
                                                        // onMouseEnter={() => handleMouseEnter(item[0])}
                                                        onClick={() => handleClick(item[0])}
                                                        style={{backgroundColor: hoveredItem === item[0] ? '#cbe1e0' : 'transparent'}}
                                                    >
                                                        <td className="city_item city_item_fixed">{reverseDateWithOutYear(item[0])}</td>
                                                    </tr>
                                                )
                                            })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="wide-table-wrapper deals-wide-table-wrapper" style={{display: 'flex'}}>
                                <table className="wide-table">
                                    <thead>
                                    <tr>
                                        <th rowSpan="2">{`${reverseDate(startDate)}`} <br/> {`${reverseDate(endDate)}`}</th>
                                        <th colSpan="4">Общие показатели</th>
                                        <th>{middleConv}</th>
                                        <th>{incomeAll}</th>
                                        <th>{advAll}</th>
                                        <th style={{backgroundColor: profitAll < 0 ? 'rgba(250,0,0,0.42)' : 'transparent'}}>{profitAll}</th>
                                        {
                                            periodManagers.map((manager, i) => {
                                                return (
                                                    <th className="th_wide-table-min" key={i}>
                                                        <table className="wide-table-min">
                                                            <tbody>
                                                            <tr>
                                                                <th className="td_div td_div_name">
                                                                    {users &&
                                                                        users.map(user => {
                                                                            if (user.email === manager) {
                                                                                return user.nameTitle; // Display user's name if email matches manager
                                                                            }
                                                                            return null
                                                                        })}
                                                                </th>
                                                                {/*<th className="td_div"></th>*/}
                                                                <th className="td_div"
                                                                    style={{backgroundColor: 'rgba(250,243,155,0.42)'}}>Conv
                                                                </th>
                                                                {/*<th style={{*/}
                                                                {/*    backgroundColor: 'rgba(250,169,114,0.42)',*/}
                                                                {/*    width: '48px'*/}
                                                                {/*}}*/}
                                                                {/*    className={`manager_${i}_incom td_div`}>*/}
                                                                {/*    р.{*/}
                                                                {/*    managersInfo && managersInfo.map(item => {*/}
                                                                {/*        if (item.name === manager) {*/}
                                                                {/*            let conversionRate = item.winCount !== 0 ? (item.incomCount / item.winCount).toFixed(0) : 0;*/}
                                                                {/*            return isNaN(conversionRate) ? '0.00' : conversionRate;*/}
                                                                {/*        }*/}
                                                                {/*        return null;*/}
                                                                {/*    })*/}
                                                                {/*}*/}
                                                                {/*</th>*/}
                                                                <th style={{
                                                                    backgroundColor: 'rgba(250,169,114,0.42)',
                                                                    width: '50px'
                                                                }}
                                                                    className={`manager_${i}_incom td_div`}>Доход
                                                                </th>
                                                                <th className="td_div" style={{
                                                                    width: "50px",
                                                                    backgroundColor: 'rgba(166,205,241,0.42)'
                                                                }}>Ср.чек
                                                                </th>
                                                                <th className="td_div" style={{
                                                                    width: "44px",
                                                                    backgroundColor: 'rgba(155,153,153,0.35)'
                                                                }}>Рейтинг
                                                                </th>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                    <tr>
                                        <th>Всего</th>
                                        <th>Успех</th>
                                        <th>В работе</th>
                                        <th>Провал</th>
                                        <th>Конверсия</th>
                                        <th>Доход</th>
                                        <th>Реклама</th>
                                        <th>Прибыль</th>
                                        {
                                            managersInfo.map((manager, i) => {

                                                // console.log('manager', manager)
                                                return (
                                                    <th className="th_wide-table-min_header" key={i}>
                                                        <table className="wide-table-min wide-table-min_header">
                                                            <tbody>
                                                            <tr>
                                                                <th className={`manager_${i}_all all_td`}>{manager.orderCount}</th>
                                                                <th className={`manager_${i}_win win_td`}
                                                                    style={{backgroundColor: 'rgba(152,234,119,0.42)'}}>{
                                                                    manager.winCount
                                                                }
                                                                </th>
                                                                <th className={`manager_${i}_work work_td`}
                                                                    style={{backgroundColor: 'rgba(169,175,248,0.42)'}}>{
                                                                    manager.workCount
                                                                }
                                                                </th>
                                                                <th className={`manager_${i}_failed failed_td`}
                                                                    style={{backgroundColor: 'rgba(248,101,101,0.42)'}}>{
                                                                    manager.failedCount
                                                                }
                                                                </th>
                                                                <th className={`manager_${i}_conversion conversion_td`}
                                                                    style={{backgroundColor: 'rgba(250,243,155,0.42)'}}>{
                                                                    manager.conversion
                                                                }
                                                                </th>
                                                                <th className={`manager_${i}_incom_middle incom_middle_td`}
                                                                    style={{backgroundColor: 'rgba(250,169,114,0.42)'}}>{
                                                                    manager.incomCount
                                                                }
                                                                </th>
                                                                <th className={`manager_${i}_incom_middle incom_middle_td`}
                                                                    style={{backgroundColor: 'rgba(166,205,241,0.42)'}}>{
                                                                    manager.middleCashCount
                                                                }
                                                                </th>
                                                                <th className={`manager_${i}_rating rating_td`}
                                                                    style={{backgroundColor: 'rgba(155,153,153,0.35)'}}>{
                                                                    manager.ratingCount
                                                                }
                                                                </th>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        dealsStatistics
                                            .sort((a, b) => new Date(b[0]) - new Date(a[0]))
                                            .map((item, index) => {
                                                const filteredOrders = item[1].orders
                                                    .filter(order => order.status === null
                                                        // &&
                                                        // order.sentSum !== undefined && order.sentSum !== '' && order.sentSum !== null &&
                                                        // order.sum !== undefined && order.sum !== '' && order.sum !== null &&
                                                        // order.sum !== "0" && order.sentSum !== "0"
                                                    );
                                                if (filteredOrders.length === 0) {
                                                    return null;
                                                }

                                                const incomeReduce = item[1].orders.reduce((total, orderItem) => {
                                                    if ((orderItem.status === null && orderItem.sentSum !== '0' && orderItem.sentSum !== null && orderItem.sentSum !== '' && orderItem.sentSum !== undefined)
                                                        ||
                                                        (orderItem.status === null && orderItem.sum !== '0' && orderItem.sum !== null && orderItem.sum !== '' && orderItem.sum !== undefined)
                                                    ) {
                                                        let sum = Math.round(Number(orderItem.sum));
                                                        let sentSum = Math.round(Number(orderItem.sentSum));
                                                        if (sum === 0 || sentSum === 0) {
                                                            return total; // Return current total without adding anything if either sum or sentSum is 0
                                                        } else {
                                                            return total + (sum - sentSum);
                                                        }
                                                    }
                                                    return total; // Return current total if conditions are not met
                                                }, 0)

                                                // console.log('incomeReduce', incomeReduce)

                                                return (
                                                    <tr className="main_tr"
                                                        style={{backgroundColor: hoveredItem === item[0] ? '#cbe1e0' : 'transparent'}}
                                                        key={index} onClick={() => {
                                                        showManagersTables(item).then();
                                                        handleClick(item[0]);
                                                    }}
                                                        // onMouseEnter={() => handleMouseEnter(item[0])}
                                                    >
                                                        <td className="city_item">{reverseDateWithOutYear(item[0])}</td>
                                                        <td className="all_item">{item[1].orders.length}</td>
                                                        <td style={{backgroundColor: 'rgba(152,234,119,0.42)'}}
                                                            className="win_item">
                                                            {filteredOrders.length}
                                                        </td>
                                                        <td style={{backgroundColor: 'rgba(169,175,248,0.42)'}}
                                                            className="work_item">
                                                            {item[1].orders.filter(order => order.status === 'work').length}
                                                        </td>
                                                        <td style={{backgroundColor: 'rgba(248,101,101,0.42)'}}
                                                            className="failed_item">
                                                            {item[1].orders.filter(order => order.status === 'failed').length}
                                                        </td>
                                                        <td style={{backgroundColor: 'rgba(250,243,155,0.42)'}}>
                                                            {`${(filteredOrders.length * 100 / item[1].orders.length).toFixed(2)}%`}
                                                        </td>
                                                        <td className="incomeTd">
                                                            {/*{filteredOrders.reduce((total, order) => total + Math.round(Number(order.sum) - Math.round(Number(order.sentSum))), 0)}*/}
                                                            {incomeReduce}
                                                        </td>
                                                        <td className="advTd" id={`${item[0]}`}>
                                                            {advValues[item[0]] ? advValues[item[0]].currentAdv : ''}
                                                        </td>
                                                        <td className="profitTd"
                                                            style={{backgroundColor: profitValues[item[0]]?.countAdv < 0 ? 'rgba(250,0,0,0.42)' : 'transparent'}}>
                                                            {profitValues[item[0]] ? profitValues[item[0]]?.countAdv : '--'}
                                                        </td>
                                                        {
                                                            periodManagers.map((manager, j) => (
                                                                <td key={`manager_${j}`}>
                                                                    {
                                                                        Object.entries(item[1].managers).map((item, idxNum) => {
                                                                            if (manager === item[0]) {
                                                                                return (
                                                                                    <div key={`manager_${j}_${idxNum}`}>
                                                                                        <table
                                                                                            className="wide-table-min wide-table-min_body">
                                                                                            <tbody>
                                                                                            <tr>
                                                                                                <td className={`manager_${j}_all__item manager_item_td`}
                                                                                                    key={`manager_${j}_all__item`}
                                                                                                    style={{backgroundColor: 'transparent'}}
                                                                                                >
                                                                                                    {item[1].orderCount}
                                                                                                </td>
                                                                                                <td className={`manager_${j}_win__item manager_item_td`}
                                                                                                    key={`manager_${j}_win__item`}
                                                                                                    style={{backgroundColor: 'rgba(152,234,119,0.42)'}}
                                                                                                >
                                                                                                    {item[1].status.win}
                                                                                                </td>
                                                                                                <td className={`manager_${j}_work__item manager_item_td`}
                                                                                                    key={`manager_${j}_work__item`}
                                                                                                    style={{backgroundColor: 'rgba(169,175,248,0.42)'}}
                                                                                                >
                                                                                                    {item[1].status.work}
                                                                                                </td>
                                                                                                <td className={`manager_${j}_failed__item manager_item_td`}
                                                                                                    key={`manager_${j}_failed__item`}
                                                                                                    style={{backgroundColor: 'rgba(248,101,101,0.42)'}}>
                                                                                                    {item[1].status.failed}
                                                                                                </td>
                                                                                                <td className={`manager_${j}_conversion__item td_width manager_item_td_conv`}
                                                                                                    key={`manager_${j}_conversion__item`}
                                                                                                    style={{backgroundColor: 'rgba(250,243,155,0.42)'}}
                                                                                                >
                                                                                                    {item[1].status.conversion}
                                                                                                </td>
                                                                                                <td className={`manager_${j}_incom__item td_width manager_item_td_incom`}
                                                                                                    key={`mmanager_${j}_incom__item`}
                                                                                                    style={{backgroundColor: 'rgba(250,169,114,0.42)'}}
                                                                                                >
                                                                                                    {item[1].status.incom}
                                                                                                </td>
                                                                                                <td className={`manager_${j}_middleCash__item td_width manager_item_td_middleCash`}
                                                                                                    key={`manager_${j}_middleCash__item`}
                                                                                                    style={{backgroundColor: 'rgba(166,205,241,0.42)'}}
                                                                                                >
                                                                                                    {item[1].status.middleCash}
                                                                                                </td>
                                                                                                <td className={`manager_${j}_rating__item td_width manager_item_td_rating`}
                                                                                                    key={`manager_${j}_rating__item`}
                                                                                                    style={{backgroundColor: 'rgba(155,153,153,0.35)'}}
                                                                                                >
                                                                                                    {item[1].status.rating}
                                                                                                </td>
                                                                                            </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        })
                                                                    }
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                );
                                            })
                                    }
                                    <tr>
                                        <th>Итого</th>
                                        <th>{allTdSum}</th>
                                        <th>{winTdSum}</th>
                                        <th>{workTdSum}</th>
                                        <th>{failedTdSum}</th>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={{display: noTableData ? 'block' : 'none', marginTop: '20px', fontWeight: "bold"}}>Нет
                            данных
                        </div>
                    </div>
                </>
            );
    }

}

export {TheDealsStatistics};

// import React, {useEffect} from 'react';
// import {makeDerg} from "../http/userAPI";
//
//
// const TheUpdate = () => {
//
//     useEffect(() => {
//         console.log('use effect TheUpdate')
//         makeDerg()
//     }, []);
//
//     return (
//         <div>
//
//         </div>
//     );
// };
//
// export default TheUpdate;
const normalizeDate = (date) => {
    let isoDate = date;

// Получение часового пояса устройства
    let deviceTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Преобразование в нормальный формат с учётом русской локализации и автоматическим определением часового пояса устройства
    let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false, timeZone: deviceTimeZone };
    let normalDate = new Date(isoDate).toLocaleString('ru-RU', options);

    return normalDate
}

export {normalizeDate};
import React, {useEffect, useState} from 'react';
import phoneImg from "../img/phone.svg";
import waImg from "../img/whatsapp.svg";
import tgImg from "../img/telegram.svg";
import vbImg from "../img/viber.svg";
import {Link} from "react-router-dom";
import plusImg from "../img/plus.svg";
import leftImg from "../img/left_arrow.svg";
import {getCompanyOrdersByINN} from "../http/ordersAPI";

const TheOrderCard = ({info, headerName,}) => {
    const [card, setCard] = useState({});
    //
    // const [all, setAll] = useState(true);
    // const [process, setProcess] = useState(false);
    // const [finish, setFinish] = useState(false);

    // const allClick = () => {
    //     setAll(true);
    //     setProcess(false);
    //     setFinish(false);
    // }
    //
    // const processClick = () => {
    //     setAll(false);
    //     setProcess(true);
    //     setFinish(false);
    // }
    //
    // const finishClick = () => {
    //     setAll(false);
    //     setProcess(false);
    //     setFinish(true);
    // }
    const [url, setUrl] = useState('');

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                let url = window.location.href;
                let parts = url.split('/');
                let preLastWord = parts[parts.length - 2];
                let lastWord = parts[parts.length - 1];
                setUrl(`${preLastWord}`)

                const result = await getCompanyOrdersByINN(preLastWord);
                console.log('Found result:', result);

                if (result && result[0].orders) {
                    const order = result[0].orders
                        .find(item => item.id === Number(lastWord));
                    setCard(order)
                    console.log('Found order:', order);
                }

            } catch (error) {
                console.error('Error fetching or processing orders:', error);
            }
        };

        fetchOrders();
    }, []);

    const normalizeDate = (date) => {
        let isoDate = date;

// Получение часового пояса устройства
        let deviceTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Преобразование в нормальный формат с учётом русской локализации и автоматическим определением часового пояса устройства
        let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false, timeZone: deviceTimeZone };
        let normalDate = new Date(isoDate).toLocaleString('ru-RU', options);

        return normalDate
    }


    return (
        <div>
            <header
                className="header order__header"
                // style={headerName == 'Заказ' ? {display: "flex"} : {display: "none"}}
            >
                <div className="header__left">
                    <div>
                        <Link to={`/orders/${url}`}>
                            <img src={leftImg} className="logo_img" width="25px"
                                 height="25px"/>
                        </Link>
                    </div>
                </div>
                <div className="header__name"
                >

                </div>
                <div className="header__right">

                </div>
            </header>
            <div className="orders">
                <div className="content body_item">
                    <div className="content__block">
                        <div className="center">
                            <div className="input_item">
                                <div>№ Заказа</div>
                                <div className="item_">
                                    <div className="item_data">{card.idBitrix}</div>
                                    {/*<div className="item_data">для Контрагента - №5</div>*/}
                                </div>
                            </div>
                            <div className="input_item">
                                <div>Стоимость</div>
                                <div className="item_"><span>{card.sum}</span>р.</div>
                            </div>
                            <div className="input_item">
                                <div>Дата создания</div>
                                <div className="item_">
                                    {normalizeDate(card.createdAt)}
                                </div>
                            </div>
                            <div className="input_item">
                                <div>Ответственный</div>
                                {/*<div className="item_">*/}
                                {/*    <div className="item_data">Ильсияр М</div>*/}
                                {/*</div>*/}
                            </div>
                            <div className="input_item">
                                <div>Статус</div>
                                <div className="item_">
                                    <div className="item_data">Новый</div>
                                </div>
                            </div>
                            <div className="input_item">
                                <div>Данные доставки</div>
                                <div className="item_">
                                    {/*<div className="item_data">Оплата по договору №223 от 4.01.2024 за период*/}
                                    {/*    01.02.2024*/}
                                    {/*    по 08.02.2024 . НДС не облагается*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="input_item">
                                <div>Комментарий</div>
                                {/*<div className="item_">*/}
                                {/*    <div className="item_data">Оплата по договору №223 от 4.01.2024 за период*/}
                                {/*        01.02.2024*/}
                                {/*        по 08.02.2024 . НДС не облагается*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                            <div className="input_item">
                                <div>Фотопример</div>
                                <div className="item_">
                                    <div className="item_data">
                                        <input type="file"/>
                                    </div>
                                </div>
                            </div>
                            <div className="input_item">
                                <div>Собранный букет</div>
                                <div className="item_">
                                    <div className="item_data">
                                        <input type="file"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {TheOrderCard};
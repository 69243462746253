import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import leftImg from "../img/left_arrow.svg";
import {DateTime} from "luxon";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dropdown } from 'react-bootstrap';
import {
    getCompanyOrdersByDatePeriod,
    getCompanyOrdersByDatePeriodForCityTest,
    getOrderFailed,
    getOrderNull
} from "../http/ordersAPI";
import preloadImg from "../img/tube-spinner.svg";

const TheCityStatisticsTest = ({info, show}) => {
    //console.log("info", info.role)
    //console.log("SVERKA")
    const [preloader, setPreloader] = useState(false)
    const [sortOption, setSortOption] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dealsStatistics, setDealsStatistics] = useState({})
    const [advValues, setAdvValues] = useState([]);
    const [profitValues, setProfitValues] = useState([0]);
    const [incomeValues, setIncomeValues] = useState([]);
    const [profitAll, setProfitAll] = useState(0)
    const [advAll, setAdvAll] = useState(0)
    const [incomeAll, setIncomeAll] = useState(0)
    const [allTdSum, setAllTdSum] = useState(0)
    const [failedTdSum, setFailedTdSum] = useState(0)
    const [winTdSum, setWinTdSum] = useState(0)
    const [modalActive, setModalActive] = useState(false)
    const [modalActiveCont, setModalActiveCont] = useState([])
    const [role, setRole] = useState("")

    useEffect(() => {
        setRole(info.role.role)
    }, []);

    const handleInputChange = (e, index) => {
        const adv = [...advValues];
        adv[index] = e.target.value;
        setAdvValues(adv);

        const newProfitValues = incomeValues.map((income, i) => {
            if (i === index) {
                return income - Number(adv[index]);
            }
            return profitValues[i]; // Keep other values unchanged
        });

        setProfitValues(newProfitValues);

        const timeoutId = setTimeout(() => {
            let allProfitTd = document.querySelectorAll('.profitTd');
            let allAdvTd = document.querySelectorAll('.advTd');

            let allProfitTdSum = 0;
            let allAdvTdSum = 0;

            allProfitTd.forEach(td => {

                if (!isNaN(parseFloat(td.textContent.trim()))) {
                    allProfitTdSum += parseFloat(td.textContent.trim());
                }
            });

            allAdvTd.forEach(td => {

                let value = parseFloat(td.querySelector('input').value.trim());
                // Проверяем, что значение не является NaN
                if (!isNaN(value)) {
                    allAdvTdSum += value;
                }
            });

            setProfitAll(allProfitTdSum);
            setAdvAll(allAdvTdSum);
        }, 50); // Задержка в миллисекундах (например, 1000 мс = 1 секунда)

        return () => clearTimeout(timeoutId);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleChangeSort = (orderBy) => {
        setSortOption(orderBy)
        // sortShops(orderBy)
        setProfitAll(0)
        setAdvAll(0)
        setIncomeAll(0)
    }



    const [modalResults, setModalResults] = useState([]);
    const [inputEnter, setInputEnter] = useState("")
    const [query, setQuery] = useState("")

    const [cityCompany, setCityCompany] = useState([]);
    const [orderNull, setOrderNull] = useState([]);
    const [orderFailed, setOrderFailed] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [modalOrder, setModalOrder] = useState(false)
    const [orderRaz, setOrderRaz] = useState([])
    const [cityCompanyMax, setCityCompanyMax] = useState([])



    const fetchData = async () => {
        try {
            setLoading(true);
            setError(null);
            setProgress(10); // Начинаем с 10%

            // Симуляция загрузки прогресса
            const interval = setInterval(() => {
                setProgress(prev => (prev < 90 ? prev + 10 : prev));
            }, 100);

            const [companyOrders, nullOrders, failedOrders] = await Promise.all([
                getCompanyOrdersByDatePeriodForCityTest(),
                getOrderNull(),
                getOrderFailed()
            ]);

            setCityCompany(companyOrders);
            console.log("companyOrders", companyOrders);
            setOrderNull(nullOrders);
            setOrderFailed(failedOrders);

            clearInterval(interval);
            setProgress(100); // Завершение загрузки

        } catch (err) {
            setError('Ошибка при загрузке данных'); // Обработайте ошибку
            console.error(err);
        } finally {
            setTimeout(() => {
                setLoading(false); // Завершите загрузку через некоторое время
                setProgress(0); // Сброс прогресса
            }, 500); // Чтобы шкала оставалась на 100% немного дольше
        }
    };

    const [sortRScom, setSortRS] = useState("noRs")

    useEffect(() => {
        //console.log("setSortRS", sortRScom)
        const cityCompanyM = [];
        for (let i = 0; i < cityCompany.length; i++) {
            const companyGroup = cityCompany[i];
            //console.log("companyGroup", companyGroup)
            for (let j = 0; j < companyGroup.companyAlls.length; j++) {
                const company = companyGroup.companyAlls[j];
                if (company.rs.length === 0 && sortRScom === "noRs") {
                    const result = {
                        id: company.id,
                        name: company.innCompany,
                        ip: company.nameCompany,
                        city: companyGroup.nameCityTitle,
                        region: companyGroup.region,
                        orderCount: company.orders.length,
                    };
                    cityCompanyM.push(result);
                } else if (company.rs.length !== 0 && sortRScom === "Rs") {
                    const result = {
                        id: company.id,
                        name: company.innCompany,
                        ip: company.nameCompany,
                        city: companyGroup.nameCityTitle,
                        region: companyGroup.region,
                        orderCount: company.orders.length,
                    };
                    cityCompanyM.push(result);
                }
            }
        }
        cityCompanyM.sort((a, b) => b.orderCount - a.orderCount);
        setCityCompanyMax(cityCompanyM);
        if (cityCompanyM.length > 0){
            setLoading(true);
        }
        console.log("setCityCompanyMax", cityCompanyMax)
    }, [cityCompany, sortRScom]);

    const modalActiveFunc = (order) => {
        setModalActiveCont(order)
        setModalActive(!modalActive)
    }

    const enterCity = (e) => {
        console.log(e)
        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
        let token = "80a7758e1c4d6412649c75b3f4e070057bef8234";
        let query = e.target.value;
        setQuery(query)
        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify({query: query})
        }

        fetch(url, options)
            .then(response => response.json())
            .then(result => {

                console.log("result ЧИСТЫЙ", result)
                // setCleanResult(result)

                const filteredSuggestions = result.suggestions.filter(suggestion =>
                    (suggestion.data.city && suggestion.data.city.toLowerCase().includes(query?.toLowerCase())) ||
                    (suggestion.data.settlement && suggestion.data.settlement.toLowerCase().includes(query?.toLowerCase())) ||
                    (suggestion.data.region_with_type && suggestion.data.region_with_type.toLowerCase().includes(query?.toLowerCase())) ||
                    (suggestion.data.settlement_with_type && suggestion.data.settlement_with_type.toLowerCase().includes(query?.toLowerCase())) ||
                    (suggestion.data.settlement_with_typeTitle && suggestion.data.settlement_with_typeTitle.toLowerCase().includes(query?.toLowerCase()))
                );

                console.log("filteredSuggestions", filteredSuggestions)

                const suggestions = filteredSuggestions.map((suggestion, index) => ({
                    postcode: suggestion.data.postal_code || "",
                    nameCityTitle: suggestion.data.city || "",
                    countryTitle: suggestion.data.country || "",
                    regionTitle: suggestion.data.region_with_type || "",
                    area_with_typeTitle: suggestion.data.area_with_type || suggestion.data.settlement_with_type || "",
                    geo_lat: suggestion.data.geo_lat || "",
                    geo_lon: suggestion.data.geo_lon || "",
                    settlement: suggestion.data.settlement || suggestion.data.settlement_with_typeTitle || "",
                }));
                setModalResults(suggestions);

                console.log("Suggestions", suggestions)
            })
            .catch(error => console.log("error", error));
    }
    const [idCompany, setIdCompany] = useState("")
    const sentNewCity = (city) => {
        console.log("Пришло",idCompany,city)
    }
    const totalOrders = cityCompany.reduce((total, item) => {
        return total + item.companyAlls.reduce((subTotal, itemCom) => {
            return subTotal + itemCom.orders.length;
        }, 0);
    }, 0);

    //const totalOrdersDB =


    const countInnDuplicates = (companyAlls) => {
        const innCounts = {};

        companyAlls.forEach(itemCom => {
            const inn = itemCom.innCompany;
            if (innCounts[inn]) {
                innCounts[inn]++;
            } else {
                innCounts[inn] = 1;
            }
        });

        return innCounts;
    };


    const countTotalInnDuplicates = (cityCompany) => {
        const innCounts = {};

        cityCompany.forEach(city => {
            city.companyAlls.forEach(itemCom => {

                const key = `${itemCom.innCompany}-${city.nameCityTitle}`;
                if (innCounts[key]) {
                    innCounts[key]++;
                } else {
                    innCounts[key] = 1;
                }

        })
        });

        return innCounts;
    };
    const countCityDuplicates = (cityCompany) => {
        const cityCounts = {};
        cityCompany.forEach(city => {
            const uniqueKey = `${city.nameCityTitle}-${city.region}`;
            if (cityCounts[uniqueKey]) {
                cityCounts[uniqueKey]++;
            } else {
                cityCounts[uniqueKey] = 1;
            }
        });
        return cityCounts;
    };

    const totalInnDuplicates = countTotalInnDuplicates(cityCompany);
    const totalCityDuplicates = countCityDuplicates(cityCompany);

    const formatCityDuplicates = (duplicates) => {
        return Object.entries(duplicates)
            .filter(([city, count]) => count > 1)
            .map(([city, count]) => `${city}: ${count}`)
            .join(', ');
    };
    const formattedCityDuplicates = formatCityDuplicates(totalCityDuplicates);


    let countInx = 0
    const countCompaniesWithRs = (cityCompany) => {
        return cityCompany.reduce((total, itemCom) => {
            const companiesWithRs = itemCom.companyAlls ?
                itemCom.companyAlls.filter(company => company.rs !== null && company.rs !== "").length : 0;
            return total + companiesWithRs;
        }, 0);
    };



    const countCompaniesWithRsALL = (cityCompany) => {
        return cityCompany.reduce((total, itemCom) => {
            return total + (itemCom.companyAlls ? itemCom.companyAlls.length : 0);
        }, 0);
    };

    const funOrderRaz = () => {
        const orderCity = new Set(
            cityCompany.reduce((accumulator, itemCom) => {
                if (Array.isArray(itemCom.companyAlls)) {
                    itemCom.companyAlls.forEach(company => {
                        if (Array.isArray(company.orders)) {
                            company.orders.forEach(order => {
                                if (order.id) {
                                    accumulator.push(order.id);
                                }
                            });
                        }
                    });
                }
                return accumulator;
            }, [])
        );

        console.log("Список idBitrix компаний:", Array.from(orderCity));

        const result = orderNull.filter(order => {
            const orderId = order.id;
            const existsInSet = orderCity.has(orderId);
            return !existsInSet;
        });

        console.log("Входящие заказы (orderNull):", orderNull);
        console.log("Результат фильтрации (заказы без совпадений):", result);
        console.log("ID отфильтрованных заказов:", result.map(res => res.id));

        setOrderRaz(result);
    };
    const [currentPage, setCurrentPage] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const itemsPerPage = 10;
    useEffect(() => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = Math.min(startIndex + itemsPerPage, cityCompanyMax.length);
        setCurrentItems([])
        setCurrentItems(cityCompanyMax.slice(startIndex, endIndex));
        console.log("currentItems", cityCompanyMax.slice(startIndex, endIndex))
    }, [currentPage, cityCompanyMax]);
    const pageCount = Math.ceil(cityCompanyMax.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const [slovoSort, setSlovoSort] = useState("Без Rs")
    const sortRS = (value, slovo) => {
        setSortRS(value)
        setSlovoSort(slovo)
    }



    return (
        <>
            <div className="text-center mb-4">
                <br/>
                <br/>
                <br/>
                <button className="btn btn-primary" onClick={() => fetchData()}>
                    Запуск сверки
                </button>
                {loading && (
                    <div style={{ marginTop: '20px' }}>
                        <div style={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: '5px' }}>
                            <div
                                style={{
                                    width: `${progress}%`,
                                    height: '20px',
                                    backgroundColor: progress === 100 ? 'green' : 'blue',
                                    borderRadius: '5px',
                                    transition: 'width 0.3s'
                                }}
                            />
                        </div>
                        <span style={{ marginLeft: '10px' }}>{progress}%</span>
                    </div>
                )}

                {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
            </div>
            { totalOrders > 0 && (
            <div>
                {role === 'admin' && (

                            <div className="container mt-4">
                {
                    totalOrders > 0 && (
                        <div className="mt-4">
                            <h4 style={{background:"lightcoral", padding:"10px"}}>Общее количество провальных ордеров: {orderFailed.length}</h4>
                            <h4 style={{background:"lightgreen", padding:"10px"}}>Общее количество успешных распределенных ордеров: {totalOrders}</h4>
                            <h4 style={{background:"lightgreen", padding:"10px"}}>Общее количество успешных из БД ордеров: {orderNull.length}</h4>
                            <h4
                                style={{background:"lightsteelblue", padding:"10px", cursor:"pointer"}}
                                onClick={()=>{
                                    setModalOrder(!modalOrder)
                                    funOrderRaz()
                                }}
                            >Разница ордеров: {orderNull.length - parseInt(totalOrders)}</h4>
                            {orderRaz > 0 && (
                                <div>

                                </div>
                            )}
                            <br/>
                            <h4>Компании с р/с: {countCompaniesWithRs(cityCompany)} из {countCompaniesWithRsALL(cityCompany)} = {countCompaniesWithRsALL(cityCompany)-countCompaniesWithRs(cityCompany)}</h4>
                            <h4>Дубликаты Городов:</h4>
                            <div>{formattedCityDuplicates}</div>
                            <h4>Городов без координат: {
                                cityCompany.filter(city =>
                                    city.geo_lat === null || city.geo_lat === '' &&
                                    city.geo_lot === null || city.geo_lot === ''
                                ).length
                            }</h4>
                            <h4>Дубликаты компаний:</h4>
                            <ul>
                                <ul>
                                    {Object.entries(totalInnDuplicates).map(([inn, count], index) => {
                                        if (count > 1) {
                                            countInx = countInx + 1
                                            return (
                                                <li key={inn}>
                                                    {countInx}. ИНН: {inn}, Дубликатов: {count}
                                                </li>
                                            );
                                        }
                                        return null;
                                    })}
                                </ul>
                            </ul>
                        </div>
                    )
                }
            </div>

                )}
                <div className="container mt-4">
                    <div className="shadow p-3 mb-5 bg-white rounded">
                                <div className="text-center">
                                    <div className="d-flex justify-content-end mt-4">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                {slovoSort}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item as="button" onClick={()=>sortRS("Rs", "C RS")}>Компании с RS</Dropdown.Item>
                                                <Dropdown.Item as="button" onClick={()=>sortRS("noRs", "Без RS")}>Компании без RS</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <h3 className="mb-4">Таблица организаций {sortRScom==="noRs"? "без " : "с " } RS ({cityCompanyMax.length})</h3>
                                    <table className="table table-striped table-bordered">
                                        <thead className="thead-dark">
                                        <tr>
                                            <th>№</th>
                                            <th>ID</th>
                                            <th>Название</th>
                                            <th>ИНН</th>
                                            <th>Город</th>
                                            <th>Количество ордеров</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentItems.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{startIndex + index + 1}</td>
                                                    <td>{item.id}</td>
                                                    <td>{item.ip}</td>
                                                    <td
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => {
                                                            window.open(`/card/${item.id}/${item.name}`, '_blank');
                                                        }}
                                                    >
                                                        {item.name}
                                                    </td>

                                                    <td>{item.city}</td>
                                                    <td>{item.orderCount}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                    <div>
                                        <button
                                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))}
                                            disabled={currentPage === 0}
                                            style={{ margin: "0 5px" }}
                                        >
                                            &larr;
                                        </button>

                                        <button
                                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount - 1))} // Переход на следующую страницу
                                            disabled={currentPage === pageCount - 1} // Отключаем кнопку если мы на последней странице
                                            style={{ margin: "0 5px" }}
                                        >
                                            &rarr;
                                        </button>
                                    </div>
                                </div>
                    </div>
                </div>
                {role === "admin" && (
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>City Name</th>
                            <th>Region</th>
                            <th>Latitude</th>
                            <th>Longitude</th>
                            <th>INN</th>
                            {/*<th>Order Count</th>*/}
                            <th>Company Count</th>
                        </tr>
                        </thead>
                        <tbody>
                        {cityCompany
                            .sort((a, b) => a.nameCityTitle.localeCompare(b.nameCityTitle))
                            .sort((a, b) => b.companyAlls.length - a.companyAlls.length)
                            .map((item, index) => {
                                const innDuplicates = countInnDuplicates(item.companyAlls);
                                return (
                                    <tr key={index}>
                                        <td>{item.id}</td>
                                        <td className={"col-1"}>{item.nameCityTitle || item.settlement_with_typeTitle}</td>
                                        <td>{item.region}</td>
                                        <td>{item.geo_lat}</td>
                                        <td>{item.geo_lon}</td>
                                        <td>
                                            {item.companyAlls
                                                .sort((a, b) => b.orders.length - a.orders.length) // Сортируем по количеству ордеров
                                                .map((itemCom, indexCom) => {
                                                    let itemComOrders = itemCom.orders.length;
                                                    return (
                                                        <div key={indexCom} style={{ display: 'flex' }}>
                                                            {itemCom.id} {itemCom.innCompany}
                                                            {/* Получаем количество дублей для текущего ИНН */}
                                                            <span
                                                                style={{ marginLeft: "20px", cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setIdCompany(itemCom.id);
                                                                    modalActiveFunc(itemCom.orders);
                                                                }}
                                                            >
                                                        Ордера: {itemComOrders}
                                                                (Дублей: {innDuplicates[itemCom.innCompany] > 1 ? innDuplicates[itemCom.innCompany] : "Н"})
                                                     </span>
                                                        </div>
                                                    );
                                                })}
                                        </td>
                                        <td>Итого сделок: {item.companyAlls.reduce((total, itemCom) => total + itemCom.orders.length, 0)}</td>
                                        <td>Итого компаний: {item.companyAlls.length}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
        )}
        </>
    )
}

export {TheCityStatisticsTest};

import React from 'react';
import {testBotOneBD} from "../http/botOneAPI";

const testBotOne = () => {
    console.log("запрос в базу")
    testBotOneBD().then()
}

const TheBotOne = () => {
    return (
        <>
          <div>
              <h2>Бот</h2>
              <button onClick={()=> {
                  console.log("Кнопка")
                  testBotOne()
              }}>Запустить</button>
          </div>
        </>
    );
};

export default TheBotOne;

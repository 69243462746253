import {$host} from "./index";

export const getNotificationOrders = async () => {
    // console.log('getNotificationOrders')
    const {data} = await $host.get('api/notification/getNotificationOrders')
    return data
}

export const getNotificationOrdersLength = async () => {
    // console.log('getNotificationOrdersLength')
    const {data} = await $host.get('api/notification/getNotificationOrdersLength')
    return data
}

export const dataFromNotifications = async () => {
    const {data} = await $host.get('api/notification/dataFromNotifications')
    return data
}

export const deleteNotificationBD = async (id) => {
    // console.log("make deleteNotificationBD", id)
    const {data} = await $host.post(`api/notification/deleteNotificationBD`, {id: id})
    return data
}

export const countAdvBDNotification = async (obj) => {
    console.log('adv', obj)
    const {data} = await $host.post(`api/notification/countAdvBDNotification`, obj)
    return data
}
